import messages from "@intlify/unplugin-vue-i18n/messages";
import { computed, type ComputedRef } from "vue";
import { createI18n, type TranslateResult } from "vue-i18n";

import type enGB from "@/assets/i18n/en-GB.json";

export enum Locale {
  de_DE = "de-DE",
  en_GB = "en-GB",
}

// The values here correspond to the values defined in @/i18n.ts
export enum NumberFormat {
  INTEGER = "integer",
  DECIMAL_1 = "decimal1",
  DECIMAL_2 = "decimal2",
}

export enum DateFormat {
  SHORT = "short",
  MEDIUM = "medium",
  LONG = "long",
}

interface NumberFormats {
  [key: string]: Intl.NumberFormatOptions;
}

const numberFormats: NumberFormats = {
  currency: {
    style: "currency",
    currency: "USD",
  },
  integer: {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  decimal1: {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  },
  decimal2: {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

export type TranslationPath = KeysOfType<typeof enGB, string>;
export type EnumTranslationPath<Enum extends string> = KeysOfType<
  typeof enGB,
  Record<Enum, string>
>;

type KeysOfType<T, A, Prefix extends string = ""> = {
  [K in keyof T & string]:
    | (T[K] extends A ? `${Prefix}${K}` : never)
    | (T[K] extends Record<string, unknown>
        ? KeysOfType<T[K], A, `${Prefix}${K}.`>
        : never);
}[keyof T & string];

export function translateEnum<Enum extends string>(
  path: EnumTranslationPath<Enum>,
  key: Enum,
  named?: Record<string, unknown>
): TranslateResult {
  const translationPath = `${path}.${key}`;
  // eslint-disable-next-line no-restricted-syntax,@intlify/vue-i18n/no-dynamic-keys -- this is safe because of the EnumTranslationPath type
  return named ? i18n.global.t(translationPath, named) : i18n.global.t(translationPath);
}

/**
 * Return a ref of an array of all localised values of an enum
 *
 * If this returns the following error, the referenced key does not fully match
 * the enum. This check is performed against the en-gb file:
 *
 * > Argument of type 'string' is not assignable to parameter of type 'never'.
 */
export function useEnumOptions<Enum extends string>(
  path: EnumTranslationPath<Enum>,
  enumClass: Record<string, Enum>,
): ComputedRef<{ value: Enum; title: string }[]> {
  return computed(() =>
    Object.values(enumClass).map((enumValue) => ({
      value: enumValue,
      title: translateEnum(path, enumValue),
    })),
  );
}
export const create = (locale = Locale.de_DE) =>
  createI18n({
    legacy: false,
    locale,
    fallbackLocale: Locale.en_GB,
    messages,
    numberFormats: {
      [Locale.en_GB]: numberFormats,
      [Locale.de_DE]: numberFormats,
    },
    datetimeFormats: {
      [Locale.en_GB]: {
        short: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        medium: {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
        long: {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
          hour: "numeric",
          minute: "numeric",
        },
      },
      [Locale.de_DE]: {
        short: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        },
        medium: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
        },
        long: {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
          hour: "numeric",
          minute: "numeric",
        },
      },
    },
  });

export const i18n = create(Locale.de_DE);
