<script setup lang="ts">
import { useI18n } from "vue-i18n";

import AppDialog from "@/app/base/dialog/AppDialog.vue";
import { useFieldService } from "@/app/process/service/FieldService";

const props = defineProps<{
  fieldValueId: string;
  deleteMessage: string;
}>();

const { t } = useI18n();

const fieldService = useFieldService();

function deleteField() {
  fieldService.deleteFieldValue(props.fieldValueId);
  fieldService.closeDeleteFieldDialog();
}
</script>

<template>
  <AppDialog
    :title="t('fields.deleteTitle')"
    :confirmMessage="t('action.delete')"
    :cancelMessage="t('action.cancel')"
    destructive
    @confirm="deleteField"
    @cancel="fieldService.closeDeleteFieldDialog"
  >
    <p>{{ deleteMessage }}</p>
  </AppDialog>
</template>

<style scoped></style>
