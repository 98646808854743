<script setup lang="ts">
import { useRoute } from "vue-router";

import { usePreviousRoute } from "@/app/base/utils/navigation";
import EnvironmentIndicator from "@/app/common/header/EnvironmentIndicator.vue";
import { UserAuthService } from "@/services/auth/UserAuthService";

import AppHeader from "./AppHeader.vue";

const previousRoute = usePreviousRoute();
const route = useRoute();
</script>

<template>
  <AppHeader
    :previousRoute="previousRoute"
    :activePath="route.fullPath"
    @logout="UserAuthService.logOutUser()"
  />
  <EnvironmentIndicator />
</template>
