<script setup lang="ts">
import { Handle, Position } from "@vue-flow/core";

defineProps<{
  id: string;
  label: string;
  position: Position.Right | Position.Left;
}>();
</script>

<template>
  <div :class="position === Position.Right ? 'handle-right' : 'handle-left'">
    <p class="label">{{ label }}</p>
    <Handle
      :id="id"
      :type="position === Position.Right ? 'target' : 'source'"
      :position="position"
    />
  </div>
</template>

<style scoped>
.handle-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.handle-left {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}

.label {
  color: #000;
}

.vue-flow__handle {
  position: relative;
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
}

.vue-flow__handle-left {
  top: 0;
  transform: translate(-50%, 0);
}

.vue-flow__handle-right {
  top: 0;
  transform: translate(50%, 0);
}
</style>
