import { i18n as i18nInstance } from "@/app/base/utils/i18n";
import { getFieldValue } from "@/app/process/field/FieldHelper";
import ValueField from "@/app/process/field/ValueField.vue";
import { type CellContent } from "@/app/process/list/TableTypes";
import { useFieldService } from "@/app/process/service/FieldService";
import { FieldType } from "@/gql/types";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { t } = i18nInstance.global;

const i18n = {
  yes: t("boolean.yes"),
  no: t("boolean.no"),
};

export function createFieldCells(entityId?: string | null) {
  if (!entityId) {
    return {};
  }
  const cells: Record<string, CellContent> = {};
  const fields = useFieldService().getFieldKeysWithValues(entityId);

  for (const [key, values] of fields) {
    if (key.type !== FieldType.Tag) {
      const value = values.map((it) =>
        getFieldValue(key.type ?? FieldType.String, it, i18n.yes, i18n.no),
      );

      cells[key.name ?? ""] = {
        content: value,
        component: ValueField,
        props: {
          fieldValueId: values.map((it) => it.id),
          targetEntityId: entityId,
          readonly: true,
          showLabel: false,
          hasMultipleValues: values.length > 1,
        },
      };
    }
  }

  return cells;
}
