import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";

const caeliTheme = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: {
        primary: {
          color: "#00CA90",
        },
        secondary: {
          color: "#FEFFFE",
        },
      },
    },
  },
});

export const primeVueConfig = {
  ripple: true,
  theme: {
    preset: caeliTheme,
    options: {
      darkModeSelector: "light",
    },
  },
};
