<script lang="ts" setup>
import { Editor } from "@toast-ui/editor";
import { ref, watchEffect } from "vue";

const props = defineProps<{
  label?: string;
  value?: string;
  markdown?: boolean;
}>();
const markdownContainer = ref<HTMLElement | null>();

watchEffect(() => {
  if (!markdownContainer.value) {
    return;
  }
  Editor.factory({
    el: markdownContainer.value,
    viewer: true,
    initialValue: props.value,
    linkAttributes: {
      target: "_blank",
    },
  });
});
</script>

<template v-if="props.value && props.value.length">
  <div>
    <p v-if="props.label" class="text-caption text-blue-grey-lighten-2">
      {{ props.label }}
    </p>
    <div
      v-if="markdown"
      ref="markdownContainer"
      class="toastui-editor-contents my-n2"
    >
      <div></div>
    </div>
    <div v-else class="toastui-editor-contents my-n2">
      <p class="text-caeli6">{{ props.value }}</p>
    </div>
  </div>
</template>
