<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import DeleteDialog from "@/app/base/dialog/DeleteDialog.vue";
import TextDisplay from "@/app/base/form/TextDisplay.vue";
import TextEditor from "@/app/base/form/TextEditor.vue";
import TextField from "@/app/base/form/value/TextField.vue";
import { useActivityService } from "@/app/process/service/ActivityService";

const props = defineProps<{
  id: string;
  activityId: string;
  readonly?: boolean;
  draggable?: boolean;
}>();

const { t } = useI18n();

const activityService = useActivityService();

const task = computed(() => activityService.getTask(props.id));

const zIndex = computed(() => 1000 - (task.value?.sortOrder ?? 0));

const removeDialog = ref<boolean>(false);
</script>

<template>
  <VCard
    variant="elevated"
    class="border-card overflow-visible d-flex flex-row"
    :style="{ 'z-index': zIndex }"
  >
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex flex-wrap flex-column ga-3 pa-4">
        <span
          class="text-right ma-n2 mt-n3 text-caption text-blue-grey-lighten-3"
        >
          {{ t("processes.task") }}
        </span>
        <div class="d-flex ga-3 align-center">
          <TextField
            v-if="!readonly"
            :label="t('processes.nameLabel')"
            :initialValue="task?.title"
            required
            variant="outlined"
            density="compact"
            hideDetails="auto"
            data-testid="activity-task-name"
            @update="
              (title: string) =>
                activityService.createOrUpdateTask(
                  { id: props.id, title },
                  props.activityId,
                )
            "
          />
          <TextDisplay
            v-else
            class="mt-n4"
            :value="task?.title ?? ''"
            :label="t('processes.nameLabel')"
          />
          <div v-if="!readonly" class="d-flex flex-column align-center">
            <VBtn
              icon="mdi-delete-outline"
              variant="plain"
              size="32"
              color="red-darken-2"
              @click="() => (removeDialog = true)"
            />
          </div>
          <DeleteDialog
            v-model="removeDialog"
            :target="
              t('processes.task') +
              (task?.title ? ' ' + t('quoted', { expression: task.title }) : '')
            "
            @confirm="
              () => {
                activityService.removeTask(task?.id ?? 'undefined');
                removeDialog = false;
              }
            "
            @cancel="() => (removeDialog = false)"
          />
        </div>

        <TextEditor
          v-if="!readonly"
          :containerId="props.id"
          :previousContent="task?.description ?? ''"
          :label="t('processes.singleView.descriptionLabel')"
          data-testid="activity-task-description"
          @saveContent="
            (description: string) =>
              activityService.createOrUpdateTask(
                { id: props.id, description },
                props.activityId,
              )
          "
        />
        <TextDisplay
          v-else
          :label="t('processes.singleView.descriptionLabel')"
          :value="task?.description ?? ''"
          markdown
        />
      </div>
    </div>
    <VIcon
      v-if="props.draggable && !props.readonly"
      class="drag-handle-icon"
      icon="mdi-drag"
    />
  </VCard>
</template>

<style scoped>
.drag-handle-icon {
  align-self: center;
  margin-right: 8px;
  cursor: move;
}
</style>
