<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { VCard } from "vuetify/components";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import { deduplicate } from "@/app/base/utils/array";
import { useBlockedActionStore } from "@/app/common/store/BlockedActionStore";
import DeleteProcessTemplateDialog from "@/app/process/action/DeleteProcessTemplateDialog.vue";
import UpgradeProcessDialog from "@/app/process/action/upgrade/UpgradeProcessDialog.vue";
import { EditorMode } from "@/app/process/graphviewer/EditorMode";
import FloatingShowGraphButton from "@/app/process/graphviewer/FloatingShowGraphButton.vue";
import ProcessActivityTable from "@/app/process/list/ProcessActivityTable.vue";
import OutputTable from "@/app/process/output/OutputTable.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import {
  UI_DIALOG,
  useDialogService,
} from "@/app/process/service/DialogService";
import { useProcessService } from "@/app/process/service/ProcessService";
import EntityFieldsCard from "@/app/process/single/EntityFieldsCard.vue";
import { isDefined } from "@/app/process/utils";
import {
  type ActivityDto,
  type ActivityOutputDto,
  EntityType,
  type ProcessOutputDto,
} from "@/gql/types";

const { t } = useI18n();

const router = useRouter();

const props = defineProps<{
  processId: string;
}>();

const activityService = useActivityService();
const blockerStore = useBlockedActionStore();
const processService = useProcessService();
const dialogService = useDialogService();

const isLoading = ref<boolean>(false);

const process = computed(() => {
  return processService.getProcess(props.processId);
});

const newProcessName = ref<string | undefined>(undefined);
const processName = computed<string>({
  get: () => process.value?.name ?? "N/A",
  set: (value: string) => (newProcessName.value = value),
});

const rootActivity = computed(() => {
  if (!process.value?.startActivityId) {
    return undefined;
  }
  return activityService.getActivity(process.value.startActivityId);
});

const graph = computed((): ActivityDto[] =>
  rootActivity.value
    ? (activityService.getGraph(rootActivity.value.id) ?? [])
    : [],
);

const activityOutputs = computed((): ActivityOutputDto[] =>
  processService
    .getProcessActivities(props.processId)
    .flatMap((processActivity) =>
      activityService.getOutputs(processActivity.activity.id),
    ),
);

const processOutputs = computed((): ProcessOutputDto[] =>
  processService
    .getProcessActivities(props.processId)
    .flatMap((processActivity) =>
      processService.getOutputs(processActivity.id),
    ),
);

const importantActivityOutputsFromProcess = computed(() => {
  return processOutputs.value
    .filter((output) => output.important)
    .map((output) => output.activityOutputId)
    .map((id) => activityOutputs.value.find((output) => output.id === id))
    .filter(isDefined);
});

const nonImportantProcessActivityOutputIds = computed(() => {
  return processOutputs.value
    .filter((output) => !output.important)
    .map((output) => output.activityOutputId);
});

const importantActivityOutputs = computed(() => {
  return deduplicate([
    ...activityOutputs.value.filter(
      (output) =>
        output.important &&
        !nonImportantProcessActivityOutputIds.value.includes(output.id),
    ),
    ...importantActivityOutputsFromProcess.value,
  ]);
});

const isBlocked = computed(() => blockerStore.isBlocked(props.processId));

async function createCustomActivity() {
  try {
    const activityId = uuidv4();
    await activityService.createOrUpdateActivity(
      {
        id: activityId,
        custom: true,
      },
      true,
    );

    await processService.createOrUpdateProcessActivity({
      id: uuidv4(),
      activityId,
      processId: props.processId ?? uuidv4(),
    });

    await router.push({
      name: "customActivity",
      params: { activityId, processId: props.processId },
    });
  } catch (error) {
    console.error(error);
  }
}

async function saveProcessName(closeCallback: () => void) {
  if (newProcessName.value) {
    isLoading.value = true;
    await processService.updateProcessName(
      process.value?.id,
      newProcessName.value,
    );
    isLoading.value = false;
  }
  closeCallback();
}

onBeforeMount(() => {
  processService.markProcessRefetch(props.processId);
});
</script>

<template>
  <VCard
    v-if="process !== undefined"
    class="bg-grey-lighten-5 pa-10 pt-3 pb-1 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <VAppBar
      v-if="
        rootActivity &&
        rootActivity.id !==
          activityService.getLastReleasedVersion(rootActivity.id)?.id
      "
      density="compact"
      class="px-6 bg-orange-lighten-5 border-b"
      elevation="0"
    >
      <div class="d-flex align-center w-100 text-caeli6">
        <p class="text-body-2">
          {{ t("processes.upgraded", { name: rootActivity?.name }) }}
        </p>
        <VBtn
          :disabled="isBlocked"
          class="ml-4"
          variant="tonal"
          color="caeli6"
          density="compact"
          prependIcon="mdi-refresh"
          :text="t('processes.upgrade.checkChanges')"
          data-testid="upgrade-process-button"
          @click="dialogService.openDialog(UI_DIALOG.UPGRADE_PROCESS)"
        >
          <template #prepend>
            <VProgressCircular
              v-if="isBlocked"
              size="14"
              class="px-2"
              indeterminate
            />
            <VIcon v-else icon="mdi-refresh" />
          </template>
        </VBtn>
      </div>
    </VAppBar>
    <UpgradeProcessDialog :processId="props.processId" />
    <FloatingShowGraphButton
      v-if="rootActivity && graph.length > 1"
      :editorMode="EditorMode.PROCESS"
      :rootActivityId="rootActivity.id"
      :processId="props.processId"
      primary
    />
    <VTooltip location="start" :text="t('processes.createCustomActivity')">
      <template #activator="{ props: activator }">
        <FloatingActionButton
          v-bind="activator"
          icon="mdi-plus-box-multiple-outline"
          data-testid="create-custom-activity"
          @click="createCustomActivity"
        />
      </template>
    </VTooltip>
    <div class="ma-auto w-form">
      <div class="d-flex flex-column ga-3">
        <div class="d-flex flex-column align-center justify-space-between">
          <RouterLink
            :style="{ color: 'inherit' }"
            :to="{
              name: 'processTemplate',
              params: { rootActivityId: rootActivity?.id },
            }"
          >
            <p class="text-subtitle-1">{{ rootActivity?.name }}</p>
          </RouterLink>

          <Inplace v-if="process.id && process.name" class="rounded-lg">
            <template #display>
              <p class="text-h5">
                {{ process.name }}
              </p>
            </template>

            <template #content="{ closeCallback }">
              <div v-focustrap>
                <InputText
                  v-model="processName"
                  autofocus
                  class="my-1"
                  @keydown.enter="saveProcessName(closeCallback)"
                  @keydown.esc="closeCallback"
                  @blur="saveProcessName(closeCallback)"
                />
              </div>
            </template>
          </Inplace>
        </div>
        <VCard class="pa-3 border-card">
          <VTabs v-model="dialogService.activeProcessTab[processId]">
            <VTab value="features">{{ t("processes.features") }}</VTab>
            <VTab value="activities">{{ t("processes.activity", 2) }}</VTab>
            <VTab value="outputs">{{ t("processes.output", 2) }}</VTab>
            <VTab value="customActivities" data-testid="custom-activities-tab">
              {{ t("processes.customActivity", 2) }}</VTab
            >
          </VTabs>
          <VWindow v-model="dialogService.activeProcessTab[processId]">
            <VWindowItem value="features" class="pa-4">
              <p class="text-h6 mb-4">
                <VIcon icon="mdi-tag-text" size="tiny" class="mr-1 mb-1" />
                {{ t("processes.field", 2) }}
              </p>
              <div class="d-flex flex-column ga-4">
                <EntityFieldsCard
                  :entityId="process.id"
                  :ancestorIds="[process.startActivityId]"
                  :entityType="[EntityType.Process, EntityType.Activity]"
                />
              </div>

              <p class="text-h6 mb-4 mt-8">
                <VIcon icon="mdi-star" size="tiny" class="ma-1" />
                {{ t("processes.outputFavorite", 2) }}
              </p>

              <div class="d-flex flex-column ga-4">
                <OutputTable
                  :contextKey="`importantoutputs-${props.processId}`"
                  :outputs="importantActivityOutputs"
                  :processId="props.processId"
                  :processOutputs="processOutputs"
                  showType
                />
              </div>
            </VWindowItem>
            <VWindowItem value="activities">
              <ProcessActivityTable :processId="props.processId" showDetails />
            </VWindowItem>
            <VWindowItem value="outputs">
              <OutputTable
                :contextKey="`outputs-${props.processId}`"
                :outputs="activityOutputs"
                :processId="props.processId"
                :processOutputs="processOutputs"
                showType
              />
            </VWindowItem>
            <VWindowItem value="customActivities">
              <DeleteProcessTemplateDialog />
              <ProcessActivityTable
                :processId="props.processId"
                showCustomActivities
                showDetails
              />
            </VWindowItem>
          </VWindow>
        </VCard>
      </div>
    </div>
  </VCard>
</template>
