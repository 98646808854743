<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/process/service/ActivityService";

const router = useRouter();

const props = defineProps<{ rootActivityMid: string }>();

const { t } = useI18n();

const activityService = useActivityService();

const current = computed(() =>
  activityService.getCurrentVersion(props.rootActivityMid),
);

const lastReleased = computed(() =>
  activityService.getLastReleased(props.rootActivityMid),
);
</script>

<template>
  <div
    class="d-flex pointer ga-6 align-center"
    :class="{ 'font-italic': !lastReleased }"
    @click="
      () =>
        router.push({
          name: 'processTemplate',
          params: { rootActivityId: lastReleased?.id ?? current?.id },
        })
    "
  >
    <p>
      {{ current?.name ?? "" }}
    </p>
    <VBtn
      v-if="lastReleased?.id !== current?.id"
      variant="outlined"
      class="text-caption font-italic"
      density="compact"
      prependIcon="mdi-pencil"
      data-testid="edit-draft-button"
      @click.stop.prevent="
        () =>
          router.push({
            name: 'processTemplate',
            params: { rootActivityId: current?.id },
          })
      "
      >{{ t("processes.draft") }}
    </VBtn>
  </div>
</template>
