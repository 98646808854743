import { type CancelablePromise } from "@/services/backend/core/CancelablePromise";
import { OpenAPI } from "@/services/backend/core/OpenAPI";
import { request as __request } from "@/services/backend/core/request";

export class RestService {
  /**
   * @returns stats OK
   * @throws ApiError
   */
  public static getProcessListStats(): CancelablePromise<object> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/pisys/v1/stats/process/list",
    });
  }

  public static async downloadProcessListStats() {
    const data = await RestService.getProcessListStats();

    const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "processes.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
