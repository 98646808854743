import {v4 as uuidv4} from "uuid";

export interface RevisionEntry {
  id: string;
  mid: string;
  archived: boolean;
  revision: number;
  released: boolean;
}

export interface RelationLookup {
  add(parentId: string, childId: string): void;

  remove(id: string): void;
}

export function provideId<
  T extends {
    id: string;
  },
>(fields?: Partial<T>, presetId = false): string {
  return fields?.id && presetId ? fields.id : (uuidv4() as string);
}

/**
 * Provides lookups for a 1:n relationship in both directions
 */
export class OneToManyIdLookup implements RelationLookup {
  readonly childIdsByParentId = new Map<string, string[]>();
  readonly parentIdByChildId = new Map<string, string>();

  getChildIds(id: string) {
    return this.childIdsByParentId.get(id) ?? [];
  }

  getParentId(id: string) {
    return this.parentIdByChildId.get(id);
  }

  add(parentId: string, childId: string) {
    const childIds = this.getChildIds(parentId);
    if (
      !childIds.find((id) => {
        return id === childId;
      })
    ) {
      this.parentIdByChildId.set(childId, parentId);
      childIds.push(childId);
      this.childIdsByParentId.set(parentId, childIds);
    }
  }

  /**
   * Removes all parent and child connections for an id from the lookup
   * @param id
   */
  remove(id: string) {
    this.removeChildConnections(id);
    this.removeParentConnection(id);
  }

  removeChildConnections(id: string) {
    const childIds = this.getChildIds(id);
    if (childIds === undefined) {
      return;
    }
    childIds.forEach((childId) => {
      this.parentIdByChildId.delete(childId);
    });
    this.childIdsByParentId.delete(id);
  }

  removeParentConnection(id: string) {
    const parentId = this.getParentId(id);
    if (parentId === undefined) {
      return;
    }
    const filteredIds = this.getChildIds(parentId).filter((it) => {
      return it !== id;
    });
    if (!filteredIds.length) {
      this.childIdsByParentId.delete(parentId);
    } else {
      this.childIdsByParentId.set(parentId, filteredIds);
    }
    this.parentIdByChildId.delete(id);
  }
}

/**
 * Provides lookups for an m:n relationship in both directions
 */
export class ManyToManyIdLookup implements RelationLookup {
  readonly childIdsByParentId = new Map<string, string[]>();
  readonly parentIdsByChildId = new Map<string, string[]>();

  add(parentId: string, childId: string) {
    const parentIds = this.getParentIds(childId);
    if (
      !parentIds.find((id) => {
        return id === parentId;
      })
    ) {
      parentIds.push(parentId);
      this.parentIdsByChildId.set(childId, parentIds);
    }
    const childIds = this.getChildIds(parentId);
    if (
      !childIds.find((id) => {
        return id === childId;
      })
    ) {
      childIds.push(childId);
      this.childIdsByParentId.set(parentId, childIds);
    }
  }

  getChildIds(parentId: string) {
    return this.childIdsByParentId.get(parentId) ?? [];
  }

  getParentIds(childId: string) {
    return this.parentIdsByChildId.get(childId) ?? [];
  }

  /**
   * Removes a particular connection from the lookup if it exists
   * @param parentId
   * @param childId
   */
  removeConnection(parentId: string, childId: string) {
    const filteredParentIds = this.getParentIds(childId).filter((it) => {
      return it !== parentId;
    });
    if (filteredParentIds.length) {
      this.parentIdsByChildId.set(childId, filteredParentIds);
    } else {
      this.parentIdsByChildId.delete(childId);
    }

    const filteredSiblingIds = this.getChildIds(parentId).filter((it) => {
      return it !== childId;
    });
    if (filteredSiblingIds.length) {
      this.childIdsByParentId.set(parentId, filteredSiblingIds);
    } else {
      this.childIdsByParentId.delete(parentId);
    }
  }

  /**
   * Removes all parent and child connections for an id from te lookup
   * @param id
   */
  remove(id: string) {
    this.removeParentConnections(id);
    this.removeChildConnections(id);
  }

  removeParentConnections(id: string) {
    const parentIds = this.getParentIds(id);
    parentIds.forEach((parentId) => {
      const filteredSiblingIds = this.getChildIds(parentId).filter((it) => {
        return it !== id;
      });
      if (!filteredSiblingIds.length) {
        this.childIdsByParentId.delete(parentId);
      } else {
        this.childIdsByParentId.set(parentId, filteredSiblingIds);
      }
    });
    this.parentIdsByChildId.delete(id);
  }

  removeChildConnections(id: string) {
    const childIds = this.getChildIds(id);
    childIds.forEach((childId) => {
      const filteredParentIds = this.getParentIds(childId).filter((it) => {
        return it !== id;
      });
      if (!filteredParentIds.length) {
        this.parentIdsByChildId.delete(childId);
      } else {
        this.parentIdsByChildId.set(childId, filteredParentIds);
      }
    });
    this.childIdsByParentId.delete(id);
  }
}

export class PendingPersistedLookupDual<
  PERSISTED extends {
    id: string;
  },
  PENDING extends {
    id: string;
  },
> {
  toPending: (persisted: PERSISTED) => PENDING;

  constructor(toPending: (persisted: PERSISTED) => PENDING) {
    this.toPending = toPending;
  }

  pendingById = new Map<string, PENDING>();
  persistedById = new Map<string, PERSISTED>();

  getAllIds() {
    return [...this.pendingById.keys(), ...this.persistedById.keys()];
  }

  getAll(): PENDING[] {
    const results: PENDING[] = [];
    this.getAllIds().forEach((id) => {
      const entry = this.getOptional(id);
      if (entry !== undefined) {
        results.push(entry);
      }
    });
    return results;
  }

  getAllPersisted(): PERSISTED[] {
    const results: PERSISTED[] = [];
    this.getAllIds().forEach((id) => {
      const entry = this.getPersistedOptional(id);
      if (entry !== undefined) {
        results.push(entry);
      }
    });
    return results;
  }

  getAllPending(): PENDING[] {
    const results: PENDING[] = [];
    this.getAllIds().forEach((id) => {
      const entry = this.getPendingOptional(id);
      if (entry !== undefined) {
        results.push(entry);
      }
    });
    return results;
  }

  clearPending(id: string) {
    return this.pendingById.delete(id);
  }

  /** (over)write item **/
  setPending(item: PENDING) {
    this.pendingById.set(item.id, item);
  }

  /** update changed fields **/
  updatePending(id: string, update: Partial<PENDING>) {
    this.setPending({ ...this.getPending(id, true), ...update });
  }

  getPendingOptional(id: string) {
    return this.pendingById.get(id);
  }

  /**
   *
   * Get a pending item by id, optionally creating it from the persisted version if only the persisted version is present
   *
   * @param id
   * @param createIfOnlyPersisted
   * @throws Error when no pending item is present
   */
  getPending(id: string, createIfOnlyPersisted = false) {
    const pendingOptional = this.getPendingOptional(id);
    if (pendingOptional !== undefined) {
      return pendingOptional;
    }
    const persistedOptional = this.getPersistedOptional(id);
    if (createIfOnlyPersisted && persistedOptional !== undefined) {
      const pendingFromPersisted = this.toPending(persistedOptional);
      this.setPending(pendingFromPersisted);
      return pendingFromPersisted;
    }
    throw new Error(`No pending item for id ${id}`);
  }

  setPersisted(items: PERSISTED[]) {
    const individualItems: Iterable<[string, PERSISTED]> = items.map((item) => [
      item.id,
      item,
    ]);
    this.persistedById = new Map([
      ...this.persistedById.entries(),
      ...individualItems,
    ]);
  }

  getPersistedOptional(id: string) {
    return this.persistedById.get(id);
  }

  getOptional(id: string) {
    const pendingOptional = this.getPendingOptional(id);
    if (pendingOptional) {
      return pendingOptional;
    }
    const persistedOptional = this.getPersistedOptional(id);
    if (persistedOptional) {
      return this.toPending(persistedOptional);
    }
    return undefined;
  }

  /**
   * Gets the pending version for an id if present, otherwise falling back to the persisted version
   * @param id
   * @throws Error if neither a pending nor a persisted item is present
   */
  get(id: string) {
    const entry = this.getOptional(id);
    if (entry === undefined) {
      throw new Error(`No existing entry with the id ${id}`);
    }
    return entry;
  }

  /**
   * Removes an item from all lookups
   * @param id
   */
  remove(id: string) {
    this.pendingById.delete(id);
    this.persistedById.delete(id);
  }
}
