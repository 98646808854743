import { defineStore } from "pinia";
import { useConfirm } from "primevue/useconfirm";
import { v4 as uuidv4 } from "uuid";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useBlockedActionStore } from "@/app/common/store/BlockedActionStore";
import { Action, usePromptService } from "@/app/notification/PromptService";
import { useActivityService } from "@/app/process/service/ActivityService";
import {
  processActivityToDto,
  processOutputToDto,
} from "@/app/process/service/mapper/ProcessMapper";
import { useProcessActivityStore } from "@/app/process/service/persistence/process/ProcessActivityEntityStore";
import { useProcessStore } from "@/app/process/service/persistence/process/ProcessEntityStore";
import { useProcessOutputStore } from "@/app/process/service/persistence/process/ProcessOutputEntityStore";
import { useProcessTaskStore } from "@/app/process/service/persistence/process/ProcessTaskEntityStore";
import {
  type ActivityDto,
  type ProcessActivityDto,
  type ProcessActivityEto,
  type ProcessDto,
  type ProcessEto,
  type ProcessOutputDto,
  type ProcessTaskDto,
  useProcessUpgradeMutation,
} from "@/gql/types";

export const useProcessService = defineStore("ProcessService", () => {
  const activityService = useActivityService();
  const promptService = usePromptService();
  const blockerStore = useBlockedActionStore();

  const processStore = useProcessStore();
  const processActivityStore = useProcessActivityStore();
  const processOutputStore = useProcessOutputStore();
  const processTaskStore = useProcessTaskStore();

  const router = useRouter();
  const confirm = useConfirm();
  const { t } = useI18n();

  const { mutate: processUpgradeMutation } = useProcessUpgradeMutation();

  function createProcess(rootActivity: ActivityDto, processName: string) {
    const process: ProcessDto = {
      name: processName,
      id: uuidv4(),
      startActivityId: rootActivity.id,
    };
    return processStore.createOrUpdate(process).then(
      () => {
        promptService.success(process.id, Action.SAVE, process?.name ?? "");
        return process.id;
      },
      (reason) => {
        promptService.failure(process.id, Action.SAVE, reason);
        return undefined;
      },
    );
  }

  async function updateProcessName(
    processId: string | undefined,
    processName: string | undefined,
  ): Promise<void> {
    if (!processId || !processName) {
      console.error("Invalid arguments for updateProcessName", {
        processId,
        processName,
      });
      return;
    }
    const process = processStore.getById(processId);
    if (!process) {
      console.error("Process not found for id", processId);
      return;
    }
    return processStore
      .createOrUpdate({
        id: processId,
        name: processName,
        startActivityId: process.startActivityId,
      })
      .then(() => {
        promptService.success(processId, Action.SAVE, processName);
      })
      .catch((reason) => {
        promptService.failure(processId, Action.SAVE, reason);
        console.error(reason);
      });
  }

  async function createOrUpdateProcessActivity(fields: ProcessActivityDto) {
    const existingEto = processActivityStore.getById(fields.id);
    const existingDto = existingEto
      ? processActivityToDto(existingEto)
      : undefined;
    return await processActivityStore
      .createOrUpdate({ ...existingDto, ...fields })
      .then(
        () => {
          promptService.success(
            fields.id,
            Action.SAVE,
            activityService.getActivity(fields.activityId)?.name ?? fields.id,
          );
        },
        (reason) => {
          promptService.failure(fields.id, Action.SAVE, reason);
        },
      );
  }

  async function createOrUpdateOutput(dto: ProcessOutputDto) {
    const existingEto = processOutputStore.getById(dto.id);
    const existingDto = existingEto
      ? processOutputToDto(existingEto)
      : undefined;
    return processOutputStore
      .createOrUpdate({ ...existingDto, ...dto })
      .then(() => {
        const activityOutputId =
          dto.activityOutputId ?? existingEto?.activityOutputId;
        const processActivityId =
          dto.processActivityId ?? existingEto?.processActivityId;
        const processId = processActivityId
          ? processActivityStore.getById(processActivityId)?.processId
          : undefined;
        if (processId && activityOutputId && processActivityId) {
          processActivityUsedOutputsCache.delete(processActivityId);
        }
        promptService.success(
          dto.id,
          Action.SAVE,
          activityService.getOutput(dto.activityOutputId ?? "undefined")
            ?.name ?? dto.id,
        );
        return dto.id;
      })
      .catch((reason) => {
        // TODO: Rework error handling to avoid string matching
        if (JSON.stringify(reason).includes("REFRESH_REQUIRED")) {
          showReloadRequiredDialog();
        } else {
          promptService.failure(dto.id, Action.SAVE, reason);
        }
      });
  }

  async function createTask(dto: ProcessTaskDto) {
    return processTaskStore.createOrUpdate(dto).then(
      () => {
        promptService.success(
          dto.id,
          Action.SAVE,
          activityService.getTask(dto.activityTaskId ?? "undefined")?.title ??
            dto.id,
        );
        return dto.id;
      },
      (reason) => {
        promptService.failure(dto.id, Action.SAVE, reason);
      },
    );
  }

  async function deleteProcessActivity(processActivityId: string) {
    return processActivityStore
      .deleteById(processActivityId)
      .catch((reason) => {
        promptService.failure(processActivityId, Action.DELETE, reason);
      });
  }

  async function deleteOutput(processOutputId: string, silentSuccess = false) {
    return processOutputStore.deleteById(processOutputId).then(
      () => {
        if (!silentSuccess) {
          promptService.success(
            processOutputId,
            Action.DELETE,
            processOutputId,
          );
        }
      },
      (reason) => {
        promptService.failure(processOutputId, Action.DELETE, reason);
      },
    );
  }

  async function deleteTask(taskId: string) {
    return processTaskStore.deleteById(taskId).then(
      () => {
        promptService.success(taskId, Action.DELETE, taskId);
      },
      (reason) => {
        promptService.failure(taskId, Action.DELETE, reason);
      },
    );
  }

  async function startProcess(rootActivityId: string, processName: string) {
    const activityList = findActivityListByRootProcessId(rootActivityId);
    return createProcess(activityList[0], processName);
  }

  function findActivityListByRootProcessId(rootActivityId: string) {
    const activityList = activityService.linearizedActivityGraphs.find(
      (processList: ActivityDto[]) => {
        return processList[0].id === rootActivityId;
      },
    );
    if (!activityList) {
      throw new Error(
        `Store contains no process list with root process id ${rootActivityId}`,
      );
    }
    return activityList;
  }

  function getProcessOutput(
    processActivityId: string,
    activityOutputId: string,
  ): ProcessOutputDto | undefined {
    return processOutputStore
      .getFiltered("processActivityId", processActivityId)
      .find(
        (processOutput) => processOutput.activityOutputId === activityOutputId,
      );
  }

  function getOutputInstances(activityOutputId: string) {
    return processOutputStore.getFiltered("activityOutputId", activityOutputId);
  }

  function deleteCustomOutputInstancesFromCache(activityOutputId: string) {
    const output = activityService.getOutput(activityOutputId);
    if (!output) {
      return;
    }
    if (!activityService.isCustom(output.activityId)) {
      return;
    }
    processOutputStore.unregisterAllLoaded(
      getOutputInstances(activityOutputId).map((eto) => eto.id),
    );
  }

  function getInboundProcessOutputs(processId: string, activityId: string) {
    const activityInboundOutputs =
      activityService.getInboundOutputs(activityId);

    return activityInboundOutputs.flatMap((inboundOutput) => {
      const processActivity = getProcessActivityByTemplate(
        processId,
        inboundOutput.activityId,
      );
      return processActivity
        ? (getProcessOutput(processActivity.id, inboundOutput.id) ?? [])
        : [];
    });
  }

  function getOutputs(processActivityId: string): ProcessOutputDto[] {
    return processOutputStore.getFiltered(
      "processActivityId",
      processActivityId,
    );
  }

  function getTask(activityTaskId: string, processActivityId: string) {
    return processTaskStore
      .getFiltered("processActivityId", processActivityId)
      .find((task) => task.activityTaskId === activityTaskId);
  }

  function getTasks(processActivityId: string) {
    return processTaskStore.getFiltered("processActivityId", processActivityId);
  }

  function getProcessActivityByTemplate(processId: string, activityId: string) {
    return processActivityStore
      .getFiltered("processId", processId)
      .find((processActivity) => processActivity.activity.id === activityId);
  }

  const processActivityUsedOutputsCache = new Map<
    string,
    { providedCount: number; sum: number }
  >();

  function getUsedOutputs(processActivity: ProcessActivityEto) {
    const cachedState = processActivityUsedOutputsCache.get(processActivity.id);
    if (cachedState) {
      return cachedState;
    }
    const calculatedValue = {
      providedCount: getOutputs(processActivity.id).length,
      sum: activityService.getOutputs(processActivity.activity.id).length,
    };
    processActivityUsedOutputsCache.set(processActivity.id, calculatedValue);
    return calculatedValue;
  }

  function getActivities(processId: string) {
    const startActivityId = processStore.getById(processId)?.startActivityId;
    if (!startActivityId) {
      throw new Error(`Missing startActivityId on Process ${processId}`);
    }
    return activityService.getGraph(startActivityId) ?? [];
  }

  function getProcessActivities(processId: string) {
    return processActivityStore.getFiltered("processId", processId);
  }

  function getProcessActivitiesSortedByGraph(processId: string) {
    return getActivities(processId).flatMap(
      (activity) => getProcessActivityByTemplate(processId, activity.id) ?? [],
    );
  }

  /** Stores whether the migration field value for a process output has been accepted by the user.
   * Key: `${processId}.${targetStartActivityId}.${processOutputId}`*/
  const outputMigrationState = reactive(
    new Map<string, Map<string, boolean>>(),
  );

  /** Stores Process Output value migrations for a pending update of a process to a new version.
   * Outer key: `${processId}.${target startActivityId}`
   * Inner key: `${processOutputId}`
   * Value: Partial<ProcessOutputDto> with only the field values that are being migrated */
  const outputMigrationFields = reactive(
    new Map<string, Map<string, ProcessOutputDto>>(),
  );

  function getAllMigrationFields(
    processId: string,
    targetStartActivityId: string,
  ) {
    return (
      outputMigrationFields.get(`${processId}.${targetStartActivityId}`) ??
      new Map<string, ProcessOutputDto>()
    );
  }

  function getMigrationFields(
    processId: string,
    targetStartActivityId: string,
    processOutputId: string,
  ) {
    return getAllMigrationFields(processId, targetStartActivityId).get(
      processOutputId,
    );
  }

  function updateMigrationState(
    processId: string,
    targetStartActivityId: string,
    processOutputId: string,
    targetState: boolean,
  ) {
    const outerKey = `${processId}.${targetStartActivityId}`;
    const innerMap =
      outputMigrationState.get(outerKey) ?? new Map<string, boolean>();
    innerMap.set(processOutputId, targetState);
    outputMigrationState.set(outerKey, innerMap);
    if (
      targetState &&
      !getMigrationFields(processId, targetStartActivityId, processOutputId)
    ) {
      updateMigrationFields(processId, targetStartActivityId, {
        id: processOutputId,
      });
    }
  }

  function updateMigrationFields(
    processId: string,
    targetStartActivityId: string,
    processOutputFields: ProcessOutputDto,
  ) {
    const outerKey = `${processId}.${targetStartActivityId}`;
    const innerKey = processOutputFields.id;
    const innerMap =
      outputMigrationFields.get(outerKey) ??
      new Map<string, ProcessOutputDto>();
    innerMap.set(innerKey, processOutputFields);
    outputMigrationFields.set(outerKey, innerMap);
  }

  function clearMigrationFields(
    processId: string,
    startActivityId: string,
    processOutputId: string,
  ) {
    const outerKey = `${processId}.${startActivityId}`;
    const innerMap = outputMigrationFields.get(outerKey) ?? new Map();
    innerMap.delete(processOutputId);
    outputMigrationFields.set(outerKey, innerMap);
  }

  function upgradeProcess(
    processId: string,
    targetStartActivityId: string,
    migratedOutputs: ProcessOutputDto[],
  ) {
    blockerStore.block(processId);
    processUpgradeMutation({
      processId,
      targetStartActivityId,
      migratedOutputs,
    })
      .then((result) => {
        if (result?.data?.processUpgrade) {
          processStore.unregisterAllLoaded([processId]);
          processStore.registerAllLoaded([
            result.data.processUpgrade,
          ] as ProcessEto[]);
        }
        promptService.success(
          processId,
          Action.UPGRADE,
          processStore.getById(processId)?.name ?? processId,
        );
        blockerStore.unblock(processId);
      })
      .catch((reason) => {
        promptService.failure(processId, Action.UPGRADE, reason);
        blockerStore.unblock(processId);
      });
  }

  function showReloadRequiredDialog() {
    confirm.require({
      header: t("dialogs.refreshRequired.title"),
      message: t("dialogs.refreshRequired.message"),
      rejectProps: {
        label: t("dialogs.refreshRequired.refreshPage"),
        severity: "secondary",
        outlined: true,
      },
      reject: () => router.go(0),
      acceptProps: {
        label: t("dialogs.refreshRequired.openInTab"),
      },
      accept: () => window.open(window.location.href, "_blank"),
    });
  }

  return {
    isLoading: (id?: string) =>
      processStore.isLoading(id) ||
      processActivityStore.isLoading() ||
      processTaskStore.isLoading() ||
      processOutputStore.isLoading(),
    startProcess,
    updateProcessName,
    createOrUpdateOutput,
    createOrUpdateProcessActivity,
    deleteOutput,
    deleteCustomOutputInstancesFromCache,
    getOutput: (id: string) => processOutputStore.getById(id),
    getOutputs,
    getProcessOutput,
    createTask,
    deleteTask,
    getInboundProcessOutputs,
    getTask,
    getTasks,
    getAllProcessActivities: () => processActivityStore.getAll(),
    getProcesses: () => processStore.getAll(),
    getProcess: (processId: string) => processStore.getById(processId),
    getProcessActivity: (id: string) => processActivityStore.getById(id),
    markProcessActivityRefetch: (id: string) =>
      processActivityStore.markRefetch(id),
    markProcessRefetch: (id: string) => processStore.markRefetch(id),
    getProcessActivityByTemplate,
    getProcessActivities,
    getProcessActivitiesSortedByGraph,
    getUsedOutputs,
    deleteProcessActivity,
    getActivities,
    updateMigrationFields,
    clearMigrationFields,
    getAllMigrationFields,
    updateMigrationState,
    getAllMigrationStates: (processId: string, targetStartActivityId: string) =>
      outputMigrationState.get(`${processId}.${targetStartActivityId}`) ??
      new Map<string, boolean>(),
    getMigrationState: (
      processId: string,
      targetStartActivityId: string,
      processOutputId: string,
    ) =>
      outputMigrationState
        .get(`${processId}.${targetStartActivityId}`)
        ?.get(processOutputId) ?? false,
    upgradeProcess,
  };
});
