import { useI18n } from "vue-i18n";

// source: https://www.makeuseof.com/regular-expressions-validate-url/
const urlRegex =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/;
const mailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const linkedInUrlRegex =
  /^http(s)?:\/\/(\w+\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?/;
const technicalKeyRegex = /^[a-z0-9.]+$/;

export const useValidators = () => {
  const { t } = useI18n();

  const required = (value: string) => {
    if (value) {
      return true;
    }
    return t("validators.required");
  };

  const email = (value: string) => {
    if (mailRegex.test(value)) {
      return true;
    }
    return t("validators.email");
  };

  //regex from https://www3.ntu.edu.sg/home/ehchua/programming/howto/Regexe.html
  const webUrl = (value: string) => {
    if (urlRegex.test(value)) {
      return true;
    }
    return t("validators.webUrl");
  };

  const isWebUrl = (value?: string | null) => {
    if (!value) {
      return false;
    }
    return urlRegex.test(value);
  };

  const linkedInUrl = (value: string) => {
    if (linkedInUrlRegex.test(value)) {
      return true;
    }
    return t("validators.linkedInUrl");
  };

  const zipCodeRegex = /^\d{5}$/;
  const zipCode = (value: string | number) => {
    if (typeof value === "number") {
      value = value.toString();
    }

    if (value && !zipCodeRegex.test(value.trim())) {
      return t("address.zipCodeInvalid");
    }
    return true;
  };

  const technicalKey = (value: string) => {
    if (technicalKeyRegex.test(value)) {
      return true;
    }
    return t("validators.techKey");
  };

  return {
    required,
    email,
    webUrl,
    linkedInUrl,
    isWebUrl,
    zipCode,
    technicalKey,
  };
};
