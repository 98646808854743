import {
  type ProcessActivityDto,
  type ProcessActivityEto,
  type ProcessDto,
  type ProcessEto,
  type ProcessOutputDto,
  type ProcessOutputEto,
  type ProcessTaskDto,
  type ProcessTaskEto,
} from "@/gql/types";

export function processToDto(process: ProcessEto): ProcessDto {
  return {
    id: process.id,
    name: process.name,
    startActivityId: process.startActivityId,
  };
}

export function processOutputToDto(output: ProcessOutputEto): ProcessOutputDto {
  return {
    id: output.id,
    processActivityId: output.processActivityId,
    activityOutputId: output.activityOutputId,
    value: {
      valueString: output.value?.valueString,
      valueBoolean: output.value?.valueBoolean,
      valueNumber: output.value?.valueNumber,
      valueDate: output.value?.valueDate,
      valueDateTime: output.value?.valueDateTime,
      valueEntityId: output.value?.valueEntityId,
      valueJson: output.value?.valueJson,
    },
  };
}

export function processTaskToDto(task: ProcessTaskEto): ProcessTaskDto {
  return {
    id: task.id,
    processActivityId: task.processActivityId,
    activityTaskId: task.activityTaskId,
    created: task.created,
  };
}

export function processActivityToDto(
  processActivity: ProcessActivityEto,
): ProcessActivityDto {
  return {
    id: processActivity.id,
    processId: processActivity.processId,
    activityId: processActivity.activity.id,
  };
}
