<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import TextField from "@/app/base/form/value/TextField.vue";
import TagsEditCard from "@/app/process/field/TagsEditCard.vue";
import ValueField from "@/app/process/field/ValueField.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import { usePersonService } from "@/app/process/service/PersonService";
import DeleteFieldDialog from "@/app/process/single/DeleteFieldDialog.vue";
import FieldDialog from "@/app/process/single/FieldDialog.vue";
import TopUsedFieldKeysSelector from "@/app/process/single/TopUsedFieldKeysSelector.vue";
import { EntityType, type FieldValueDto, type PersonDto } from "@/gql/types";

const props = defineProps<{
  personId: string;
}>();

const { t } = useI18n();

const personService = usePersonService();
const fieldService = useFieldService();

const fieldValueSelectedForDeletion = ref<FieldValueDto | undefined>();
const person = computed(() => personService.getById(props.personId));

const personFields = computed(() =>
  fieldService.getNonTagFieldValues(props.personId),
);

function openDeleteFieldDialog(fieldValue: FieldValueDto) {
  fieldValueSelectedForDeletion.value = fieldValue;
  fieldService.openDeleteFieldDialog();
}

function updatePerson(update: Partial<PersonDto>) {
  if (!person.value) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { references, fields, ...personWithoutFields } = person.value;
  personService.createOrUpdate({ ...personWithoutFields, ...update });
}
</script>

<template>
  <VCard
    v-if="person"
    class="activity bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <VCardTitle>
      {{ t("person.edit") }}
    </VCardTitle>
    <VCard class="pa-4">
      <VForm>
        <div class="d-flex flex-column ga-4">
          <TagsEditCard
            :entityType="EntityType.Person"
            :entityId="personId"
            @delete="(id: string) => fieldService.deleteFieldWithInstances(id)"
          />
          <TextField
            :initialValue="person.name ?? undefined"
            data-testid="person-name"
            :label="t('person.name')"
            @update="(value) => updatePerson({ name: value })"
          />
          <div class="d-flex flex-column ga-4">
            <ValueField
              v-for="fieldValue in personFields"
              :key="fieldValue.id"
              :fieldValueId="[fieldValue.id]"
              class="w-100"
              data-testid="entity-field"
              @delete="() => openDeleteFieldDialog(fieldValue)"
              @update="
                (update) => fieldService.createOrUpdateFieldValue(update)
              "
            />
          </div>
          <DeleteFieldDialog
            v-if="
              fieldService.isDeleteFieldDialogOpen &&
              fieldValueSelectedForDeletion
            "
            :fieldValueId="fieldValueSelectedForDeletion.id"
            :deleteMessage="
              t('person.deleteFieldText', {
                field:
                  fieldService.getFieldKey(
                    fieldValueSelectedForDeletion.fieldKeyId ?? '',
                  )?.name ?? '',
              })
            "
          />
          <div>
            <Button
              outlined
              size="small"
              icon="mdi mdi-plus"
              data-testid="entity-add-field"
              :label="t('action.addSomething', { name: t('processes.field') })"
              @click="fieldService.openFieldDialog"
            />
            <FieldDialog
              v-if="fieldService.isFieldDialogOpen"
              :entityType="[EntityType.Person]"
              :entityId="personId"
            />
            <TopUsedFieldKeysSelector
              :entityId="personId"
              :fieldKeys="personService.getTopUsedFieldKeys"
            />
          </div>
        </div>
      </VForm>
    </VCard>
  </VCard>
</template>
