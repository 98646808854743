<script setup lang="ts">
/* eslint-disable */
// @ts-nocheck

import { ref } from "vue";

const model = defineModel<string[]>({ required: true, default: [""] });

const itemCount = ref<number>(1);

function addItem() {
  itemCount.value++;
}
</script>

<template>
  <div class="d-flex flex-column ga-2">
    <div class="d-flex flex-column ga-1">
      <h5>Options:</h5>
      <p class="text-caption text-blue-grey-lighten-2">
        Add options for the field key. Options can be used to define a set of
        possible values for a field key. Keep this box empty if you don't want
        options
      </p>
    </div>
    <div v-for="index in itemCount" :key="index" class="d-flex ga-2">
      <VTextField
        :autofocus="itemCount > 1"
        v-model="model[index - 1]"
        :label="`Option ${index}`"
        variant="outlined"
        density="compact"
        @keydown.enter="addItem"
      />
      <VBtn
        v-if="index === itemCount"
        class="mt-1"
        flat
        icon="mdi-plus"
        density="compact"
        @click="addItem"
      />
    </div>
  </div>
</template>
