<script setup lang="ts">
const grafanaUrl = "/grafana";
</script>

<template>
  <iframe title="Dashboards" class="iframe" :src="grafanaUrl" />
</template>

<style scoped>
.iframe {
  border: none;
  width: 100%;
  height: 100vh;
}
</style>
