<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { useValidators } from "@/app/base/form/validators";

const { t } = useI18n();
const validators = useValidators();

const props = defineProps<{
  initialValue?: string;
  readonly?: boolean;
  label?: string;
  noLabel?: boolean;
  required?: boolean;
  technicalKey?: boolean;
}>();

const emits = defineEmits<(event: "update", value: string) => void>();

const lastEmitted = ref<string | undefined>(props.initialValue ?? "");

const valueString = ref(props.initialValue ?? "");

function isValid(value: string): boolean | string {
  if (!props.required) {
    return true;
  }
  return validators.required(value);
}

function isTechnicalKey(value: string): boolean | string {
  if (!props.technicalKey) {
    return true;
  }
  return validators.technicalKey(value);
}

function emitValue() {
  if (
    isValid(valueString.value) !== true ||
    isTechnicalKey(valueString.value) !== true
  ) {
    return;
  }
  if (lastEmitted.value === valueString.value) {
    return;
  }
  emits("update", valueString.value);
  lastEmitted.value = valueString.value;
}
</script>

<template>
  <VTextField
    v-model="valueString"
    :label="
      label ??
      (noLabel ? undefined : t('processes.singleView.outputTypes.STRING'))
    "
    class="text-caeli6 w-100"
    variant="outlined"
    density="compact"
    hideDetails="auto"
    :readonly="props.readonly"
    :rules="[isValid, isTechnicalKey]"
    validateOn="input"
    @blur="emitValue"
  >
    <slot />
  </VTextField>
</template>
