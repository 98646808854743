import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

import { getAccessToken } from "@/services/auth/msal/accessTokens";
import { UserAuthService } from "@/services/auth/UserAuthService";

const httpLink = createHttpLink({
  uri: "/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  const token = UserAuthService.isAuthEnabled() ? await getAccessToken() : "";
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

function nullable(merge = true) {
  return {
    merge,
    read(existing = null) {
      return existing;
    },
  };
}

const cache = new InMemoryCache({
  typePolicies: {
    FieldKeyEto: {
      fields: {
        colorHex: nullable(),
        prefix: nullable(),
      },
    },
    FieldValueEto: {
      fields: {
        optionId: nullable(),
      },
    },
    ValueEto: {
      fields: {
        valueBoolean: nullable(),
        valueNumber: nullable(),
        valueString: nullable(),
        valueDate: nullable(),
        valueDateTime: nullable(),
        valueJson: nullable(),
        valueEntityId: nullable(),
      },
    },
    ProcessOutputEto: {
      fields: {
        comment: nullable(),
      },
    },
    ProcessTaskEto: {
      fields: {
        author: nullable(),
      },
    },
    ActivityEto: {
      fields: {
        description: nullable(),
        goal: nullable(),
      },
    },
    ActivityOutputEto: {
      fields: {
        description: nullable(),
        key: nullable(),
      },
    },
    PersonEto: {
      fields: {
        email: nullable(),
        fields: nullable(false),
      },
    },
    MessageEto: {
      fields: {
        body: nullable(),
        fields: nullable(false),
        authorId: nullable(),
      },
    },
    ProcessEto: {
      fields: {
        author: nullable(),
      },
    },
    DocumentEto: {
      fields: {
        description: nullable(),
        iterator: nullable(),
      },
    },
    DocumentGenerateResult: {
      fields: {
        validation: nullable(false),
      },
    },
  },
});
export const apolloClient = new ApolloClient({
  link: from([authLink, httpLink]),
  cache,
});
