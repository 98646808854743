<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  primary?: boolean;
  first?: boolean;
  secondary?: boolean;
  icon: string;
  color?: string;
  loading?: boolean;
  disabled?: boolean;
}>();
const emits = defineEmits<(event: "click") => void>();

const customClass = computed(() => {
  if (props.primary) {
    return "primary";
  }
  if (props.first) {
    return "first";
  }
  if (props.secondary) {
    return "secondary";
  }
  return "";
});
</script>

<template>
  <VBtn
    :class="customClass"
    :icon="icon"
    :size="props.primary ? 'large' : 'small'"
    :color="props.color ?? (props.primary ? 'caeli5' : undefined)"
    :loading="props.loading"
    :disabled="props.disabled"
    elevation="8"
    @click.stop.prevent="() => emits('click')"
  />
</template>

<style scoped>
#app .v-btn {
  position: fixed;
  z-index: 2000;
  border-radius: 50%;
  right: 2rem;
  bottom: 6rem;
}
#app .v-btn.primary {
  right: 1.75rem;
  bottom: 1.5rem;
}
#app .v-btn.first {
  right: 2rem;
  bottom: 2rem;
}
#app .v-btn.secondary {
  right: 2rem;
  bottom: 9.5rem;
}
</style>
