<script setup lang="ts">
import { computed } from "vue";

import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import { usePersonService } from "@/app/process/service/PersonService";

const props = defineProps<{
  label?: string;
  personId: string;
}>();

const personService = usePersonService();

const person = computed(() => {
  return personService.getById(props.personId);
});
</script>

<template>
  <ReadonlyField :label="props.label ?? ''">
    <div class="d-flex w-100 toastui-editor-contents">
      <LoadingIndicator v-if="!person" />
      <RouterLink
        v-else
        class=""
        :to="{
          name: 'personView',
          params: { personId: props.personId },
        }"
      >
        {{ person?.name }}
      </RouterLink>
    </div>
  </ReadonlyField>
</template>
