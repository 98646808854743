<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();
const { t } = useI18n();

const error = computed(() => {
  const { error: e } = router.currentRoute.value.query;
  return e?.toString();
});

const errorMessage = computed(() => {
  //display message if provided
  const { message } = router.currentRoute.value.query;
  if (message) {
    return message;
  } else if (error.value === "404") {
    return t("errorPage.notFound");
  } else if (error.value === "500") {
    return t("errorPage.internalServerError");
  } else {
    return "";
  }
});

const errorDescription = computed(() => {
  const { description } = router.currentRoute.value.query;
  if (description) {
    return description;
  }
  //generate pageNotFound message if invalid path is provided
  const { invalidPath } = router.currentRoute.value.query;
  if (invalidPath) {
    return t("errorPage.pageNotFound", { path: invalidPath });
  }
  const { entity } = router.currentRoute.value.query;
  const { id } = router.currentRoute.value.query;
  if (entity) {
    return t("errorPage.entityNotFound", { entity, id });
  }
  //generate internal server message if error is 500
  if (error.value === "500") {
    return t("errorPage.internalServerErrorDescription");
  }

  return "";
});
</script>

<template>
  <div class="container pt-8">
    <div class="first-row">
      <span class="errorCode">{{ error }}</span>
      <span class="ml-2">{{ errorMessage }}</span>
    </div>
    <div class="second-row">
      {{ errorDescription }}
    </div>
  </div>
</template>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: max-content max-content;
}

.first-row {
  grid-row: 1;
  grid-column: 2;
}

.second-row {
  grid-row: 2;
  grid-column: 2;
}

.errorCode {
  font-size: 80px;
}
</style>
