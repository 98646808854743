<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import StartProcessButton from "@/app/process/action/start/StartProcessButton.vue";
import { useActivityService } from "@/app/process/service/ActivityService";

const { t } = useI18n();

const activityService = useActivityService();

const dialog = ref(false);
const processName = ref("");

const props = defineProps<{
  rootActivityId: string;
  floating?: boolean;
}>();

const rootActivity = computed(() =>
  activityService.getActivity(props.rootActivityId),
);
</script>

<template>
  <VTooltip location="start" :text="t('processes.startButton.startProcess')">
    <template #activator="{ props: activator }">
      <FloatingActionButton
        v-if="props.floating"
        v-bind="activator"
        icon="mdi-play"
        data-testid="start-process-button"
        @click="
          () => {
            processName = '';
            dialog = true;
          }
        "
      />
      <VBtn
        v-else
        data-testid="start-process-button"
        v-bind="activator"
        variant="text"
        icon="mdi-play"
        size="tiny"
        @click="
          () => {
            processName = '';
            dialog = true;
          }
        "
      />
    </template>
  </VTooltip>
  <VDialog v-model="dialog" width="600" data-testid="start-process-dialog">
    <VCard>
      <VCardTitle class="text-wrap"
        >{{
          t("processes.startButton.startNamedProcess", {
            name: rootActivity?.name ?? "",
          })
        }}
      </VCardTitle>
      <VCardText>
        <VTextField
          v-model="processName"
          density="compact"
          variant="outlined"
          :label="t('processes.startButton.nameLabel')"
          hideDetails="auto"
        />
      </VCardText>
      <VCardActions class="d-flex flex-column">
        <StartProcessButton
          :processName="processName"
          :rootActivityId="rootActivityId"
        />
        <VBtn
          variant="flat"
          block
          @click.stop.prevent="
            () => {
              dialog = false;
            }
          "
          >{{ t("action.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
