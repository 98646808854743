<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import ValueField from "@/app/process/field/ValueField.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import DeleteFieldDialog from "@/app/process/single/DeleteFieldDialog.vue";
import FieldDialog from "@/app/process/single/FieldDialog.vue";
import {
  type EntityType,
  type FieldKeyDto,
  type FieldKeyEto,
  FieldType,
  type FieldValueDto,
  type FieldValueEto,
} from "@/gql/types";

const props = defineProps<{
  entityId: string;
  ancestorIds?: string[];
  entityType: EntityType[];
  readonly?: boolean;
}>();

const { t } = useI18n();
const fieldService = useFieldService();

interface ListItem {
  key: FieldKeyDto;
  value: FieldValueDto;
  readonly: boolean;
}

const fields = computed(() => {
  const valueFieldKeysMap = new Map<FieldKeyEto, FieldValueEto[]>();

  // This overrides the fields according to the order in ancestorIds. The current entity always wins as it is last.
  [...(props.ancestorIds ?? []), props.entityId].forEach((entityId) => {
    const fieldKeysWithValues = fieldService.getFieldKeysWithValues(entityId);
    fieldKeysWithValues.forEach((value, key) => {
      if (key.type === FieldType.Tag) {
        return;
      }
      valueFieldKeysMap.set(key, value);
    });
  });

  const results: ListItem[] = [];

  const uneditableKeys = Array.from(valueFieldKeysMap.keys()).filter(
    (key) => !key.entityTypes.some((et) => props.entityType.includes(et)),
  );

  valueFieldKeysMap.forEach((fieldValues, fieldKey) => {
    fieldValues.forEach((fieldValue) => {
      results.push({
        key: fieldKey,
        value: fieldValue,
        readonly: uneditableKeys.includes(fieldKey),
      });
    });
  });

  results.sort(compareByReadOnly);
  return results;
});

function compareByReadOnly(a: ListItem, b: ListItem) {
  if (a.readonly === b.readonly) {
    return 0;
  }
  return a.readonly ? -1 : 1;
}

const fieldValueSelectedForDeletion = ref(
  undefined as FieldValueDto | undefined,
);

function openDeleteFieldDialog(fieldValue: FieldValueDto) {
  fieldValueSelectedForDeletion.value = fieldValue;
  fieldService.openDeleteFieldDialog();
}
</script>

<template>
  <template v-for="field in fields" :key="field.value.id">
    <ValueField
      :targetEntityId="props.entityId"
      :fieldValueId="[field.value.id]"
      :readonly="props.readonly || field.readonly"
      @update="(newValue) => fieldService.createOrUpdateFieldValue(newValue)"
      @delete="() => openDeleteFieldDialog(field.value)"
    />
    <DeleteFieldDialog
      v-if="
        fieldService.isDeleteFieldDialogOpen && fieldValueSelectedForDeletion
      "
      :fieldValueId="fieldValueSelectedForDeletion.id"
      :deleteMessage="
        t('fields.delete', {
          field:
            fieldService.getFieldKey(
              fieldValueSelectedForDeletion?.fieldKeyId ?? '',
            )?.name ?? '',
        })
      "
    />
  </template>
  <div>
    <VBtn
      v-if="!props.readonly"
      variant="outlined"
      color="caeli5"
      prependIcon="mdi-plus"
      data-testid="entity-add-field"
      @click="fieldService.openFieldDialog"
    >
      {{ t("action.addSomething", { name: t("processes.field") }) }}
    </VBtn>
  </div>
  <FieldDialog
    v-if="fieldService.isFieldDialogOpen"
    :entityType="props.entityType"
    :entityId="props.entityId"
  />
</template>
