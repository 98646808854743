import { type Edge, type Elements, type Node } from "@vue-flow/core";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { useGraphLayout } from "@/app/process/graphviewer/useGraphLayout";
import { useActivityService } from "@/app/process/service/ActivityService";
import { type ActivityEto } from "@/gql/types";

export interface GraphHandleData {
  id: string;
  name: string;
}

export const useGraphService = defineStore("GraphService", () => {
  const activityService = useActivityService();
  const { layout } = useGraphLayout();

  const nodes = ref<Node[]>([]);
  const edges = ref<Edge[]>([]);
  const model = computed<Elements>(() => [...nodes.value, ...edges.value]);

  function fetchGraph({
    rootActivityId,
    useSimpleMode,
  }: {
    rootActivityId: string;
    useSimpleMode: boolean;
  }) {
    nodes.value = createNodes(rootActivityId, useSimpleMode);
    edges.value = createEdges(rootActivityId, useSimpleMode);
    nodes.value = layout({
      nodes: nodes.value,
      edges: edges.value,
    });
  }

  function createNodes(rootActivityId: string, useSimpleMode: boolean) {
    return (
      activityService.getGraph(rootActivityId)?.map((activity) => {
        const { inputs, outputs } = createHandleData(activity, useSimpleMode);

        return {
          id: activity.id,
          data: {
            label: activity.name,
            inputs,
            outputs,
            isSimpleMode: useSimpleMode,
          },
          position: { x: 0, y: 0 },
          type: "graphNode",
        };
      }) ?? []
    );
  }

  function createEdges(rootActivityId: string, useSimpleMode: boolean) {
    const items =
      activityService
        .getGraph(rootActivityId)
        ?.map((activity) => {
          return activity.inputs.map((input) => {
            const outputActivityId =
              activityService.getOutputActivity(input.outputId)?.id ?? "";
            return {
              id: `${activity.id}-${input.id}`,
              source: outputActivityId,
              target: activity.id,
              sourceHandle: useSimpleMode ? "output" : input.outputId,
              targetHandle: useSimpleMode ? "input" : input.id,
              animated: false,
              type: "graphEdge",
              data: {
                isDone: false,
              },
            };
          });
        })
        .flat() ?? [];

    // In simple mode we need to make the edges distinct by source and target
    // to avoid multiple edges between the same nodes
    if (useSimpleMode) {
      return makeDistinctByKeys(items, ["source", "target"]);
    }

    return items;
  }

  function createHandleData(activity: ActivityEto, useSimpleMode: boolean) {
    if (useSimpleMode) {
      return {
        inputs:
          activity.inputs.length === 0 ? [] : [{ id: "input", name: "In" }],
        outputs:
          activity.outputs.length === 0 ? [] : [{ id: "output", name: "Out" }],
      };
    }

    const inputs = activity.inputs.map((input) => {
      return {
        id: input.id,
        name: activityService.getOutput(input.outputId)?.name ?? "N/A",
      } as GraphHandleData;
    });

    const outputs = activity.outputs.map((output) => {
      return {
        id: output.id,
        name: output.name,
      } as GraphHandleData;
    });

    return { inputs, outputs };
  }

  function makeDistinctByKeys<T>(list: T[], keys: (keyof T)[]): T[] {
    const getKey = (obj: T) => keys.map((key) => obj[key]).join("|");

    const uniqueObjects: Record<string, T> = {};

    list.forEach((obj) => {
      const valueKey = getKey(obj);
      if (!uniqueObjects[valueKey]) {
        uniqueObjects[valueKey] = obj;
      }
    });

    return Object.values(uniqueObjects);
  }

  return {
    fetchGraph,
    model,
  };
});
