import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import { processOutputToDto } from "@/app/process/service/mapper/ProcessMapper";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import { type ProcessOutputDto, type ProcessOutputEto } from "@/gql/types";
import {
  DeleteProcessOutputDocument,
  ProcessOutputDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual<ProcessOutputEto, ProcessOutputDto>(
    processOutputToDto,
  ),
  {
    queryName: "processOutput",
    mutation: ProcessOutputDocument,
    deleteMutation: DeleteProcessOutputDocument,
  },
  {
    indexFields: ["processActivityId", "activityOutputId"],
  },
);

export const useProcessOutputStore = defineEntityStore(
  "processOutput",
  entityProvider,
);
