<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useDialogService } from "@/app/process/service/DialogService";
import { type EntityType } from "@/gql/types";

const props = defineProps<{
  documentId: string;
  entityType: EntityType;
}>();

const { t } = useI18n();
const dialogService = useDialogService();
</script>

<template>
  <VTooltip location="start" :text="t('document.generate')">
    <template #activator="{ props: activator }">
      <VBtn
        v-bind="activator"
        :title="t('document.generate')"
        data-testid="generate-document-button"
        variant="plain"
        size="tiny"
        icon="mdi-play"
        @click.stop.prevent="
          dialogService.openGenerateDocumentDialog(
            props.documentId,
            props.entityType,
          )
        "
      />
    </template>
  </VTooltip>
</template>
