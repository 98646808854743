import { defineStore } from "pinia";
import { reactive } from "vue";

export const useBlockedActionStore = defineStore("blocker", () => {
  const blockedKeys = reactive(new Set<string>());

  return {
    blockedKeys,
    block(key: string) {
      blockedKeys.add(key);
    },
    unblock(key: string) {
      blockedKeys.delete(key);
    },
    isBlocked(key: string) {
      return blockedKeys.has(key);
    },
  };
});
