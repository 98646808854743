<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  item?: string;
  items?: string;
}>();

const { t } = useI18n();

const loadingText = computed(() => {
  if (props.items) {
    return t("loading.loading", 2, { named: { items: props.items } });
  } else if (props.item) {
    return t("loading.loading", 1, { named: { item: props.item } });
  } else {
    return t("loading.loadingData");
  }
});
</script>

<template>
  <div class="progress-container">
    <VProgressCircular size="24" width="3" indeterminate />
    <span>{{ loadingText }}</span>
  </div>
</template>
<style scoped>
.progress-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  padding-top: 2em;
}
</style>
