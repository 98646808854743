<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { VIcon } from "vuetify/components";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import { deduplicate } from "@/app/base/utils/array";
import CreateProcessTemplateDialog from "@/app/process/action/CreateProcessTemplateDialog.vue";
import DeleteProcessTemplateDialog from "@/app/process/action/DeleteProcessTemplateDialog.vue";
import EditRootTemplateDialog from "@/app/process/action/EditRootTemplateDialog.vue";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import ProcessTemplateListEntry from "@/app/process/list/ProcessTemplateListEntry.vue";
import ProcessTemplateRootActions from "@/app/process/list/ProcessTemplateRootActions.vue";
import { type RowItem } from "@/app/process/list/TableTypes";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useDialogService } from "@/app/process/service/DialogService";
import { useFieldService } from "@/app/process/service/FieldService";
import { compareByName } from "@/app/process/utils";
import { type ActivityDto, EntityType } from "@/gql/types";

const { t } = useI18n();

const router = useRouter();

const activityService = useActivityService();
const dialogService = useDialogService();
const fieldService = useFieldService();

const isLoading = computed(
  () =>
    activityService.isLoading() ||
    fieldService.isLoadingFieldKey() ||
    fieldService.isLoadingFieldValue(),
);

function stateLabel(activity: ActivityDto) {
  const current = activityService.getCurrentVersion(
    activityService.getMid(activity.id) ?? "undefined",
  );
  const lastReleased = activityService.getLastReleased(
    activityService.getMid(activity.id) ?? "undefined",
  );
  if (!lastReleased) {
    return "draft";
  }
  if (lastReleased.id === current?.id) {
    return "released";
  }
  return "draftReleased";
}

const activityChains = computed(() => {
  return activityService.linearizedActivityGraphs
    .concat()
    .sort((a, b) => compareByName(a[0], b[0]))
    .sort((a, b) => stateLabel(a[0]).localeCompare(stateLabel(b[0])));
});

const rootActivityMids = computed((): string[] =>
  deduplicate(
    activityChains.value.flatMap(
      (activityChain) => activityService.getMid(activityChain[0].id) ?? [],
    ),
  ),
);

const processTemplateRowTitle = t("processes.processTemplate");

const rowItems = computed(() =>
  rootActivityMids.value.flatMap((rootActivityMid) => {
    const current = activityService.getCurrentVersion(rootActivityMid);
    if (!current) {
      return [];
    }
    const lastReleased = activityService.getLastReleased(rootActivityMid);
    if (
      activityService.isArchived(current.id) &&
      (!lastReleased || activityService.isArchived(lastReleased.id))
    ) {
      return [];
    }
    const result: RowItem = {
      key: rootActivityMid,
      class: { released: Boolean(lastReleased) },
      cells: {
        [processTemplateRowTitle]: {
          content: current.name ?? "",
          component: ProcessTemplateListEntry,
          props: {
            rootActivityMid,
          },
        },
        [""]: {
          component: ProcessTemplateRootActions,
          props: {
            class: "justify-end",
            rootActivityMid,
          },
        },
      },
      tags: deduplicate(
        activityChains.value
          .find((activityChain) => activityChain[0].id === current.id)
          ?.flatMap((activity) => fieldService.getActiveTagNames(activity.id)),
      ),
    };
    return result;
  }),
);
</script>

<template>
  <VCard
    class="bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
    :loading="isLoading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      ></VProgressLinear>
    </template>
    <VTooltip location="start" :text="t('processes.createProcessTemplate')">
      <template #activator="{ props }">
        <FloatingActionButton
          v-bind="props"
          icon="mdi-plus"
          primary
          data-testid="create-process-template"
          @click="dialogService.openCreateTemplateDialog"
        />
      </template>
    </VTooltip>
    <VTooltip location="start" :text="t('processes.listView.processesTitle')">
      <template #activator="{ props }">
        <FloatingActionButton
          v-bind="props"
          icon="mdi-play-box-multiple-outline"
          @click="() => router.push({ name: 'processList' })"
        />
      </template>
    </VTooltip>
    <EditRootTemplateDialog />
    <CreateProcessTemplateDialog />
    <DeleteProcessTemplateDialog />

    <div v-if="!activityChains.length" class="text-center w-100 mt-3">
      <VIcon
        class="mt-16"
        color="blue-grey-lighten-3"
        size="100"
        icon="mdi-sim-off-outline"
      />
      <VCardTitle class="mt-4 text-blue-grey-lighten-2"
        >{{ t("processes.listView.noTemplates") }}
      </VCardTitle>
    </div>
    <div v-else class="mx-8 text-caeli6">
      <p class="text-h6 py-3">
        {{ t("processes.processTemplate", 2) }}
      </p>
      <div class="w-100">
        <FilteredDataTable
          contextKey="processTemplates"
          :rowItems="rowItems"
          :mandatoryColumns="[processTemplateRowTitle, '']"
          :availableTags="fieldService.getTagFieldKeys(EntityType.Process)"
          disableColumnToggle
        />
      </div>
    </div>
  </VCard>
</template>
