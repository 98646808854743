<script setup lang="ts">
import { ControlButton } from "@vue-flow/controls";

const ICON_SIZE = 16;

const props = defineProps<{
  icon: string;
  tooltip?: string;
}>();

const emits = defineEmits<(e: "click") => void>();
</script>

<template>
  <VTooltip offset="20" :disabled="!props.tooltip" :text="props.tooltip">
    <template #activator="{ props: tooltipProps }">
      <ControlButton @click="emits('click')">
        <VIcon v-bind="tooltipProps" :size="ICON_SIZE" :icon="props.icon" />
      </ControlButton>
    </template>
  </VTooltip>
</template>
