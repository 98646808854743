export class StorageKeys {
  static readonly graph = {
    showMiniMap: {
      key: "graph.showMiniMap",
      storage: localStorage,
    },
    useSimpleMode: {
      key: "graph.useSimpleMode",
      storage: localStorage,
    },
  };

  static readonly app = {
    drawerClosed: {
      key: "app.drawerClosed",
      storage: localStorage,
    },
    activeTablePage: {
      key: "app.activeTablePage",
      storage: sessionStorage,
    },
  };

  static readonly process = {
    activityStateFilter: {
      key: "process.activity.list.stateFilter",
      storage: localStorage,
    },
    activeTab: {
      key: "process.activeTab",
      storage: sessionStorage,
    },
  };
}

export default {
  baseUrl: import.meta.env.VITE_BASE_URL as string,
  authEnabled: import.meta.env.VITE_AUTH_ENABLED === "true",
  // Application (client) ID of app registration in Azure portal.
  authClientId: import.meta.env.VITE_AUTH_CLIENT_ID as string,
  envIndicator: import.meta.env.VITE_ENV_INDICATOR as string | undefined,
  grafanaUrl: import.meta.env.VITE_GRAFANA_URL as string,
};
