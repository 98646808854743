<!--suppress VueUnrecognizedSlot, RequiredAttributes -->
<script setup lang="ts">
import { Background } from "@vue-flow/background";
import { useVueFlow, VueFlow } from "@vue-flow/core";
import { MiniMap } from "@vue-flow/minimap";
import { useStorage } from "@vueuse/core";
import { nextTick, ref, watch } from "vue";

import GraphEdge from "@/app/process/graphviewer/GraphEdge.vue";
import GraphNode from "@/app/process/graphviewer/GraphNode.vue";
import { useGraphService } from "@/app/process/graphviewer/GraphViewerService";
import GraphViewerToolbar from "@/app/process/graphviewer/GraphViewerToolbar.vue";
import { StorageKeys } from "@/config";

const props = defineProps<{
  rootActivityId: string;
  processId?: string;
}>();

const graphService = useGraphService();
const { fitView } = useVueFlow();
const showMiniMap = useStorage(StorageKeys.graph.showMiniMap.key, true);
const useSimpleMode = useStorage(StorageKeys.graph.useSimpleMode.key, true);
// TODO: Find a better way to update the graph view when the mode changes
const graphKey = ref<number>(0);

function fetch(rootActivityId: string) {
  graphService.fetchGraph({
    rootActivityId,
    useSimpleMode: useSimpleMode.value,
  });
}

watch(
  () => props.rootActivityId,
  (newRootActivityId) => {
    fetch(newRootActivityId);
  },
  { immediate: true },
);

watch(useSimpleMode, async () => {
  fetch(props.rootActivityId);
  graphKey.value += 1;
  await nextTick();
  await fitView();
});
</script>

<template>
  <div :key="graphKey" class="container">
    <VueFlow
      :modelValue="graphService.model"
      :minZoom="0.1"
      :maxZoom="2"
      fitViewOnInit
    >
      <Background />
      <MiniMap v-if="showMiniMap" position="top-right" />
      <GraphViewerToolbar />

      <template #node-graphNode="nodeProps">
        <GraphNode v-bind="nodeProps" />
      </template>

      <template #edge-graphEdge="edgeProps">
        <GraphEdge v-bind="edgeProps" />
      </template>
    </VueFlow>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100%;
}
</style>

<style>
@import "@vue-flow/core/dist/style.css";
@import "@vue-flow/core/dist/theme-default.css";
@import "@vue-flow/controls/dist/style.css";
</style>
