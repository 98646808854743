<script setup lang="ts">
import { useStorage } from "@vueuse/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import drawerBackground from "@/assets/drawerBackground.jpg";
import logoIcon from "@/assets/logoIcon.svg";
import logoText from "@/assets/logoText.svg";
import { StorageKeys } from "@/config";
import { UserAuthService } from "@/services/auth/UserAuthService";

const { t } = useI18n();

const isDrawerClosed = useStorage(StorageKeys.app.drawerClosed.key, false);
const activePage = useStorage(
  StorageKeys.app.activeTablePage.key,
  {},
  StorageKeys.app.activeTablePage.storage,
  { deep: true, initOnMounted: true },
);

interface Route {
  path: string;
  icon: string;
  title: string;
  exact?: boolean;
}

const routes: Route[] = [
  {
    path: "/",
    icon: "mdi-view-dashboard",
    title: "Dashboard",
    exact: true,
  },
  {
    path: "/person/list",
    icon: "mdi-account-multiple",
    title: "Personen",
  },
  {
    path: "/message/list",
    icon: "mdi-email",
    title: "Nachrichten",
  },
  {
    path: "/document/list",
    icon: "mdi-file-document-multiple",
    title: "Dokumente",
  },
  {
    path: "/process/list",
    icon: "mdi-sitemap",
    title: "Prozesse",
  },
  {
    path: "/process/activity/list",
    icon: "mdi-checkbox-marked",
    title: "Aufgaben",
  },
];

const chevron = computed(() =>
  isDrawerClosed.value ? "mdi-chevron-right" : "mdi-chevron-left",
);

function isActive(route: Route) {
  const path = window.location.pathname;
  return route.exact ? route.path === path : path.startsWith(route.path);
}
</script>

<template>
  <VNavigationDrawer
    :modelValue="true"
    :rail="isDrawerClosed"
    permanent
    :image="drawerBackground"
  >
    <VListItem nav :to="routes[0]" :active="false">
      <div class="d-flex align-center">
        <img
          id="pisys-logo-icon"
          class="ml-2"
          :src="logoIcon"
          height="25"
          alt="Logo"
        />

        <Transition>
          <img
            v-if="!isDrawerClosed"
            id="pisys-logo-text"
            class="ml-2"
            :src="logoText"
            height="20"
            :alt="t('app.logoText')"
          />
        </Transition>
      </div>
    </VListItem>

    <VDivider></VDivider>

    <VList density="compact" nav data-testid="navigation-items">
      <VListItem
        v-for="route in routes"
        :key="route.path"
        nav
        :prependIcon="route.icon"
        :to="route.path"
        :active="isActive(route)"
        @click="activePage = {}"
      >
        <VListItemTitle>{{ route.title }}</VListItemTitle>
      </VListItem>
    </VList>

    <VDivider></VDivider>

    <VList density="compact" nav>
      <VListItem prependIcon="mdi-logout" @click="UserAuthService.logOutUser()">
        <VListItemTitle>{{ t("user.logout") }}</VListItemTitle>
      </VListItem>

      <VDivider></VDivider>

      <VListItem @click.stop="isDrawerClosed = !isDrawerClosed">
        <template #prepend>
          <VIcon>{{ chevron }}</VIcon>
        </template>
        <VListItemTitle v-if="!isDrawerClosed">{{
          t("drawer.collapse")
        }}</VListItemTitle>
      </VListItem>
    </VList>
  </VNavigationDrawer>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
