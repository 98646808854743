<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  initialValue?: number;
  readonly?: boolean;
  noLabel?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<(event: "update", value: number) => void>();

const valueNumber = ref(props.initialValue ?? 0);

watch(
  () => props.initialValue,
  (newValue) => {
    valueNumber.value = newValue ?? 0;
  },
);
</script>

<template>
  <VTextField
    v-model="valueNumber"
    :label="noLabel ? undefined : t('processes.singleView.outputTypes.NUMBER')"
    class="w-100"
    variant="outlined"
    density="compact"
    type="number"
    step="any"
    hideDetails="auto"
    :readonly="props.readonly"
    @blur="() => emits('update', valueNumber)"
  />
</template>
