<script lang="ts" setup>
import { computed } from "vue";

import OptionField from "@/app/process/field/OptionField.vue";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import { type FieldKeyDto, type FieldValueDto } from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

function emitUpdate(valueNumber: string) {
  if (!valueNumber) {
    return;
  }
  emit("update", {
    ...props.fieldValue,
    value: {
      valueNumber: Number.parseFloat(valueNumber),
    },
  });
}

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);
</script>

<template>
  <ReadonlyField
    v-if="props.readonly"
    :label="props.label"
    :value="String(props.fieldValue?.value?.valueNumber ?? '')"
  />
  <OptionField
    v-else-if="isOptionField"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :label="props.label"
    itemTitle="valueNumber"
    @update="
      (value) => emit('update', { ...props.fieldValue, optionId: value })
    "
  />
  <VTextField
    v-else
    :modelValue="props.fieldValue?.value?.valueNumber"
    :label="props.fieldKey.name ?? ''"
    variant="outlined"
    density="compact"
    type="number"
    hideDetails="auto"
    data-testid="number-field-input"
    @blur="
      (event: Event) => emitUpdate((event.target as HTMLInputElement).value)
    "
  />
</template>
