<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  initialValue?: string;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<(event: "update", value: string) => void>();

const isDialogOpen = ref<boolean>(false);
const isEditMode = ref<boolean>(false);
const json = ref<string | undefined>(formatJson(props.initialValue));

const formattedJson = computed(() => formatJson(json.value));
const hasValidSyntax = computed(() => {
  if (json.value === undefined) {
    return undefined;
  }
  if (json.value === "") {
    return false;
  }
  try {
    JSON.parse(json.value);
    return true;
  } catch (error) {
    return false;
  }
});

watch(
  () => props.initialValue,
  (newValue) => {
    json.value = formatJson(newValue);
  },
);

watch(isEditMode, (newValue) => {
  if (!newValue) {
    update();
  }
});

function formatJson(input?: string): string | undefined {
  if (!input) {
    return undefined;
  }
  try {
    return JSON.stringify(JSON.parse(input), null, 2);
  } catch (error) {
    return undefined;
  }
}

function update() {
  if (props.initialValue === json.value || !formattedJson.value) {
    return;
  }
  emits("update", formattedJson.value);
}

function saveButtonSeverity() {
  if (hasValidSyntax.value === false) {
    return "danger";
  }
  if (isEditMode.value) {
    return "primary";
  }
  return "secondary";
}

function textareaClasses() {
  const styles: string[] = [];

  styles.push("!font-mono");

  if (hasValidSyntax.value === true) {
    styles.push("!bg-green-50");
  } else if (hasValidSyntax.value === false) {
    styles.push("!border-red-500", "!bg-red-50");
  } else {
    styles.push("!bg-gray-50");
  }

  return styles;
}
</script>

<template>
  <div>
    <Button
      text
      raised
      severity="secondary"
      class="mb-2"
      :label="t('processes.singleView.outputTypes.JSON')"
      @click="isDialogOpen = true"
    />

    <Dialog
      v-model:visible="isDialogOpen"
      modal
      maximizable
      :dismissableMask="hasValidSyntax !== false"
      :closable="hasValidSyntax !== false"
      :header="t('processes.singleView.outputTypes.JSON')"
      :style="{ width: '75vw' }"
      @afterHide="
        () => {
          update();
          isEditMode = false;
        }
      "
    >
      <template #header>
        <div
          class="flex flex-row items-center justify-between w-screen mr-2 h-[2.5rem]"
        >
          <p class="text-lg font-bold">
            {{ t("processes.singleView.outputTypes.JSON") }}
          </p>

          <div class="flex flex-row gap-2 items-center">
            <p v-if="hasValidSyntax === false" class="text-red-500">
              {{ t("processes.singleView.objectTypeDialog.syntaxError") }}
            </p>

            <Button
              v-if="hasValidSyntax !== false"
              text
              rounded
              outlined
              :severity="saveButtonSeverity()"
              icon="mdi mdi-pencil"
              :label="isEditMode ? t('ui.save') : ''"
              @click="isEditMode = !isEditMode"
            />
          </div>
        </div>
      </template>

      <pre v-if="!isEditMode">{{ formattedJson }}</pre>

      <Textarea
        v-else
        v-model="json"
        autoResize
        class="w-full"
        spellcheck="false"
        :invalid="hasValidSyntax === false"
        :pt="{
          root: {
            class: textareaClasses(),
            style: { fontSize: '1em' },
          },
        }"
      />
    </Dialog>
  </div>
</template>
