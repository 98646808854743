<script lang="ts" setup>
import { ref } from "vue";

import { useFieldService } from "@/app/process/service/FieldService";
import { type FieldKeyDto } from "@/gql/types";

const props = defineProps<{ fieldKey: FieldKeyDto }>();
const emits = defineEmits<(e: "delete", id: string) => void>();

const fieldService = useFieldService();

const editName = ref(props.fieldKey.name);
</script>

<template>
  <div class="d-flex align-center" data-testid="rename-tag-entry-enclosing-div">
    <VTextField
      v-model="editName"
      density="compact"
      variant="outlined"
      hideDetails="auto"
      @focusout="
        () =>
          fieldService.createOrUpdateFieldKey({
            id: props.fieldKey.id,
            name: editName,
          })
      "
    />
    <VBtn
      variant="plain"
      icon="mdi-delete-outline"
      class="ml-1 text-red-darken-2"
      size="small"
      :data-testid="`rename-tag-entry-delete-tag-${editName}`"
      @click="() => emits('delete', props.fieldKey.id)"
    />
  </div>
</template>
