import { type Router } from "vue-router";

import config from "@/config";
import { getAccessToken } from "@/services/auth/msal/accessTokens";
import { msalInstance } from "@/services/auth/msal/msalConfig";
import { configureAppForMSAL } from "@/services/auth/msal/setup";

/*
PiSys users are managed in Azure Active Directory.
This implementation integrates with AD using the https://github.com/AzureAD/microsoft-authentication-library-for-js
As of 9/2022 there is no official Vue wrapper for MSAL. Available third-party wrappers have questionable quality.
Therefore, we integrate MSAL directly. The code is based on the example
https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-browser-samples/vue3-sample-app
*/

// Functions only used in app setup
export function configureAppForUserAuth(router: Router) {
  configureAppForMSAL(router);
}
export function getAccessTokenGenerator() {
  return getAccessToken;
}

/**
 * User auth functions exposed to business components.
 */
export class UserAuthService {
  public static isAuthEnabled() {
    return config.authEnabled;
  }

  public static getUserFullName(): string {
    return (
      (config.authEnabled ? msalInstance.getActiveAccount()?.name : null) ??
      "Anonymous User"
    );
  }

  public static getUserMail(): string {
    return (
      (config.authEnabled ? msalInstance.getActiveAccount()?.username : null) ??
      "anonymous@caeli-wind.de"
    );
  }

  public static async logOutUser() {
    if (config.authEnabled && msalInstance.getActiveAccount() != null) {
      console.log("Logging out the active account");
      await msalInstance.logoutRedirect();
    }
  }
}
