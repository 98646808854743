<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDialogService } from "@/app/process/service/DialogService";
import { type ProcessActivityEto } from "@/gql/types";

const router = useRouter();

const props = defineProps<{
  processActivity: ProcessActivityEto;
}>();

const { t } = useI18n();

const processUiStore = useDialogService();
</script>

<template>
  <div class="d-flex align-center ga-2 py-1">
    <VTooltip location="start" :text="t('ui.edit')">
      <template #activator="{ props: activator }">
        <VBtn
          v-bind="activator"
          :title="t('ui.edit')"
          variant="plain"
          size="tiny"
          icon="mdi-pencil"
          @click="
            () =>
              router.push({
                name: 'customActivity',
                params: {
                  activityId: props.processActivity.activity.id,
                  processId: props.processActivity.processId,
                },
              })
          "
        />
      </template>
    </VTooltip>
    <VTooltip location="start" :text="t('action.delete')">
      <template #activator="{ props: activator }">
        <VBtn
          v-bind="activator"
          :title="t('action.delete')"
          variant="plain"
          color="error"
          size="tiny"
          icon="mdi-delete"
          @click.stop.prevent="
            () =>
              props.processActivity
                ? processUiStore.openActivityDeleteDialog(
                    [props.processActivity.activity],
                    props.processActivity.processId,
                  )
                : undefined
          "
        />
      </template>
    </VTooltip>
  </div>
</template>
