<script lang="ts" setup>
const props = defineProps<{
  tristateValue?: boolean | null;
  readonly?: boolean;
}>();

const emit = defineEmits<(e: "update", value?: boolean | null) => void>();
</script>

<template>
  <div style="width: 2rem">
    <VSwitch
      :color="
        props.tristateValue === true
          ? 'caeli5'
          : props.tristateValue === false
            ? 'red'
            : 'caeli6'
      "
      density="compact"
      :indeterminate="
        props.tristateValue === undefined || props.tristateValue === null
      "
      :modelValue="props.tristateValue"
      hideDetails="auto"
      :readonly="props.readonly"
      @update:modelValue="(tristate) => emit('update', tristate)"
    />
  </div>
</template>
