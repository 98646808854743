import { type _GettersTree, defineStore, type Store } from "pinia";

import { type PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import {
  ApolloCrudRepo,
  type QueryParams,
} from "@/app/process/service/persistence/ApolloCrudRepo";
import { ListDebouncer, withoutTypename } from "@/app/process/utils";
import { type Eto } from "@/gql/types";

export interface EntityStoreState<E extends Eto, D extends { id: string }> {
  cache: PendingPersistedLookupDual<E, D>;
  repo: ApolloCrudRepo<E, D>;
  queryParams: QueryParams;
  onAfterRegister?: (etos: E[]) => void;
  onBeforeUnregister?: (etos: E[]) => void;
  indexedFields: (keyof E)[];
  indices: Map<keyof E, Map<string, Map<string, E>>>;
}

export type EntityStoreGetters<
  E extends Eto,
  D extends { id: string },
> = _GettersTree<EntityStoreState<E, D>>;

export interface EntityStoreActions<E extends Eto, D extends { id: string }> {
  createOrUpdate(dto: D): Promise<void>;
  deleteById(id: string): Promise<boolean>;
  getAll(): E[];
  getById(id: string): E | undefined;
  getFiltered(key: keyof E, value: string): E[];
  isLoading(id?: string): boolean;
  registerAllLoaded(etos: E[]): void;
  unregisterAllLoaded(ids: string[]): void;
  loadAll(): void;
  loadById(id: string): void;
  addToIndices(etos: E[]): void;
  removeFromIndices(eto: E): void;
  markRefetch(id: string): void;
}

export type EntityStore<E extends Eto, D extends { id: string }> = Store<
  string,
  EntityStoreState<E, D>,
  EntityStoreGetters<E, D>,
  EntityStoreActions<E, D>
>;

export interface EntityProviderHooks<E extends Eto> {
  onAfterRegister?: (etos: E[]) => void;
  onBeforeUnregister?: (etos: E[]) => void;
  indexFields?: (keyof E)[];
}

export class EntityProvider<E extends Eto, D extends { id: string }> {
  cache: PendingPersistedLookupDual<E, D>;
  repo: ApolloCrudRepo<E, D>;
  queryParams: QueryParams;
  onAfterRegister?: (etos: E[]) => void;
  onBeforeUnregister?: (etos: E[]) => void;

  isLoadingAll: boolean | undefined = undefined;
  isLoadingIds = new Map<string, boolean>();

  indexedFields: (keyof E)[];
  indices: Map<keyof E, Map<string, Map<string, E>>>;

  listDebouncer = new ListDebouncer<string>();

  constructor(
    cache: PendingPersistedLookupDual<E, D>,
    queryParams: QueryParams,
    hooks?: EntityProviderHooks<E>,
  ) {
    this.cache = cache;
    this.queryParams = queryParams;
    this.repo = new ApolloCrudRepo<E, D>(queryParams);
    this.onAfterRegister = hooks?.onAfterRegister;
    this.onBeforeUnregister = hooks?.onBeforeUnregister;
    this.indexedFields = hooks?.indexFields ?? [];
    this.indices = this.initializedIndex();
  }

  private initializedIndex() {
    const indices = new Map();
    this.indexedFields.forEach((field) => {
      indices.set(field, new Map());
    });
    return indices;
  }

  getAll() {
    if (this.queryParams.listQuery && this.isLoadingAll === undefined) {
      this.loadAll();
    }
    return this.cache.getAllPersisted();
  }

  getById(id: string) {
    if (
      this.queryParams.singleQuery &&
      this.isLoadingIds.get(id) === undefined
    ) {
      this.loadById(id);
    }
    return this.cache.getPersistedOptional(id);
  }

  getFiltered(key: keyof E, value: string) {
    const index = this.indices.get(key);
    if (!index) {
      console.warn(`${String(key)} is not indexed.`);
      return this.getAll().filter((eto) => String(eto[key]) === value);
    }
    return Array.from(index?.get(value)?.values() ?? new Set<E>());
  }

  loadAll() {
    this.isLoadingAll = true;
    this.repo
      .findAll()
      .then((results) => {
        this.isLoadingAll = false;
        this.registerAllLoaded(results);
      })
      .catch(() => (this.isLoadingAll = false));
  }

  loadById(id: string) {
    this.isLoadingIds.set(id, true);
    this.listDebouncer.add(id);
    void this.listDebouncer.run((ids) => {
      this.repo
        .findByIds(ids)
        .then((result) => {
          result.forEach((eto) => this.isLoadingIds.set(eto.id, false));
          this.registerAllLoaded(result);
        })
        .catch(() => ids.forEach((item) => this.isLoadingIds.set(item, false)));
    });
  }

  async createOrUpdate(dto: D) {
    return this.repo
      .createOrUpdate(dto)
      .then((result) => this.registerAllLoaded([result]));
  }

  async deleteById(id: string) {
    this.unregisterAllLoaded([id]);
    return this.repo.deleteById(id);
  }

  registerAllLoaded(etos: E[]) {
    const items = etos.map(withoutTypename);
    this.cache.setPersisted(items);
    const individualEntries: Iterable<[string, boolean]> = items.map((eto) => [
      eto.id,
      false,
    ]);
    this.isLoadingIds = new Map([
      ...this.isLoadingIds.entries(),
      ...individualEntries,
    ]);
    this.addToIndices(items);
    if (this.onAfterRegister) {
      this.onAfterRegister(items);
    }
  }

  unregisterAllLoaded(ids: string[]) {
    ids.forEach((id) => {
      const eto = this.cache.getPersistedOptional(id);
      if (eto) {
        if (this.onBeforeUnregister) {
          this.onBeforeUnregister([eto]);
        }
        this.removeFromIndices(eto);
      }
      this.cache.remove(id);
      this.isLoadingIds.set(id, false);
    });
  }

  isLoading(id?: string) {
    return id ? this.isLoadingIds.get(id) === true : this.isLoadingAll === true;
  }

  markRefetch(id: string) {
    this.isLoadingIds.delete(id);
  }

  addToIndices(etos: E[]) {
    const updatedIndices = new Map(this.indices);
    this.indexedFields.forEach((field) => {
      const updatedFieldMap = new Map(this.indices.get(field));
      etos.forEach((eto) => {
        const stringValue = String(eto[field]);
        let instancesById = updatedFieldMap.get(stringValue);
        if (!instancesById) {
          instancesById = new Map();
          updatedFieldMap.set(stringValue, instancesById);
        }
        instancesById.set(eto.id, eto);
      });
      updatedIndices.set(field, updatedFieldMap);
    });
    this.indices = updatedIndices;
  }

  removeFromIndices(eto: E) {
    [...this.indices.keys()].forEach((field) => {
      const stringValue = String(eto[field]);
      const index = this.indices.get(field);
      const instancesById = index?.get(stringValue);
      instancesById?.delete(eto.id);
    });
  }
}

export function defineEntityStore<E extends Eto, D extends { id: string }>(
  storeId: string,
  entityProvider: EntityProvider<E, D>,
) {
  return defineStore<
    string,
    EntityStoreState<E, D>,
    EntityStoreGetters<E, D>,
    EntityStoreActions<E, D>
  >(storeId, {
    state: () => ({ ...entityProvider }),
    getters: {},
    actions: {
      isLoading(id?: string): boolean {
        return entityProvider.isLoading.bind(this)(id);
      },
      deleteById(id: string): Promise<boolean> {
        return entityProvider.deleteById.bind(this)(id);
      },
      createOrUpdate(dto: D): Promise<void> {
        return entityProvider.createOrUpdate.bind(this)(dto);
      },
      getAll(): E[] {
        return entityProvider.getAll.bind(this)();
      },
      getById(id: string): E | undefined {
        return entityProvider.getById.bind(this)(id);
      },
      getFiltered(key: keyof E, value: string): E[] {
        return entityProvider.getFiltered.bind(this)(key, value);
      },
      registerAllLoaded(etos: E[]): void {
        entityProvider.registerAllLoaded.bind(this)(etos);
      },
      unregisterAllLoaded(ids: string[]): void {
        entityProvider.unregisterAllLoaded.bind(this)(ids);
      },
      loadAll() {
        entityProvider.loadAll.bind(this)();
      },
      loadById(id: string) {
        entityProvider.loadById.bind(this)(id);
      },
      addToIndices(etos: E[]) {
        entityProvider.addToIndices.bind(this)(etos);
      },
      removeFromIndices(eto: E) {
        entityProvider.removeFromIndices.bind(this)(eto);
      },
      markRefetch(id: string) {
        entityProvider.markRefetch.bind(this)(id);
      },
    },
  });
}
