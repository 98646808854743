<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDialogService } from "@/app/process/service/DialogService";
import { useProcessService } from "@/app/process/service/ProcessService";

const props = defineProps<{
  processName: string;
  rootActivityId?: string;
  onAfterApply?: () => void;
}>();

const { t } = useI18n();

const processService = useProcessService();
const dialogService = useDialogService();
const router = useRouter();
const isStartingProcess = ref<boolean>(false);

async function startProcess() {
  if (!props.rootActivityId) {
    return;
  }

  await startProcessAndNavigate(props.rootActivityId, props.processName);

  if (props.onAfterApply) {
    props.onAfterApply();
  }
}

async function startProcessAndNavigate(
  rootActivityId: string,
  processName: string,
) {
  isStartingProcess.value = true;

  const processId = await processService.startProcess(
    rootActivityId,
    processName,
  );

  if (!processId) {
    console.error("Missing process ID after start");
    return;
  }

  const rootProcessActivity = processService.getProcessActivityByTemplate(
    processId,
    rootActivityId,
  );

  processService
    .getProcesses()
    .forEach((process) => dialogService.collapse(process.id));

  dialogService.expand(processId);

  isStartingProcess.value = false;

  await router.push({
    name: "processActivity",
    params: { processId, processActivityId: rootProcessActivity?.id },
  });
}
</script>

<template>
  <VBtn
    variant="flat"
    block
    color="caeli5"
    :disabled="
      !props.rootActivityId ||
      props.processName.length === 0 ||
      isStartingProcess
    "
    :loading="isStartingProcess"
    @click.stop.prevent="startProcess"
    >{{ t("processes.startButton.startButtonLabel") }}
  </VBtn>
</template>
