<script lang="ts" setup>
import { computed, ref } from "vue";

import { formStyles } from "@/app/base/form/formStyles";
import OptionField from "@/app/process/field/OptionField.vue";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import { usePersonService } from "@/app/process/service/PersonService";
import { type FieldKeyDto, type FieldValueDto } from "@/gql/types";

const props = defineProps<{
  fieldKey?: FieldKeyDto;
  fieldValue?: FieldValueDto;
  initialValue?: string;
  label?: string;
  readonly?: boolean;
}>();

const emit =
  defineEmits<(e: "update", value: FieldValueDto | string) => void>();

const unknownName = "?";

const valueOverrides: Partial<FieldValueDto> = {
  // Old values must be cleared, if eg. the field switched to an option field.
  optionId: null,
  value: {
    valueString: null,
    valueDate: null,
    valueNumber: null,
    valueBoolean: null,
    valueEntityId: null,
    valueJson: null,
  },
};

const personService = usePersonService();

const processOutputPersonId = ref(props.initialValue ?? "");

const isOptionField = computed(
  () => (props.fieldKey?.options?.length ?? 0) > 0,
);

const allPersons = computed(() => personService.getAll());

const person = computed(() => {
  const id =
    props.fieldValue?.value?.valueEntityId ?? processOutputPersonId.value;
  if (!id) {
    return undefined;
  }
  return personService.getById(id);
});

function getChosenPersonId() {
  if (props.fieldValue?.optionId) {
    const chosenOption = props.fieldKey?.options?.find(
      (option) => option.id === props.fieldValue?.optionId,
    );
    return chosenOption?.value?.valueEntityId;
  }
  return props.fieldValue?.value?.valueEntityId ?? processOutputPersonId.value;
}

const personOptions = computed(() =>
  props.fieldKey?.options?.map((option) => {
    const personOption = allPersons.value.find(
      (it) => it.id === option.value?.valueEntityId,
    );
    return {
      id: option.id,
      title: personOption?.name ?? "",
    };
  }),
);

function emitOptionUpdate(selectedOptionId: string) {
  const personOption = props.fieldKey?.options?.find(
    (option) => option.id === selectedOptionId,
  );
  if (personOption && props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      optionId: personOption.id,
    });
  }
}

function emitUpdate(selectedPersonId?: string | null) {
  if (!selectedPersonId || selectedPersonId.length === 0) {
    return;
  }
  if (props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      value: {
        valueEntityId: selectedPersonId,
      },
    });
  } else {
    emit("update", selectedPersonId);
    processOutputPersonId.value = selectedPersonId;
  }
}
</script>

<template>
  <div v-if="props.readonly">
    <ReadonlyField :label="props.label ?? ''">
      <div class="d-flex w-100 toastui-editor-contents">
        <div v-if="!person">{{ unknownName }}</div>
        <RouterLink
          v-else
          class=""
          :to="{
            name: 'personView',
            params: { personId: person.id },
          }"
        >
          {{ person?.name }}
        </RouterLink>
      </div>
    </ReadonlyField>
  </div>

  <OptionField
    v-else-if="isOptionField && props.fieldKey"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :options="personOptions"
    :label="props.label"
    @update="emitOptionUpdate"
  />
  <VAutocomplete
    v-else
    :modelValue="getChosenPersonId()"
    v-bind="formStyles"
    :label="fieldKey?.name ?? label ?? 'Person'"
    :items="allPersons"
    itemTitle="name"
    itemValue="id"
    :hideSelected="true"
    hideDetails="auto"
    data-testid="person-field-multiselect"
    @update:modelValue="emitUpdate"
  />
</template>
