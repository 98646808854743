<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { deduplicate } from "@/app/base/utils/array";
import { translateEnum } from "@/app/base/utils/i18n";
import OutputTable from "@/app/process/output/OutputTable.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import {
  UI_DIALOG,
  useDialogService,
} from "@/app/process/service/DialogService";
import { type ActivityOutputEto, ActivityOutputType } from "@/gql/types";

const props = defineProps<{ activityId: string }>();

const { t } = useI18n();

const activityService = useActivityService();
const dialogService = useDialogService();
const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.SELECT_INPUTS),
);

const descendantActivityIds = computed(() =>
  activityService
    .getDescendantActivities(props.activityId)
    .map((activity) => activity.id),
);
const descendantOutputIds = computed(() =>
  activityService
    .getDescendantActivities(props.activityId)
    .flatMap((activity) => activityService.getOutputIds(activity.id, false)),
);

const templateFilter = ref<string | null>(null);
const templateFilterItems = computed(() =>
  activityService.unreleasedRootActivities.filter(
    (rootActivity) => rootActivity.id !== props.activityId,
  ),
);
const activityFilter = ref<string[]>([]);
const activityFilterItems = computed(() => {
  if (!templateFilter.value) {
    return activityService
      .getAllActivities()
      .filter((activity) => !descendantActivityIds.value.includes(activity.id));
  }
  const activities = deduplicate(
    activityService
      .getDescendantActivities(templateFilter.value, true)
      .concat(
        activityFilter.value.flatMap(
          (activityId) => activityService.getActivity(activityId) ?? [],
        ),
      ),
  );
  return activities.filter(
    (activity) =>
      activity.id !== props.activityId &&
      !descendantActivityIds.value.includes(activity.id),
  );
});
const typeFilter = ref<ActivityOutputType[]>([]);
const typeFilterItems = Object.values(ActivityOutputType).map((outputType) => ({
  outputType,
  name: translateEnum("processes.singleView.outputTypes", outputType),
}));

function initializeFilters() {
  const rootActivity = activityService.getRootActivity(props.activityId);
  if (rootActivity && rootActivity.id !== props.activityId) {
    templateFilter.value = rootActivity.id;
  }
  activityFilter.value = [];
  typeFilter.value = [];
  selectedOutputIds.value = [];
  activeOutputs.value = activityService
    .getInputs(props.activityId, false)
    .flatMap((input) => activityService.getOutput(input.outputId) ?? []);
}

onMounted(initializeFilters);

watch(dialogOpen, () => {
  if (dialogOpen.value) {
    initializeFilters();
  }
});

const activeOutputs = ref<ActivityOutputEto[]>([]);
const canRemove = computed(() => activeOutputs.value.length > 1);
const activeOutputIds = computed(() =>
  activeOutputs.value.map((output) => output.id),
);

const ownOutputIds = computed(() =>
  activityService.getOutputIds(props.activityId, false),
);

function getBaseSetOfOutputIds() {
  const baseSelection: string[] = selectedOutputIds.value.slice();
  if (activityFilter.value.length) {
    activityFilter.value.forEach((activityId) =>
      baseSelection.push(...activityService.getOutputIds(activityId, false)),
    );
  } else if (templateFilter.value) {
    baseSelection.push(
      ...(
        activityService.linearizedActivityGraphs.find(
          (graph) => graph[0].id === templateFilter.value,
        ) ?? []
      ).flatMap((activity) => activityService.getOutputIds(activity.id, false)),
    );
  } else {
    baseSelection.push(
      ...activityService
        .getAllActivities()
        .flatMap((activity) =>
          activityService.getOutputIds(activity.id, false),
        ),
    );
  }
  return deduplicate(baseSelection);
}

function filterBySelectedTypes(idFilteredOutputs: ActivityOutputEto[]) {
  return idFilteredOutputs.filter((output) =>
    typeFilter.value.some(
      (type) => type.toLowerCase() === output?.type?.toLowerCase(),
    ),
  );
}

const selectableOutputs = computed(() => {
  const idFilteredOutputs = getBaseSetOfOutputIds()
    .filter(
      (outputId) =>
        !ownOutputIds.value.includes(outputId) &&
        !descendantOutputIds.value.includes(outputId) &&
        !activeOutputIds.value.includes(outputId),
    )
    .flatMap((outputId) => activityService.getOutput(outputId) ?? []);
  return typeFilter.value.length
    ? filterBySelectedTypes(idFilteredOutputs)
    : idFilteredOutputs;
});

const selectedOutputIds = ref<string[]>([]);

function addSelectedToActiveOutputs() {
  activeOutputs.value.push(
    ...selectedOutputIds.value.flatMap(
      (outputId) => activityService.getOutput(outputId) ?? [],
    ),
  );
  selectedOutputIds.value = [];
}

function removeActiveOutput(outputId: string) {
  activeOutputs.value = activeOutputs.value.filter(
    (output) => output.id !== outputId,
  );
}

function applySelection() {
  const currentInputs = activityService.getInputs(props.activityId, false);
  const inputsToRemove = currentInputs.filter(
    (input) => !activeOutputIds.value.includes(input.outputId),
  );
  const outputIdsToAdd = activeOutputIds.value.filter(
    (outputId) => !currentInputs.some((input) => input.outputId === outputId),
  );
  outputIdsToAdd.forEach((outputId) =>
    activityService.createOrUpdateInput({
      id: uuidv4(),
      activityId: props.activityId,
      outputId,
    }),
  );
  inputsToRemove.forEach((input) => activityService.removeInput(input.id));
}
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="800">
    <VCard>
      <VCardTitle>{{ t("processes.input", 2) }}</VCardTitle>
      <div class="px-5 pb-2 d-flex flex-column ga-3">
        <VCard variant="flat" class="d-flex flex-column ga-3">
          <span class="text-caption">{{ t("processes.filterLabel") }}</span>
          <VSelect
            v-model="templateFilter"
            :items="templateFilterItems"
            itemValue="id"
            itemTitle="name"
            density="compact"
            variant="outlined"
            :label="t('processes.processTemplate')"
            hideDetails="auto"
            :readonly="true"
          >
            <template #no-data>
              <VListItem :title="t('ui.noData')" />
            </template>
          </VSelect>
          <VSelect
            v-model="activityFilter"
            :items="activityFilterItems"
            itemValue="id"
            itemTitle="name"
            density="compact"
            variant="outlined"
            :label="t('processes.activity')"
            :clearable="true"
            :multiple="true"
            hideDetails="auto"
          >
            <template #no-data>
              <VListItem :title="t('ui.noData')" />
            </template>
          </VSelect>
          <VSelect
            v-model="typeFilter"
            :items="typeFilterItems"
            itemTitle="name"
            itemValue="key"
            density="compact"
            variant="outlined"
            :label="t('processes.singleView.outputTypeLabel')"
            :clearable="true"
            :multiple="true"
            hideDetails="auto"
          >
            <template #no-data>
              <VListItem :title="t('ui.noData')" />
            </template>
            <template #selection="{ item }">
              <VChip density="compact">
                {{ item.raw.name }}
              </VChip>
            </template>
          </VSelect>
        </VCard>
        <div class="text-center mt-3">
          <VSelect
            v-model="selectedOutputIds"
            :items="selectableOutputs"
            itemValue="id"
            itemTitle="name"
            density="compact"
            variant="outlined"
            :label="t('processes.input', 2)"
            hideDetails="auto"
            :multiple="true"
          >
            <template #no-data>
              <VListItem :title="t('ui.noData')" />
            </template>
            <template #item="{ props: itemProps, item }">
              <VListItem
                v-bind="itemProps"
                :subtitle="
                  activityService.getActivity(item.raw?.activityId ?? '')
                    ?.name ?? ''
                "
              />
            </template>
          </VSelect>
          <VBtn
            variant="text"
            color="caeli5"
            :disabled="!selectedOutputIds.length"
            :block="true"
            @click="addSelectedToActiveOutputs"
          >
            {{
              t("action.addSomething", {
                name: t("processes.input", selectedOutputIds.length),
              })
            }}
          </VBtn>
        </div>
        <OutputTable
          contextKey="outputSelection"
          :outputs="activeOutputs"
          :canRemove="canRemove"
          height="12rem"
          @remove="removeActiveOutput"
        />
      </div>
      <VCardActions class="d-flex flex-column">
        <VBtn
          :block="true"
          variant="flat"
          color="caeli5"
          @click="
            () => {
              addSelectedToActiveOutputs();
              applySelection();
              dialogService.closeDialog(UI_DIALOG.SELECT_INPUTS);
            }
          "
          >{{ t("action.confirmSelection") }}
        </VBtn>
        <VBtn
          :block="true"
          @click="() => dialogService.closeDialog(UI_DIALOG.SELECT_INPUTS)"
          >{{ t("action.cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
