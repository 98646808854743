import {
  type ActivityDto,
  type ActivityEto,
  type ActivityInputDto,
  type ActivityInputEto,
  type ActivityOutputDto,
  type ActivityOutputEto,
  type ActivityTaskDto,
  type ActivityTaskEto,
} from "@/gql/types";

export function activityInputToDto(input: ActivityInputEto): ActivityInputDto {
  return {
    id: input.id,
    outputId: input.outputId,
  };
}

export function activityOutputToDto(
  output: ActivityOutputEto,
): ActivityOutputDto {
  return {
    id: output.id,
    activityId: output.activityId,
    description: output.description,
    type: output.type,
    name: output.name,
    sortOrder: output.sortOrder,
    important: output.important,
  };
}

export function activityTaskToDto(task: ActivityTaskEto): ActivityTaskDto {
  return {
    id: task.id,
    description: task.description,
    title: task.title,
    sortOrder: task.sortOrder,
  };
}

export function activityToDto(activity: ActivityEto): ActivityDto {
  return {
    id: activity.id,
    name: activity.name,
    goal: activity.goal,
    description: activity.description,
  };
}
