import { InteractionRequiredAuthError } from "@azure/msal-browser";

import {
  baseLoginRequest,
  msalInstance,
} from "@/services/auth/msal/msalConfig";

export async function getAccessToken(): Promise<string> {
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...baseLoginRequest,
    });
    return response.accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(baseLoginRequest);
    }
    throw e;
  }
}
