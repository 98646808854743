import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import { useHexColorService } from "@/app/process/service/HexColorService";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import {
  DeleteFieldKeyDocument,
  FieldKeyDocument,
  type FieldKeyDto,
  type FieldKeyEto,
  GetFieldKeyDocument,
  GetFieldKeysDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: FieldKeyEto) => ({ id: eto.id }) as FieldKeyDto,
  ),
  {
    queryName: "fieldKey",
    listQuery: GetFieldKeysDocument,
    singleQuery: GetFieldKeyDocument,
    mutation: FieldKeyDocument,
    deleteMutation: DeleteFieldKeyDocument,
  },
  {
    onAfterRegister: (etos: FieldKeyEto[]) => {
      etos.forEach((eto) => {
        if (eto.colorHex) {
          useHexColorService().updateNextColorIndex(eto.colorHex);
        }
      });
    },
  },
);

export const useFieldKeyStore = defineEntityStore("fieldKey", entityProvider);
