import { type InjectionKey } from "vue";

import { type Dialog } from "./Dialog";

export interface DialogProvider {
  confirm: (dialog: Dialog) => Promise<boolean>;
}

export const DialogProvideKey: InjectionKey<DialogProvider> =
  Symbol("DialogProvideKey");
