import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import { useActivityInputStore } from "@/app/process/service/persistence/activity/ActivityInputEntityStore";
import { useActivityOutputStore } from "@/app/process/service/persistence/activity/ActivityOutputEntityStore";
import { useActivityTaskStore } from "@/app/process/service/persistence/activity/ActivityTaskEntityStore";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import { useFieldValueStore } from "@/app/process/service/persistence/fields/FieldValueStore";
import {
  ActivityDocument,
  type ActivityDto,
  type ActivityEto,
  DeleteActivityDocument,
  GetActivitiesDocument,
  GetActivityDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: ActivityEto) => ({ id: eto.id }) as ActivityDto,
  ),
  {
    queryName: "activity",
    listQuery: GetActivitiesDocument,
    singleQuery: GetActivityDocument,
    mutation: ActivityDocument,
    deleteMutation: DeleteActivityDocument,
  },
  {
    indexFields: ["mid"],
    onAfterRegister: (etos) => {
      useActivityInputStore().registerAllLoaded(
        etos.flatMap((eto) => eto.inputs ?? []),
      );
      useActivityOutputStore().registerAllLoaded(
        etos.flatMap((eto) => eto.outputs ?? []),
      );
      useActivityTaskStore().registerAllLoaded(
        etos.flatMap((eto) => eto.tasks ?? []),
      );
      useFieldValueStore().registerAllLoaded(
        etos.flatMap((eto) => eto.fields ?? []),
      );
    },
  },
);

export const useActivityStore = defineEntityStore("activity", entityProvider);
