import { usePersonStore } from "@/app/process/service/persistence/person/PersonStore";
import {
  FieldType,
  type FieldValueAddressV1,
  type FieldValueDto,
  type FieldValueEto,
} from "@/gql/types";

// custom type FieldValueDto | FieldValueEto
type FieldValue = FieldValueDto | FieldValueEto;

export function parseAddressValue(value: FieldValueDto | FieldValueEto) {
  const address = JSON.parse(value.value?.valueJson) as FieldValueAddressV1;
  const country = address?.country
    ? (address.country as string).toString() + "-"
    : "";
  return `${country}${address?.zipCode ?? ""} ${address?.province ?? ""} ${
    address?.city ?? ""
  } - ${address?.street ?? ""}`;
}

export const fieldTypeToMappingFunction = {
  [FieldType.Person]: (value: FieldValue) => value.value?.valueEntityId,
  [FieldType.String]: (value: FieldValue) => value.value?.valueString,
  [FieldType.Date]: (value: FieldValue) => value.value?.valueDate,
  [FieldType.Boolean]: (value: FieldValue) => value.value?.valueBoolean,
  [FieldType.Number]: (value: FieldValue) => value.value?.valueNumber,
  [FieldType.Url]: (value: FieldValue) => value.value?.valueString,
  [FieldType.Address]: (value: FieldValue) => parseAddressValue(value),
  [FieldType.Tag]: (value: FieldValue) => value.fieldKeyId,
  [FieldType.File]: (value: FieldValue) => value.value?.valueEntityId,
};

export function getFieldValue(
  fieldType: FieldType,
  fieldValue: FieldValueDto | FieldValueEto,
  booleanTrueValue: string,
  booleanFalseValue: string,
): string {
  const mappingFunction = fieldTypeToMappingFunction[fieldType];

  if (!mappingFunction) {
    console.error(`No mapping function for field type ${fieldType}`);
    return "";
  }
  const mappedValue = mappingFunction(fieldValue);

  if (FieldType.Boolean === fieldType && mappedValue !== undefined) {
    return mappedValue ? booleanTrueValue : booleanFalseValue;
  }

  if (FieldType.Person === fieldType && typeof mappedValue === "string") {
    const personStore = usePersonStore();
    const person = personStore.getById(mappedValue);
    return person?.name ?? "";
  }

  if (!mappedValue) {
    return "";
  }

  return mappedValue.toString();
}
