<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import LoadingIndicator from "@/app/base/loading/LoadingIndicator.vue";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import { useFileService } from "@/app/process/service/FileService";
import {
  type FieldKeyDto,
  type FieldValueDto,
  type FileEto,
} from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const fileStore = useFileService();

const pendingFile = ref<FileEto | undefined>();
const file = computed<File | undefined>(() => {
  if (pendingFile.value?.name) {
    // We need to create a dummy file in place to show the new file name in the file-input
    return new File([], pendingFile.value.name);
  }
  return fileStore.getById(props.fieldValue?.id ?? "");
});

onMounted(async () => {
  if (props.fieldValue?.id) {
    await fileStore.fetch(
      props.fieldValue.id,
      props.fieldValue.value?.valueEntityId,
    );
  }
});

function emitUpdate(value: string) {
  emit("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueEntityId: value,
    },
  });
}

async function downloadFile() {
  await fileStore.download(props.fieldValue?.value?.valueEntityId ?? "");
}

async function uploadFile(newFile: File | File[]) {
  if (newFile instanceof Array) {
    newFile = newFile[0];
  }

  const fileEto = await fileStore.upload(newFile);

  if (props.fieldValue?.value?.valueEntityId) {
    fileStore.removeById(props.fieldValue.value?.valueEntityId);
  }

  if (!fileEto) {
    return;
  }

  pendingFile.value = fileEto;
  emitUpdate(fileEto.id);
}
</script>

<template>
  <ReadonlyField v-if="props.readonly" :label="props.label">
    <div class="d-flex w-100 toastui-editor-contents">
      <a @click="downloadFile">
        <LoadingIndicator v-if="!file" />
        <span v-else class="file-link">{{ file.name }}</span>
      </a>
    </div>
  </ReadonlyField>
  <VFileInput
    v-else
    class="w-100"
    :modelValue="file ? [file] : []"
    :loading="fileStore.isLoading"
    accept="*"
    :label="t('processes.singleView.outputTypes.FILE')"
    variant="outlined"
    density="compact"
    :centerAffix="true"
    :clearable="false"
    :hideDetails="true"
    prependIcon=""
    prependInnerIcon="mdi-paperclip"
    @update:modelValue="(files) => uploadFile(files)"
  />
</template>

<style scoped>
.file-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.file-link:hover {
  color: darkblue;
}

.file-link:active {
  color: red;
}
</style>
