<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed, reactive, ref } from "vue";

import FieldKeyCreationOptions from "@/app/FieldKeyCreationOptions.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import {
  EntityType,
  type FieldKeyDto,
  FieldType,
  type FieldValueDto,
} from "@/gql/types";
/* eslint-disable */
// @ts-nocheck
const emits = defineEmits<(e: "onSaveSuccess") => void>();

const fieldService = useFieldService();

const options = ref<string[]>([]);
const newFieldKey = reactive<FieldKeyDto>({
  id: uuidv4(),
  name: null,
  colorHex: "000000",
  type: null,
  unique: false,
  entityTypes: null,
  options: [],
  prefix: null,
});

const isValid = computed(() => {
  return (
    newFieldKey.name &&
    newFieldKey.name.length > 0 &&
    newFieldKey.entityTypes &&
    newFieldKey.entityTypes.length > 0 &&
    newFieldKey.type &&
    newFieldKey.key &&
    newFieldKey.key.length > 0
  );
});

async function save() {
  let counter = 0;
  addOptions();
  fieldService.createOrUpdateFieldKey(newFieldKey, {
    onSuccess() {
      const keyOptions = newFieldKey.options || [];
      if (keyOptions.length === 0) {
        emits("onSaveSuccess");
        cleanup();
        return;
      }
      for (const option of keyOptions) {
        fieldService.createOrUpdateFieldValue(option, {
          noPrompt: true,
          async onSuccess() {
            counter++;
            if (counter === keyOptions.length) {
              emits("onSaveSuccess");
              cleanup();
            }
          },
        });
      }
    },
  });
}

function addOptions() {
  newFieldKey.options = options.value.map((option) => {
    return {
      id: uuidv4(),
      fieldKeyId: newFieldKey.id,
      entityId: newFieldKey.id,
      value: {
        valueString: option,
      },
    } as FieldValueDto;
  });
}

function cleanup() {
  newFieldKey.id = uuidv4();
  newFieldKey.options = [];
  newFieldKey.name = null;
  newFieldKey.colorHex = "000000";
  newFieldKey.type = null;
  newFieldKey.unique = false;
  newFieldKey.entityTypes = null;
  newFieldKey.key = null;
  newFieldKey.prefix = null;
}
</script>

<template>
  <VCard class="activity bg-grey-lighten-5 text-caeli6" variant="flat">
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      />
    </template>

    <div class="d-flex pb-4 justify-end align-baseline">
      <VBtn
        variant="tonal"
        density="compact"
        :color="isValid ? 'caeli5' : 'caeli3'"
        :disabled="!isValid"
        @click="save()"
      >
        <VIcon icon="mdi-content-save-edit" class="mr-2" />
        <p class="font-weight-bold">Save</p>
      </VBtn>
    </div>

    <div class="d-flex flex-column">
      <VTextField
        v-model="newFieldKey.name"
        label="Name"
        variant="outlined"
        density="compact"
      />

      <VTextField
        v-model="newFieldKey.key"
        label="Technical key name (xyz.abc)"
        variant="outlined"
        density="compact"
      />

      <VAutocomplete
        v-model="newFieldKey.type"
        :items="
          Object.entries(FieldType).map(([key, value]) => ({
            id: key,
            title: value,
          }))
        "
        label="Type"
        variant="outlined"
        density="compact"
      />

      <VAutocomplete
        v-model="newFieldKey.entityTypes"
        :items="
          Object.entries(EntityType).map(([key, value]) => ({
            id: key,
            title: value,
          }))
        "
        label="Entity Types"
        multiple
        variant="outlined"
        density="compact"
      />

      <VTextField
          v-model="newFieldKey.prefix"
          label="Prefix"
          hint="mdi- für Icons auch möglich"
          variant="outlined"
          density="compact"
      />

      <VCheckboxBtn
        v-model="newFieldKey.unique"
        class="mb-4 ml-n1"
        label="Unique"
        color="caeli5"
      />

      <div class="d-flex ga-4">
        <VColorPicker
          :modelValue="`${newFieldKey.colorHex ? `#${newFieldKey.colorHex}` : ''}`"
          label="Color"
          showSwatches
          hideInputs
          mode="hex"
          @update:modelValue="newFieldKey.colorHex = $event.slice(1)"
        />

        <div v-if="newFieldKey.type === FieldType.String" class="flex-grow-1">
          <FieldKeyCreationOptions v-model="options" />
        </div>
      </div>
    </div>
  </VCard>
</template>
