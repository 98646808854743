<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import AppDialog from "@/app/base/dialog/AppDialog.vue";
import { lowerFirstLetter } from "@/app/base/utils/string";

const props = defineProps<{
  target: string;
  soft?: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "cancel"): void;
}>();

const actionLabel = computed(() => {
  return props.soft ? t("action.remove") : t("action.delete");
});
</script>

<template>
  <AppDialog
    modelValue
    :title="actionLabel"
    :confirmMessage="actionLabel"
    destructive
    @update:modelValue="emit('cancel')"
    @confirm="emit('confirm')"
    @cancel="emit('cancel')"
    >{{
      t("action.actionPrompt", {
        target,
        action: lowerFirstLetter(actionLabel),
      })
    }}</AppDialog
  >
</template>

<style scoped></style>
