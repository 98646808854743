<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import AppDialog from "@/app/base/dialog/AppDialog.vue";
import TextDisplay from "@/app/base/form/TextDisplay.vue";
import PersonReadonlyField from "@/app/process/field/PersonReadonlyField.vue";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import TagsDisplay from "@/app/process/field/TagsDisplay.vue";
import { useMessageService } from "@/app/process/service/MessageService";
import EntityFieldsCard from "@/app/process/single/EntityFieldsCard.vue";
import { EntityType } from "@/gql/types";

const router = useRouter();

const props = defineProps<{
  messageId: string;
}>();

const { t } = useI18n();
const messageService = useMessageService();

const message = computed(() => messageService.getById(props.messageId));

onBeforeMount(() => {
  messageService.markRefetch(props.messageId);
});
</script>

<template>
  <AppDialog
    v-model="messageService.isDeleteMessageDialogOpen"
    :title="t('message.deleteMessage')"
    :confirmMessage="t('action.delete')"
    :cancelMessage="t('action.cancel')"
    destructive
    @confirm="
      () => {
        messageService.deleteAndGoToList(messageId);
        messageService.closeDeleteDialog();
      }
    "
    @cancel="messageService.closeDeleteDialog"
  >
    <p>
      {{
        t("message.deleteMessageText", {
          name: message?.title,
        })
      }}
    </p>
  </AppDialog>

  <VCard
    v-if="message"
    class="activity bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <div class="d-flex align-center justify-space-between">
      <VCardTitle>{{ t("message.message") }}</VCardTitle>
      <TagsDisplay :entityId="message.id" justify="end" />
    </div>
    <VCard class="pa-4 d-flex flex-column ga-4">
      <ReadonlyField
        :label="t('message.title')"
        :value="message?.title ?? ''"
        data-testid="message-view-title"
      />

      <TextDisplay
        :label="t('message.body')"
        :value="message?.body ?? ''"
        data-testid="message-view-body"
        markdown
      />

      <EntityFieldsCard
        :entityType="[EntityType.Message]"
        :entityId="props.messageId"
        readonly
      />
      <PersonReadonlyField
        v-if="message.authorId"
        :personId="message.authorId"
        label="Autor"
      />
    </VCard>
    <FloatingActionButton
      icon="mdi-pencil"
      first
      primary
      data-testid="message-edit"
      @click="
        () => {
          router.push({ name: 'messageEdit', params: { messageId } });
        }
      "
    />
    <FloatingActionButton
      icon="mdi-trash-can-outline"
      data-testid="message-delete"
      color="error"
      @click="messageService.openDeleteDialog"
    />
  </VCard>
</template>
