<script lang="ts" setup>
import { computed, ref } from "vue";

import { formStyles } from "@/app/base/form/formStyles";
import {
  type FieldKeyDto,
  type FieldValueDto,
  type ValueDto,
} from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue?: FieldValueDto;
  label?: string;
  options?: { id: string }[];
  itemTitle?: keyof ValueDto;
}>();

const emit = defineEmits<(e: "update", value: string) => void>();

const options = computed<string[] | { id: string; title?: string }[]>(() => {
  if (props.options) {
    return getOptionsById();
  } else if (props.fieldKey.options && props.itemTitle) {
    return getOptionsByFieldKey(props.itemTitle);
  } else {
    return [];
  }
});
const chosenOptionId = ref(props.fieldValue?.optionId);
const chosenOption = computed(() => {
  if (props.options) {
    return props.options.find((option) => option.id === chosenOptionId.value);
  }

  if (props.itemTitle) {
    return props.fieldKey.options?.find(
      (option) => option.id === chosenOptionId.value,
    )?.value?.[props.itemTitle];
  }

  return "?";
});

function update(value?: string | null) {
  if (!value || value.length === 0) {
    return;
  }
  chosenOptionId.value = value;
  emit("update", value);
}

function getOptionsByFieldKey(
  key: keyof ValueDto,
): { id: string; title?: string }[] {
  return (
    props.fieldKey.options
      ?.filter((option) => option.id !== chosenOptionId.value)
      ?.map((option) => ({
        id: option.id,
        title: option.value ? option.value[key] : "?",
      }))
      .sort((a, b) => a.title.localeCompare(b.title)) ?? []
  );
}

function getOptionsById(): string[] {
  return props.options?.map((option) => option.id) ?? [];
}
</script>

<template>
  <VAutocomplete
    :modelValue="chosenOption"
    v-bind="formStyles"
    :label="props.label"
    :items="options"
    itemTitle="title"
    itemValue="id"
    :hideSelected="true"
    @update:modelValue="update($event as unknown as string)"
  />
</template>
