<script lang="ts" setup>
import dayjs from "dayjs";
import { computed } from "vue";

const props = defineProps<{
  dateValue?: string;
  clearable?: boolean;
  label?: string;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: string): void;
  (e: "clear"): void;
}>();

const dateFormatted = computed(() => {
  return formatDate(props.dateValue);
});

function formatDate(dateString?: string) {
  if (!dateString) {
    return "";
  }
  return dayjs(dateString).format("YYYY-MM-DD");
}

function updateDate(dateString: string) {
  if (!dateString) {
    return;
  }
  emit("update", formatDate(dateString));
}
</script>

<template>
  <div class="d-flex w-100 ga-2">
    <VTextField
      type="date"
      variant="outlined"
      density="compact"
      hideDetails="auto"
      :label="props.label ?? ''"
      :modelValue="dateFormatted"
      :readonly="props.readonly"
      @blur="
        (event: Event) => updateDate((event.target as HTMLInputElement).value)
      "
    />
    <VBtn
      v-if="!props.readonly && props.clearable && props.dateValue"
      variant="plain"
      size="small"
      color="red-lighten-1"
      icon="mdi-delete-outline"
      data-testid="delete-field-button"
      @click="() => emit('clear')"
    />
  </div>
</template>

<style>
input[type="date"] {
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
</style>
