import { computed, reactive } from "vue";
import { type Router } from "vue-router";

import { ApiError } from "@/services/backend/core/ApiError";

let router: Router | undefined = undefined;

//router instance only works inside components, so we set it from the root component App.vue
export function setRouter(r: Router) {
  router = r;
}

export function useStoreCache<T extends { id: number }>(
  entityName: string,
  fetcher: (id: T["id"]) => Promise<T>,
  updater: (value: T) => Promise<void>,
  remover: (id: T["id"]) => Promise<void>,
) {
  type Id = T["id"];
  interface CacheEntry {
    refs: number;
    value?: T;
    requested: boolean;
  }

  const cache = reactive(new Map<Id, CacheEntry>()) as Map<Id, CacheEntry>;

  function getCache(id: Id): CacheEntry {
    let cacheEntry = cache.get(id);
    if (cacheEntry) {
      return cacheEntry;
    }
    cacheEntry = reactive({ refs: 0, requested: false });
    cache.set(id, cacheEntry);
    return cacheEntry;
  }

  async function handleError(e: Error, id: Id) {
    console.error(e);
    if (router && e instanceof ApiError) {
      const href = router.resolve({
        path: "/error",
        query: { error: e.status, entity: entityName, id },
      });
      await router.push(href);
    }
  }

  function fetch(id: Id) {
    const cacheEntry = getCache(id);
    cacheEntry.refs++;
    if (!cacheEntry.requested) {
      cacheEntry.requested = true;
      const promise = fetcher(id);
      promise
        .then((value) => {
          cacheEntry.value = value;
        })
        .catch((e) => handleError(e, id));
    }
    return computed(() => cacheEntry.value);
  }

  async function update(value: T) {
    await updater(value)
      .then(() => (getCache(value.id).value = value))
      .catch((e) => handleError(e, value.id));
  }

  async function modify(id: Id, f: (value: T) => T) {
    await fetcher(id)
      .then(async (value) => {
        const updatedValue = f(value);
        await updater(updatedValue);
        getCache(id).value = updatedValue;
      })
      .catch((e) => handleError(e, id));
  }

  async function remove(id: Id) {
    await remover(id)
      .then(() => cache.delete(id))
      .catch((e) => handleError(e, id));
  }

  return { fetch, update, modify, remove, cache };
}
