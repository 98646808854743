import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import { processActivityToDto } from "@/app/process/service/mapper/ProcessMapper";
import { useActivityStore } from "@/app/process/service/persistence/activity/ActivityEntityStore";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import { useFieldValueStore } from "@/app/process/service/persistence/fields/FieldValueStore";
import { useProcessOutputStore } from "@/app/process/service/persistence/process/ProcessOutputEntityStore";
import { useProcessTaskStore } from "@/app/process/service/persistence/process/ProcessTaskEntityStore";
import {
  DeleteProcessActivityDocument,
  GetProcessActivityDocument,
  GetProcessActivityListDocument,
  ProcessActivityDocument,
  type ProcessActivityDto,
  type ProcessActivityEto,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual<ProcessActivityEto, ProcessActivityDto>(
    processActivityToDto,
  ),
  {
    queryName: "processActivity",
    listQuery: GetProcessActivityListDocument,
    singleQuery: GetProcessActivityDocument,
    mutation: ProcessActivityDocument,
    deleteMutation: DeleteProcessActivityDocument,
  },
  {
    indexFields: ["processId"],
    onAfterRegister(etos) {
      useActivityStore().registerAllLoaded(
        etos.map((eto) => eto.activity) ?? [],
      );
      useProcessOutputStore().registerAllLoaded(
        etos.flatMap((eto) => eto.outputs ?? []),
      );
      useProcessTaskStore().registerAllLoaded(
        etos.flatMap((eto) => eto.tasks ?? []),
      );
      useFieldValueStore().registerAllLoaded(
        etos.flatMap((eto) => eto.fields ?? []),
      );
    },
    onBeforeUnregister(etos) {
      useProcessOutputStore().unregisterAllLoaded(
        etos.flatMap((eto) => eto.outputs ?? []).map((output) => output.id),
      );
      useProcessTaskStore().unregisterAllLoaded(
        etos.flatMap((eto) => eto.tasks ?? []).map((task) => task.id),
      );
    },
  },
);

export const useProcessActivityStore = defineEntityStore(
  "processActivity",
  entityProvider,
);
