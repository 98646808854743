<script setup lang="ts">
import { computed } from "vue";

import { translateEnum } from "@/app/base/utils/i18n";
import ProvidedOutputsDisplay from "@/app/process/output/ProvidedOutputsDisplay.vue";
import { type ProcessActivityEto } from "@/gql/types";

const props = defineProps<{
  processActivity: ProcessActivityEto;
  cachedLabels?: Record<string, string>;
  showDetails?: boolean;
}>();

const state = computed(() => props.processActivity.status.state);
</script>

<template>
  <div class="d-flex ga-4 justify-space-between align-center">
    <VChip density="compact" variant="tonal" style="font-size: 0.8rem"
      >{{
        cachedLabels?.[state] ?? translateEnum("processes.activityState", state)
      }}
    </VChip>
    <div v-if="showDetails">
      <ProvidedOutputsDisplay v-bind="processActivity.status" />
    </div>
  </div>
</template>
