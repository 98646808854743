<script lang="ts" setup>
import dayjs from "dayjs";
import { computed } from "vue";

const props = defineProps<{
  dateTimeValue?: string;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: string): void;
  (e: "clear"): void;
}>();

const dateTimeFormatted = computed(() => {
  if (!props.dateTimeValue) {
    return "";
  }
  return dayjs(props.dateTimeValue).format("YYYY-MM-DDTHH:mm");
});

function updateDateTime(dateString: string) {
  if (!dateString) {
    return;
  }
  emit("update", dayjs(dateString).format("YYYY-MM-DDTHH:mm:ssZ"));
}
</script>

<template>
  <div class="d-flex ga-2 w-100">
    <VTextField
      type="datetime-local"
      variant="outlined"
      density="compact"
      hideDetails="auto"
      :label="props.label || ''"
      :modelValue="dateTimeFormatted"
      :readonly="props.readonly"
      @blur="
        (event: Event) =>
          updateDateTime((event.target as HTMLInputElement).value)
      "
    />
    <VBtn
      v-if="!props.readonly && props.clearable && props.dateTimeValue"
      variant="plain"
      size="small"
      color="red-lighten-1"
      icon="mdi-delete-outline"
      data-testid="delete-field-button"
      @click="() => emit('clear')"
    />
  </div>
</template>

<style>
input[type="datetime-local"] {
  cursor: pointer;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
</style>
