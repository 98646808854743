<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import OutputTable from "@/app/process/output/OutputTable.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useProcessService } from "@/app/process/service/ProcessService";
import { type ActivityInputEto, type ProcessActivityEto } from "@/gql/types";

const { t } = useI18n();

const props = defineProps<{
  activityInputs: ActivityInputEto[];
  processActivity: ProcessActivityEto;
}>();

const activityService = useActivityService();
const processService = useProcessService();

const inputOutputs = computed(() =>
  props.activityInputs.flatMap(
    (input) => activityService.getOutput(input.outputId) ?? [],
  ),
);

const inboundProcessOutputs = computed(() =>
  processService.getInboundProcessOutputs(
    props.processActivity.processId,
    props.processActivity.activity.id,
  ),
);
</script>

<template>
  <VExpansionPanel elevation="0" class="rounded-0">
    <VExpansionPanelTitle class="text-caeli6 text-body-2 py-0">
      <VIcon icon="mdi-source-branch" size="small" class="mr-2" />
      {{ t("processes.input", 2) }}
    </VExpansionPanelTitle>
    <VExpansionPanelText>
      <OutputTable
        :contextKey="`inputs-${props.processActivity.id}`"
        :outputs="inputOutputs"
        :processOutputs="inboundProcessOutputs"
        showTemplate
      />
    </VExpansionPanelText>
  </VExpansionPanel>
</template>
