import { defineStore } from "pinia";
import { ref } from "vue";

const PREDEFINED_COLORS = [
  "#3f51b5",
  "#55bb00",
  "#e040fb",
  "#00bbcc",
  "#ccaa00",
  "#ee4444",
  "#7525ff",
  "#00b0ff",
  "#ff4081",
  "#43a047",
  "#ff8f00",
  "#2979ff",
  "#795548",
  "#00bb99",
  "#e65100",
  "#9e9d24",
  "#757575",
  "#607d8b",
];

const MAX_COLOR_SUM = 500;

export const useHexColorService = defineStore("HexColorService", () => {
  const nextColorIndex = ref(0);

  function provideHexColor() {
    if (nextColorIndex.value < PREDEFINED_COLORS.length) {
      const result = PREDEFINED_COLORS[nextColorIndex.value].slice(1);
      nextColorIndex.value++;
      return result;
    }
    return randomHexColor(MAX_COLOR_SUM);
  }

  function randomHexColor(maxColorSum?: number) {
    let r = -1;
    let g = -1;
    let b = -1;
    while (
      r < 0 ||
      (maxColorSum !== undefined && maxColorSum > 0 && r + g + b > maxColorSum)
    ) {
      r = randomPositiveInteger(256);
      g = randomPositiveInteger(256);
      b = randomPositiveInteger(256);
    }
    return `${hex(r, 2)}${hex(g, 2)}${hex(b, 2)}`;
  }

  function randomPositiveInteger(below: number) {
    return Math.floor(Math.random() * below);
  }

  function hex(integer: number, minLength = 1) {
    let result = integer.toString(16);
    while (result.length < minLength) {
      result = `0${result}`;
    }
    return result;
  }

  function updateNextColorIndex(hexString: string) {
    const baseColorIndex = PREDEFINED_COLORS.findIndex((color) => {
      return color.endsWith(hexString);
    });
    if (baseColorIndex >= 0 && baseColorIndex >= nextColorIndex.value) {
      nextColorIndex.value = baseColorIndex + 1;
    }
  }

  return {
    updateNextColorIndex,
    provideHexColor,
  };
});
