import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

import {
  DataTableFilterParam,
  type FilterParameters,
} from "@/app/process/service/DataTableFilterParam";
import { StorageKeys } from "@/config";
import { type ActivityDto, type EntityType } from "@/gql/types";

export enum UI_DIALOG {
  CREATE_TEMPLATE = "CREATE_TEMPLATE",
  DELETE_ACTIVITY = "DELETE_ACTIVITY",
  EDIT_ROOT_TEMPLATE = "EDIT_ROOT_TEMPLATE",
  START_ANY = "START_ANY",
  RENAME_TAG = "RENAME_TAG",
  EDIT_GENERIC_FIELDS = "EDIT_GENERIC_FIELDS",
  SELECT_INPUTS = "SELECT_INPUTS",
  UPGRADE_PROCESS = "UPGRADE_PROCESS",
  GENERATE_DOCUMENT = "GENERATE_DOCUMENT",
  DELETE_DOCUMENT = "DELETE_DOCUMENT",
}

export const useDialogService = defineStore("processUi", () => {
  const router = useRouter();

  const dialogOpen = reactive(new Map<UI_DIALOG, boolean>());

  const dialogActivityList = ref<ActivityDto[]>([]);
  const dialogTemplateName = ref<string>("");
  const dialogEntityId = ref<string>("");
  const dialogEntityType = ref<EntityType | null>(null);
  const activeProcessTab = useStorage<Record<string, string>>(
    StorageKeys.process.activeTab.key,
    {},
    // Force persistence only for active browser session
    StorageKeys.process.activeTab.storage,
    { deep: true },
  );

  const expandedProcesses = ref<Map<string, boolean>>(
    new Map<string, boolean>(),
  );

  const filterParameters = reactive(new Map<string, FilterParameters>());

  function openDialog(dialog: UI_DIALOG) {
    dialogOpen.set(dialog, true);
  }

  function closeDialog(dialog: UI_DIALOG) {
    dialogOpen.set(dialog, false);
  }

  function getFilterParameters(key: string): FilterParameters {
    const filter =
      filterParameters.get(key) ?? DataTableFilterParam.fromUrl(key, router);
    filterParameters.set(key, filter);
    return filter;
  }

  function updateFilterParameters(
    key: string,
    update: Partial<FilterParameters>,
  ) {
    const existing = getFilterParameters(key);
    filterParameters.set(key, DataTableFilterParam.merge(existing, update));
    updateUrlBasedOnFilterParameters(key);
  }

  function updateUrlBasedOnFilterParameters(key: string) {
    const filter = getFilterParameters(key);
    if (!filter || !router.currentRoute.value.name) {
      return;
    }
    router
      .replace({
        name: router.currentRoute.value.name,
        params: router.currentRoute.value.params,
        query: DataTableFilterParam.toQuery(key, filter),
      })
      .catch((reason: string) => {
        throw new Error(`Navigation error: ${reason}`);
      });
  }

  return {
    dialogTemplateName,
    dialogActivityList,
    dialogEntityId,
    dialogEntityType,
    activeProcessTab,
    collapse: (key: string) => expandedProcesses.value.set(key, false),
    expand: (key: string) => expandedProcesses.value.set(key, true),
    openDialog,
    closeDialog,
    isDialogOpen: (dialog: UI_DIALOG) => Boolean(dialogOpen.get(dialog)),
    openCreateTemplateDialog: () => {
      dialogTemplateName.value = "";
      openDialog(UI_DIALOG.CREATE_TEMPLATE);
    },
    openActivityDeleteDialog: (
      activities: ActivityDto[],
      processId?: string,
    ) => {
      dialogActivityList.value = activities;
      if (processId) {
        dialogEntityId.value = processId;
      }
      openDialog(UI_DIALOG.DELETE_ACTIVITY);
    },
    openGenerateDocumentDialog: (documentId: string, entityType: string) => {
      dialogEntityId.value = documentId;
      dialogEntityType.value = entityType as EntityType;
      openDialog(UI_DIALOG.GENERATE_DOCUMENT);
    },
    getFilterParameters,
    updateFilterParameters,
    filterParameters,
  };
});
