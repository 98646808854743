// Utility functions for arrays of items with IDs.
// Use these in container components that allow insert/update/delete actions on such arrays.

const ERROR_UNDEFINED_ARRAY = "Illegal state: Modification of undefined array";
const ERROR_ELEMENT_NOT_FOUND = "Illegal state: Element not found in array";
const ERROR_ELEMENT_ALREADY_EXISTS =
  "Illegal state: Element with same id already exists";

export function deduplicate<T>(array?: T[]): T[] {
  return Array.from(new Set(array));
}

export function union<T>(...arrays: T[][]): T[] {
  return deduplicate(([] as T[]).concat(...arrays));
}

export function mapBy<T>(
  array: T[],
  keyFunction: (element: T) => string
): Map<string, T[]> {
  const result = new Map<string, T[]>();
  array.forEach((element) => {
    const key = keyFunction(element);
    const entries = result.get(key) ?? [];
    entries.push(element);
    result.set(key, entries);
  });
  return result;
}

export function addToStartOfArray<T extends { id: K }, K>(
  array: T[] | undefined,
  ...newElements: T[]
): void {
  if (array) {
    newElements.forEach((e) => {
      const index = findIndex(array, e.id);
      if (index !== -1) {
        throw new Error(ERROR_ELEMENT_ALREADY_EXISTS);
      }
    });
    array.unshift(...newElements);
  } else {
    throw new Error(ERROR_UNDEFINED_ARRAY);
  }
}

export function addToEndOfArray<T extends { id: K }, K>(
  array: T[] | undefined,
  ...newElements: T[]
): void {
  if (array) {
    newElements.forEach((e) => {
      const index = findIndex(array, e.id);
      if (index !== -1) {
        throw new Error(ERROR_ELEMENT_ALREADY_EXISTS);
      }
    });
    array.push(...newElements);
  } else {
    throw new Error(ERROR_UNDEFINED_ARRAY);
  }
}

export function deleteElementsFromArray<T extends { id: K }, K>(
  array: T[] | undefined,
  ...elementsToDelete: T[]
): void {
  if (array) {
    deleteIdsFromArray(array, ...elementsToDelete.map((e) => e.id));
  } else {
    throw new Error(ERROR_UNDEFINED_ARRAY);
  }
}

export function deleteIdsFromArray<T extends { id: K }, K>(
  array: T[] | undefined,
  ...idsToDelete: K[]
): void {
  if (array) {
    idsToDelete.forEach((id) => {
      const index = findIndex(array, id);
      if (index === -1) {
        throw new Error(ERROR_ELEMENT_NOT_FOUND);
      }
      array.splice(index, 1);
    });
  } else {
    throw new Error(ERROR_UNDEFINED_ARRAY);
  }
}

export function findIndex<T extends { id: K }, K>(
  array: T[] | undefined,
  id: K,
): number {
  return array?.findIndex((element) => element.id === id) ?? -1;
}

export function findElement<T extends { id: K }, K>(
  array: T[] | undefined,
  id: K,
): T | undefined {
  const index = findIndex(array, id);
  return array && index !== -1 ? array[index] : undefined;
}

export function isEmpty<T extends { id: K }, K>(
  array: T[] | undefined,
): boolean {
  return (array?.length ?? 0) === 0;
}
