import { i18n } from "@/app/base/utils/i18n";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { t } = i18n.global;

// taken from https://www.destatis.de/Europa/DE/Staat/Laendercodes.html
const countries = [
  { title: t("country.AD"), value: "AD" },
  { title: t("country.AL"), value: "AL" },
  { title: t("country.AT"), value: "AT" },
  { title: t("country.BA"), value: "BA" },
  { title: t("country.BE"), value: "BE" },
  { title: t("country.BG"), value: "BG" },
  { title: t("country.BY"), value: "BY" },
  { title: t("country.CH"), value: "CH" },
  { title: t("country.CY"), value: "CY" },
  { title: t("country.CZ"), value: "CZ" },
  { title: t("country.DE"), value: "DE" },
  { title: t("country.DK"), value: "DK" },
  { title: t("country.EE"), value: "EE" },
  { title: t("country.ES"), value: "ES" },
  { title: t("country.FI"), value: "FI" },
  { title: t("country.FR"), value: "FR" },
  { title: t("country.GB"), value: "GB" },
  { title: t("country.GR"), value: "GR" },
  { title: t("country.HR"), value: "HR" },
  { title: t("country.HU"), value: "HU" },
  { title: t("country.IE"), value: "IE" },
  { title: t("country.IS"), value: "IS" },
  { title: t("country.IT"), value: "IT" },
  { title: t("country.LI"), value: "LI" },
  { title: t("country.LT"), value: "LT" },
  { title: t("country.LU"), value: "LU" },
  { title: t("country.LV"), value: "LV" },
  { title: t("country.MC"), value: "MC" },
  { title: t("country.MD"), value: "MD" },
  { title: t("country.ME"), value: "ME" },
  { title: t("country.MK"), value: "MK" },
  { title: t("country.MT"), value: "MT" },
  { title: t("country.NL"), value: "NL" },
  { title: t("country.NO"), value: "NO" },
  { title: t("country.PL"), value: "PL" },
  { title: t("country.PT"), value: "PT" },
  { title: t("country.RO"), value: "RO" },
  { title: t("country.RS"), value: "RS" },
  { title: t("country.RU"), value: "RU" },
  { title: t("country.SE"), value: "SE" },
  { title: t("country.SI"), value: "SI" },
  { title: t("country.SK"), value: "SK" },
  { title: t("country.SM"), value: "SM" },
  { title: t("country.TR"), value: "TR" },
  { title: t("country.UA"), value: "UA" },
  { title: t("country.VA"), value: "VA" },
];
export { countries };
