<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { Action, usePromptService } from "@/app/notification/PromptService";
import { useFileService } from "@/app/process/service/FileService";

const props = defineProps<{
  outputId?: string;
  initialFileId?: string;
  readonly?: boolean;
  noLabel?: boolean;
  hideDeleteButton?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<{
  (event: "deleted"): void;
  (event: "uploaded", fileId: string): void;
}>();

const fileService = useFileService();
const promptService = usePromptService();

const { isLoading: isFileUploading } = storeToRefs(fileService);

const fileEntity = computed(() => fileService.getById(props.outputId ?? ""));

const fileId = ref(props.initialFileId);

function uploadFile(file: File | File[]): void {
  if (file instanceof Array) {
    file = file[0];
  }

  fileService.upload(file).then(
    (fileEto) => {
      if (fileEto === undefined) {
        return;
      }
      fileId.value = fileEto.id;
      emits("uploaded", fileEto.id);
      fileService.add(props.outputId, fileEto);
      promptService.success(file.name, Action.UPLOAD);
    },
    (reason) => promptService.failure(file.name, Action.UPLOAD, reason),
  );
}
</script>

<template>
  <div class="d-flex ga-2 align-center w-100">
    <VFileInput
      class="w-100"
      :modelValue="fileEntity ? [fileEntity] : []"
      :disabled="fileEntity !== undefined || props.readonly"
      :loading="isFileUploading"
      accept="*"
      :label="t('processes.singleView.outputTypes.FILE')"
      variant="outlined"
      density="compact"
      :centerAffix="true"
      :clearable="false"
      hideDetails="auto"
      prependIcon=""
      prependInnerIcon="mdi-paperclip"
      @update:modelValue="(files) => uploadFile(files)"
    />
    <VBtn
      v-if="fileEntity"
      variant="text"
      size="small"
      class="ml-n12"
      icon="mdi-download-outline"
      @click.stop.prevent="fileService.download(fileId ?? '')"
    />
    <VBtn
      v-if="fileEntity && !props.hideDeleteButton"
      variant="plain"
      color="red"
      size="small"
      icon="mdi-delete-outline"
      @click.stop.prevent="
        () => {
          emits('deleted');
          fileService.removeById(outputId ?? '');
        }
      "
    />
  </div>
</template>
