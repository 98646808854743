import { type DocumentNode } from "@apollo/client/core";

import { type Eto } from "@/gql/types";
import { apolloClient } from "@/plugins/apollo";

export interface QueryParams {
  queryName: string;
  listQuery?: DocumentNode;
  singleQuery?: DocumentNode;
  mutation: DocumentNode;
  deleteMutation: DocumentNode;
}

export class ApolloCrudRepo<E extends Eto, D extends { id: string }> {
  constructor(private readonly params: QueryParams) {
    this.params = params;
  }
  async findAll() {
    if (!this.params.listQuery) {
      throw new Error("listQuery is not defined");
    }
    return apolloClient
      .query<Record<string, E[]>>({
        query: this.params.listQuery,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        return result.data[this.params.queryName];
      });
  }

  async findByIds(ids: string[]) {
    if (!this.params.singleQuery) {
      throw new Error("singleQuery is not defined");
    }
    return apolloClient
      .query<Record<string, E[]>>({
        query: this.params.singleQuery,
        variables: { filter: { ids } },
        fetchPolicy: "no-cache",
      })
      .then((result) => result.data[this.params.queryName]);
  }

  async deleteById(id: string) {
    return apolloClient
      .mutate({
        mutation: this.params.deleteMutation,
        variables: { id },
      })
      .then(() => {
        return true;
      });
  }

  async createOrUpdate(dto: D) {
    return apolloClient
      .mutate<Record<string, E>>({
        mutation: this.params.mutation,
        variables: { dto },
      })
      .then((result) => {
        if (!result.data) {
          throw new Error("No data returned on mutation");
        }
        return result.data[this.params.queryName];
      });
  }
}
