<script lang="ts" setup>
import { computed } from "vue";

import { useFieldService } from "@/app/process/service/FieldService";

const props = withDefaults(
  defineProps<{
    entityId: string;
    justify?: "start" | "center" | "end";
    density?: "default" | "compact" | "comfortable";
  }>(),
  {
    justify: undefined,
    density: "compact",
  },
);

const fieldService = useFieldService();

const fieldValues = computed(() => {
  return fieldService.getTagFieldValues(props.entityId);
});

const justifyClass = computed(() => {
  return props.justify ? `justify-${props.justify}` : undefined;
});
</script>

<template>
  <div
    v-if="fieldValues.length"
    class="d-flex flex-wrap ga-2"
    :class="justifyClass"
    data-testid="tags-display"
  >
    <VChip
      v-for="fieldValue in fieldValues"
      :key="fieldValue.id"
      :color="`#${fieldService.getFieldKey(fieldValue.fieldKeyId)?.colorHex}`"
      variant="tonal"
      :density="props.density"
    >
      {{ fieldService.getFieldKey(fieldValue.fieldKeyId)?.name }}
    </VChip>
  </div>
</template>
