<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import EditFieldItemEntry from "@/app/process/field/action/EditFieldItemEntry.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import { type EntityType, FieldType } from "@/gql/types";

const { t } = useI18n();

const props = defineProps<{
  entityId: string;
  fieldName: string;
  entityType: EntityType;
  items?: { name: string; id: string }[];
}>();
const emits = defineEmits<(e: "delete", id: string) => void>();

const fieldService = useFieldService();

const editFieldName = ref(props.fieldName);

function deleteItems() {
  for (const item of props.items ?? []) {
    fieldService.deleteFieldWithInstances(item.id).catch((reason) => {
      console.error(reason);
    });
  }
}

function addItem() {
  fieldService.createOrUpdateFieldKey({
    id: uuidv4(),
    name: `${props.fieldName}:`,
    type: FieldType.Tag,
    entityTypes: [props.entityType],
  });
}

function updateFieldName() {
  for (const item of props.items ?? []) {
    const value = item.name ?? "";
    fieldService.createOrUpdateFieldKey({
      id: item.id,
      name: `${editFieldName.value}:${value}`,
    });
  }
}
</script>

<template>
  <VCard
    elevation="1"
    class="pa-3 d-flex flex-wrap align-start border-card w-100"
  >
    <div class="d-flex align-center w-50">
      <div style="width: 80%">
        <VTextField
          v-model="editFieldName"
          density="compact"
          variant="outlined"
          :label="t('processes.nameLabel')"
          hideDetails="auto"
          @focusout="updateFieldName"
        />
      </div>
      <div style="width: 20%" class="d-flex align-start flex-column pl-1">
        <VBtn
          variant="plain"
          color="red-darken-2"
          icon="mdi-delete-outline"
          size="tiny"
          class="pa-1 text-caption"
          @click="deleteItems"
        />
      </div>
    </div>
    <div class="d-flex flex-column ga-1 w-50">
      <EditFieldItemEntry
        v-for="item in props.items"
        :id="item.id"
        :key="item.id"
        :entityId="props.entityId"
        :fieldName="props.fieldName"
        :name="item.name"
        @delete="(id) => emits('delete', id)"
      />
      <div style="width: 80%">
        <VBtn
          class="w-100"
          variant="text"
          color="caeli5"
          prependIcon="mdi-plus"
          size="small"
          @click="addItem"
        >
          {{ t("action.addSomething", { name: t("processes.item") }) }}
        </VBtn>
      </div>
    </div>
  </VCard>
</template>

<style scoped></style>
