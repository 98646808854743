import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import { type DocumentDto, type DocumentEto } from "@/gql/types";
import {
  DocumentDeleteDocument,
  DocumentDocument,
  GetDocumentDocument,
  GetDocumentListDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: DocumentEto) => ({ id: eto.id }) as DocumentDto,
  ),
  {
    queryName: "document",
    listQuery: GetDocumentListDocument,
    singleQuery: GetDocumentDocument,
    mutation: DocumentDocument,
    deleteMutation: DocumentDeleteDocument,
  },
);

export const useDocumentStore = defineEntityStore("document", entityProvider);
