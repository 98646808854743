<script setup lang="ts">
import { useFieldService } from "@/app/process/service/FieldService";
import { type FieldKeyEto } from "@/gql/types";

defineProps<{
  entityId: string;
  fieldKeys?: FieldKeyEto[];
}>();

const fieldService = useFieldService();
</script>

<template>
  <template v-for="fieldKey in fieldKeys ?? []" :key="fieldKey.id">
    <Button
      v-if="!fieldService.isUniqueKeyAlreadySet(fieldKey, entityId)"
      text
      :label="fieldKey.name"
      icon="mdi mdi-plus"
      size="small"
      data-testid="message-add-top-used-field"
      @click="
        () => {
          fieldService.addPendingFieldForEntity(fieldKey.id, entityId);
          fieldService.closeFieldDialog();
        }
      "
    />
  </template>
</template>
