<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import ActivityInputSelectionDialog from "@/app/process/input/ActivityInputSelectionDialog.vue";
import OutputTable from "@/app/process/output/OutputTable.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import {
  UI_DIALOG,
  useDialogService,
} from "@/app/process/service/DialogService";

const { t } = useI18n();

const props = defineProps<{ activityId: string; readonly?: boolean }>();

const activityService = useActivityService();
const dialogService = useDialogService();

const inputOutputs = computed(() =>
  activityService
    .getInputs(props.activityId, false)
    .flatMap((input) => activityService.getOutput(input.outputId) ?? []),
);
</script>

<template>
  <div v-if="inputOutputs.length || !props.readonly" class="mt-3 px-1 pb-3">
    <p class="text-h6 py-2">
      {{ t("processes.input", 2) }}
    </p>
    <ActivityInputSelectionDialog :activityId="props.activityId" />
    <VCard v-if="inputOutputs.length" class="pa-4 mb-3 border-card">
      <OutputTable
        :contextKey="`inputs-${props.activityId}`"
        :outputs="inputOutputs"
        showTemplate
      />
    </VCard>
    <VBtn
      v-if="!props.readonly"
      variant="outlined"
      color="caeli5"
      @click="() => dialogService.openDialog(UI_DIALOG.SELECT_INPUTS)"
      >{{ t("processes.selectInputs") }}
    </VBtn>
  </div>
</template>
