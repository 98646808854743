import "./assets/main.css";
import "./polyfills";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import "@vuepic/vue-datepicker/dist/main.css";

import { DefaultApolloClient } from "@vue/apollo-composable";
import Datepicker from "@vuepic/vue-datepicker";
import { createPinia } from "pinia";
import { default as PrimeVue } from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import DialogService from "primevue/dialogservice";
import FocusTrap from "primevue/focustrap";
import { createApp } from "vue";

import App from "@/App.vue";
import { i18n } from "@/app/base/utils/i18n";
import { apolloClient } from "@/plugins/apollo";
import { primeVueConfig } from "@/plugins/primevue";
import vuetify from "@/plugins/vuetify";
import { loadFonts } from "@/plugins/webfontloader";
import router from "@/router";
import {
  configureAppForUserAuth,
  getAccessTokenGenerator,
  UserAuthService,
} from "@/services/auth/UserAuthService";
import { OpenAPI } from "@/services/backend/core/OpenAPI";

loadFonts().catch((e: unknown) => {
  // Allow the fonts to load asynchronously, and log any errors to the console
  console.error(e);
});

const app = createApp(App);

app.provide(DefaultApolloClient, apolloClient);
app.component("Datepicker", Datepicker);
app.use(createPinia());
const appRouter = router();
app.use(appRouter);
app.use(vuetify);
app.use(PrimeVue, primeVueConfig);
app.use(DialogService);
app.use(ConfirmationService);
app.use(i18n);
app.directive("focustrap", FocusTrap);

OpenAPI.BASE = ""; // PiSys backend is reachable with relative urls
if (UserAuthService.isAuthEnabled()) {
  configureAppForUserAuth(appRouter);
  OpenAPI.TOKEN = getAccessTokenGenerator();
} else {
  console.log("Auth is disabled");
}

// Waiting for the router to be ready prevents race conditions when returning from auth redirects
await appRouter.isReady();
app.mount("#app");
