<script lang="ts" setup>
import dayjs from "dayjs";
import { computed } from "vue";

import OptionField from "@/app/process/field/OptionField.vue";
import ReadonlyField from "@/app/process/field/ReadonlyField.vue";
import { type FieldKeyEto, type FieldValueDto } from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const dateFormatted = computed(() => {
  return formatDate(props.fieldValue?.value?.valueDate ?? undefined);
});

function formatDate(dateString?: string | null) {
  if (!dateString) {
    return "";
  }
  return dayjs(dateString).format("YYYY-MM-DD");
}

// We need to wrap the options in a computed property to show the formatted date
const availableOptions = computed(() => {
  return props.fieldKey?.options?.map((option) => {
    return {
      id: option.id,
      title: formatDate(option.value?.valueDate),
    };
  });
});

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);

function emitUpdate(dateString: string) {
  emit("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueDate: dateString ? formatDate(dateString) : null,
    },
  });
}

function emitOptionUpdate(value: string) {
  emit("update", {
    ...props.fieldValue,
    optionId: value,
    value: {
      ...props.fieldValue.value,
      valueDate: null,
    },
  });
}
</script>

<template>
  <ReadonlyField
    v-if="props.readonly"
    :label="props.label"
    :value="dateFormatted"
  />
  <OptionField
    v-else-if="isOptionField"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :options="availableOptions"
    :label="props.label"
    @update="emitOptionUpdate"
  />
  <VTextField
    v-else
    type="date"
    variant="outlined"
    density="compact"
    :label="props.fieldKey.name ?? ''"
    :modelValue="dateFormatted"
    hideDetails="auto"
    data-testid="date-field-input"
    @blur="
      (event: Event) => emitUpdate((event.target as HTMLInputElement).value)
    "
  />
</template>

<style>
input[type="date"] {
  cursor: pointer;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
</style>
