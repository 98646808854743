<script lang="ts" setup>
import { computed, onMounted } from "vue";

import ProcessActivityTable from "@/app/process/list/ProcessActivityTable.vue";
import { usePersonService } from "@/app/process/service/PersonService";
import { useProcessService } from "@/app/process/service/ProcessService";

const processService = useProcessService();
const personService = usePersonService();

const CONTEXT_KEY = "taskView";

const isLoadingProcesses = computed(() => processService.isLoading());
const isLoadingUser = computed(() => personService.isLoading());

const loading = computed(() => isLoadingProcesses.value || isLoadingUser.value);

onMounted(() => {
  processService.getAllProcessActivities();
});
</script>

<template>
  <VCard
    class="bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
    :loading="loading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      />
    </template>
    <div v-if="!loading">
      <ProcessActivityTable
        :contextKey="CONTEXT_KEY"
        showProcess
        showDetails
        grabFocusOnKeydown
      />
    </div>
  </VCard>
</template>
