import {
  InteractionType,
  type IPublicClientApplication,
  type PopupRequest,
  type RedirectRequest,
} from "@azure/msal-browser";
import { type RouteLocationNormalized, type Router } from "vue-router";

import {
  baseLoginRequest,
  msalInstance,
} from "@/services/auth/msal/msalConfig";

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    if (to.meta.requiresAuth !== false) {
      const request = {
        ...baseLoginRequest,
        redirectStartPage: to.fullPath,
      };
      const shouldProceed = await isAuthenticated(
        msalInstance,
        InteractionType.Redirect,
        request,
      );
      return shouldProceed || "/failed";
    }

    return true;
  });
}

export async function isAuthenticated(
  instance: IPublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest,
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each
  // page load before determining if a user is signed in or not
  try {
    await instance.handleRedirectPromise();
  } catch {
    return false;
  }
  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    return true;
  }

  // User is not signed in and attempting to access protected route. Sign them in.
  return logInUser(instance, interactionType, loginRequest);
}

export async function logInUser(
  instance: IPublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest,
): Promise<boolean> {
  // User is not signed in and attempting to access protected route. Sign them in.
  if (interactionType === InteractionType.Popup) {
    try {
      await instance.loginPopup(loginRequest);
      return true;
    } catch {
      return false;
    }
  } else if (interactionType === InteractionType.Redirect) {
    try {
      await instance.loginRedirect(loginRequest);
      return true;
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
