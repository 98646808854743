<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import { deduplicate } from "@/app/base/utils/array";
import { useBlockedActionStore } from "@/app/common/store/BlockedActionStore";
import DeleteProcessTemplateDialog from "@/app/process/action/DeleteProcessTemplateDialog.vue";
import StartProcessDialogSpecificButton from "@/app/process/action/start/StartProcessDialogSpecificButton.vue";
import { EditorMode } from "@/app/process/graphviewer/EditorMode";
import FloatingShowGraphButton from "@/app/process/graphviewer/FloatingShowGraphButton.vue";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { createFieldCells } from "@/app/process/list/FilteredDataTableUtil";
import ProcessTemplateActivityActions from "@/app/process/list/ProcessTemplateActivityActions.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useFieldService } from "@/app/process/service/FieldService";
import { type ActivityEto, EntityType } from "@/gql/types";

const props = defineProps<{
  rootActivityId: string;
}>();

const { t } = useI18n();

const i18n = {
  startActivity: t("processes.startActivity"),
  processActivityColumnTitle: t("processes.activity"),
};

const router = useRouter();
const activityService = useActivityService();
const blockerStore = useBlockedActionStore();
const fieldService = useFieldService();

const rootActivity = computed(() =>
  activityService.getActivity(props.rootActivityId),
);

const current = computed(() => {
  const mid = activityService.getMid(props.rootActivityId);
  return mid ? activityService.getCurrentVersion(mid) : undefined;
});

const graph = computed(
  (): ActivityEto[] => activityService.getGraph(props.rootActivityId) ?? [],
);

const displayColumns = computed(() => [
  i18n.processActivityColumnTitle,
  ...deduplicate(
    [...fieldService.getNonTagFieldKeys(EntityType.Activity)].map(
      (fieldKey) => fieldKey.name ?? "",
    ),
  ),
]);

const rowItems = computed(() => {
  return (
    graph.value?.flatMap((activity) => {
      if (activity.archived) {
        return [];
      }
      return {
        key: activity.id,
        class: {
          released: activityService.isReleased(activity.id),
          "root-activity": activity.id === props.rootActivityId,
        },
        cells: {
          ...createActivityCell(activity),
          ...createFieldCells(activity.id),
          ...createActionsCell(activity),
        },
        tags: fieldService.getActiveTagNames(activity.id),
      };
    }) ?? []
  );
});

function createActivityCell(activity: ActivityEto) {
  const isRoot = activity.id === props.rootActivityId;
  return {
    [i18n.processActivityColumnTitle]: {
      content: isRoot
        ? `${i18n.startActivity}: ${activity.name}`
        : (activity.name ?? ""),
      props: {
        class: isRoot ? "pointer text-caeli5" : "pointer",
      },
      events: {
        click: () =>
          router.push({
            name: "activity",
            params: { activityId: activity.id },
          }),
      },
    },
  };
}

function createActionsCell(activity: ActivityEto) {
  const isRoot = activity.id === props.rootActivityId;
  return {
    [""]: {
      component: ProcessTemplateActivityActions,
      props: {
        activityId: activity.id,
        isRoot,
        class: "justify-end",
      },
    },
  };
}

function revise() {
  activityService.reviseProcessTemplate(current.value?.id ?? "undefined").then(
    () => {
      router
        .push({
          name: "processTemplate",
          params: {
            rootActivityId: current.value?.id ?? "undefined",
          },
        })
        .catch((error) => console.error(error));
    },
    (error) => console.error(error),
  );
}

const rootActivityBlocked = computed(() =>
  blockerStore.isBlocked(props.rootActivityId),
);
</script>

<template>
  <VCard
    v-if="current !== undefined"
    class="bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      />
    </template>
    <DeleteProcessTemplateDialog
      @beforeDelete="
        (ids) => {
          if (ids.includes(props.rootActivityId)) {
            router.push({ name: 'processTemplateList' });
          }
        }
      "
    />
    <FloatingShowGraphButton
      :activities="graph"
      :editorMode="EditorMode.ACTIVITY"
      :rootActivityId="props.rootActivityId"
      primary
    />
    <VTooltip
      v-if="activityService.isReleased(current.id)"
      location="start"
      :text="t('action.revise')"
    >
      <template #activator="{ props: activator }">
        <FloatingActionButton
          v-bind="activator"
          :loading="rootActivityBlocked"
          icon="mdi-pencil"
          style="bottom: 9.5rem"
          data-testid="revise-draft-button"
          @click="revise"
        />
      </template>
    </VTooltip>
    <StartProcessDialogSpecificButton
      v-if="activityService.isReleased(props.rootActivityId)"
      :rootActivityId="props.rootActivityId"
      floating
    />
    <VTooltip
      v-if="!activityService.isReleased(props.rootActivityId)"
      location="start"
      :text="t('action.release')"
    >
      <template #activator="{ props: activator }">
        <FloatingActionButton
          v-bind="activator"
          :loading="rootActivityBlocked"
          icon="mdi-check"
          data-testid="release-draft-button"
          @click="activityService.releaseProcessTemplate(props.rootActivityId)"
        />
      </template>
    </VTooltip>

    <div class="mx-8">
      <div class="d-flex flex-column align-center py-3">
        <p class="text-subtitle-2">{{ t("processes.processTemplate") }}</p>
        <div class="d-flex flex-wrap ga-3 align-center">
          <p class="text-h5 text-caeli6">
            {{ rootActivity?.name ?? "" }}
          </p>
          <VChip
            v-if="activityService.isReleased(props.rootActivityId)"
            variant="flat"
            density="compact"
            color="caeli5"
            data-testid="released-chip"
          >
            {{ t("processes.released") }}
          </VChip>
          <VBtn
            v-if="
              activityService.isReleased(props.rootActivityId) &&
              !current.released
            "
            prependIcon="mdi-pencil"
            variant="outlined"
            density="compact"
            color="caeli6"
            @click="
              () =>
                router.push({
                  name: 'processTemplate',
                  params: { rootActivityId: current?.id ?? 'undefined' },
                })
            "
            >{{ t("processes.draft") }}
          </VBtn>
        </div>
      </div>
      <div class="w-100">
        <FilteredDataTable
          :contextKey="`graph-${props.rootActivityId}`"
          :rowItems="rowItems"
          :availableTags="fieldService.getTagFieldKeys(EntityType.Activity)"
          :mandatoryColumns="[i18n.processActivityColumnTitle, '']"
          :exposedColumns="displayColumns"
        />
      </div>
    </div>
  </VCard>
</template>
