import { provideApolloClient } from "@vue/apollo-composable";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useBlockedActionStore } from "@/app/common/store/BlockedActionStore";
import { Action, usePromptService } from "@/app/notification/PromptService";
import { useFieldService } from "@/app/process/service/FieldService";
import {
  activityInputToDto,
  activityOutputToDto,
  activityTaskToDto,
  activityToDto,
} from "@/app/process/service/mapper/ActivityMapper";
import { useActivityStore } from "@/app/process/service/persistence/activity/ActivityEntityStore";
import { useActivityInputStore } from "@/app/process/service/persistence/activity/ActivityInputEntityStore";
import { useActivityOutputStore } from "@/app/process/service/persistence/activity/ActivityOutputEntityStore";
import { useActivityTaskStore } from "@/app/process/service/persistence/activity/ActivityTaskEntityStore";
import { defineVersionStore } from "@/app/process/service/persistence/VersionStore";
import {
  compareByName,
  PROCESS_START_DATE_OUTPUT_NAME,
} from "@/app/process/utils";
import {
  type ActivityDto,
  type ActivityEto,
  type ActivityInputDto,
  type ActivityOutputDto,
  type ActivityOutputEto,
  ActivityOutputType,
  type ActivityTaskDto,
  GetActivitiesDocument,
  GetActivityRootsDocument,
  useActivityRootReleaseMutation,
  useActivityRootRevisionMutation,
  useGetActivityRootsQuery,
} from "@/gql/types";
import { apolloClient } from "@/plugins/apollo";

export interface ActivityTreeNode {
  activity: ActivityEto;
  parentPaths: string[][];
  children: ActivityTreeNode[];
}

export const useActivityService = defineStore("ActivityService", () => {
  const activityStore = useActivityStore();
  const activityOutputStore = useActivityOutputStore();
  const activityTaskStore = useActivityTaskStore();
  const activityInputStore = useActivityInputStore();

  const { t } = useI18n();

  const activityVersionStore = defineVersionStore(
    "activityVersions",
    activityStore,
  )();
  const activityOutputVersionStore = defineVersionStore(
    "activityOutputVersions",
    activityOutputStore,
  )();
  const activityTaskVersionStore = defineVersionStore(
    "activityTaskVersions",
    activityTaskStore,
  )();
  const activityInputVersionStore = defineVersionStore(
    "activityInputVersions",
    activityInputStore,
  )();

  const blockerStore = useBlockedActionStore();
  const promptService = usePromptService();
  const fieldService = useFieldService();

  provideApolloClient(apolloClient);

  const { onResult: onRootsResult, loading: rootsLoading } =
    useGetActivityRootsQuery();

  onRootsResult((result) => {
    if (!rootsLoading.value) {
      activityStore.registerAllLoaded(result.data?.activityRoots ?? []);
    }
  });

  function getActivity(id: string): ActivityEto | undefined {
    return activityStore.getById(id);
  }

  function createOrUpdateActivity(dto: ActivityDto, silentSuccess = false) {
    const existingEto = activityStore.getById(dto.id);
    const existingDto = existingEto ? activityToDto(existingEto) : undefined;
    const name =
      dto.name ??
      existingDto?.name ??
      t("common.somethingNew", { name: t("processes.activity") });
    return activityStore
      .createOrUpdate({
        ...existingDto,
        ...dto,
        name,
      })
      .then(
        () => {
          if (!silentSuccess) {
            promptService.success(dto.id, Action.SAVE, name);
          }
          return dto.id;
        },
        (reason) => {
          promptService.failure(dto.id, Action.SAVE, reason);
        },
      );
  }

  async function extendActivity(activityId: string, onAfter?: () => void) {
    const outputIds = getOutputIds(activityId);
    if (outputIds.length === 0) {
      throw new Error("Cannot extend activity without outputs.");
    }
    const newActivityId = uuidv4() as string;
    return createOrUpdateActivity({
      id: newActivityId,
    }).then(() => {
      const newActivity = activityStore.getById(newActivityId);
      if (!newActivity) {
        throw new Error("Failed to create new activity.");
      }
      outputIds.forEach((outputId) => {
        createOrUpdateInput(
          {
            id: uuidv4(),
            activityId: newActivityId,
            outputId,
          },
          true,
        );
      });
      if (onAfter) {
        onAfter();
      }
      return newActivityId;
    });
  }

  async function createNewProcessTemplate(
    name: string,
    description = "",
    goal = "",
  ) {
    return createOrUpdateActivity({
      id: uuidv4(),
      name,
      description,
      goal,
    }).then((createdId) => {
      if (!createdId) {
        console.error("Failed to create new process template.");
        return "";
      }
      createOrUpdateOutput(
        {
          id: uuidv4(),
          name: PROCESS_START_DATE_OUTPUT_NAME,
          type: ActivityOutputType.Date,
        },
        createdId,
        true,
      ).catch((reason) => {
        promptService.failure(createdId, Action.SAVE, reason.message);
      });
      return createdId;
    });
  }

  function createOrUpdateOutput(
    dto: ActivityOutputDto,
    activityId: string,
    silentSuccess = false,
  ) {
    const existingEto = activityOutputStore.getById(dto.id);
    const existingDto = existingEto
      ? activityOutputToDto(existingEto)
      : undefined;
    const name =
      dto.name ??
      existingDto?.name ??
      t("common.somethingNew", {
        name: t("processes.output"),
      });

    const sortOrder =
      dto?.sortOrder ??
      existingDto?.sortOrder ??
      getMaxSortOrder(activityId) + 10;

    const important = dto?.important ?? false;

    return activityOutputStore
      .createOrUpdate({
        ...existingDto,
        ...dto,
        activityId,
        sortOrder,
        name,
        important,
      })
      .then(
        () => {
          if (!silentSuccess) {
            promptService.success(dto.id, Action.SAVE, name);
          }
          return dto.id;
        },
        (reason) => {
          promptService.failure(dto.id, Action.SAVE, reason);
        },
      );
  }

  function createOrUpdateInput(dto: ActivityInputDto, silentSuccess = false) {
    const existingEto = activityInputStore.getById(dto.id);
    const existingDto = existingEto
      ? activityInputToDto(existingEto)
      : undefined;
    activityInputStore.createOrUpdate({ ...existingDto, ...dto }).then(
      () => {
        if (!silentSuccess) {
          promptService.success(
            dto.id,
            Action.SAVE,
            activityOutputStore.getById(dto.outputId)?.name ?? "",
          );
        }
        return dto.id;
      },
      (reason) => {
        promptService.failure(dto.id, Action.SAVE, reason.message);
      },
    );
    return dto.id;
  }

  function createOrUpdateTask(
    dto: ActivityTaskDto,
    activityId: string,
    silentSuccess = false,
  ) {
    const existingEto = activityTaskStore.getById(dto.id);
    const existingDto = existingEto
      ? activityTaskToDto(existingEto)
      : undefined;
    const title =
      dto.title ??
      existingDto?.title ??
      t("common.somethingNew", {
        name: t("processes.task"),
      });

    const sortOrder =
      dto?.sortOrder ??
      existingDto?.sortOrder ??
      getMaxSortOrder(activityId) + 10;

    return activityTaskStore
      .createOrUpdate({ ...existingDto, ...dto, title, activityId, sortOrder })
      .then(
        () => {
          if (!silentSuccess) {
            promptService.success(dto.id, Action.SAVE, title);
          }
          return dto.id;
        },
        (reason) => {
          promptService.failure(dto.id, Action.SAVE, reason.message);
        },
      );
  }

  function deleteActivity(id: string) {
    return activityStore.deleteById(id).then(
      () => {
        promptService.success(id, Action.DELETE);
        return true;
      },
      (reason) => {
        promptService.failure(id, Action.DELETE, reason.message);
      },
    );
  }

  function getGraph(rootActivityId: string) {
    return linearizedActivityGraphs.value.find(
      (activityChain) => activityChain[0].id === rootActivityId,
    );
  }

  async function releaseProcessTemplate(rootActivityId: string) {
    blockerStore.block(rootActivityId);
    if (activityVersionStore.isReleased(rootActivityId)) {
      throw new Error("Cannot release already released process template");
    }
    const draftActivityIds =
      getGraph(rootActivityId)?.map((activity) => activity.id) ?? [];
    const { mutate: releaseRootMutation } = useActivityRootReleaseMutation();
    await releaseRootMutation({ id: rootActivityId })
      .then((result) => {
        blockerStore.unblock(rootActivityId);
        const releasedActivities = result?.data?.activityRootRelease;
        if (releasedActivities === undefined) {
          return;
        }
        activityStore.unregisterAllLoaded(draftActivityIds);
        activityStore.registerAllLoaded(releasedActivities);
        const releasedRoot = activityStore.getById(rootActivityId);
        promptService.success(
          rootActivityId,
          Action.RELEASE,
          releasedRoot?.name ?? rootActivityId,
        );
      })
      .catch((reason) => {
        blockerStore.unblock(rootActivityId);
        promptService.failure(rootActivityId, Action.RELEASE, reason);
      });
  }

  async function reviseProcessTemplate(rootActivityId: string) {
    blockerStore.block(rootActivityId);
    if (!activityVersionStore.isReleased(rootActivityId)) {
      throw new Error("Cannot revise unreleased process template");
    }
    const { mutate: reviseRootMutation } = useActivityRootRevisionMutation();
    return await reviseRootMutation({ id: rootActivityId })
      .then((result) => {
        blockerStore.unblock(rootActivityId);
        const revisedActivities = result?.data?.activityRootRevision;
        if (revisedActivities === undefined) {
          return;
        }
        activityStore.registerAllLoaded(revisedActivities);
        const revisedRoot = activityStore.getById(rootActivityId);
        promptService.success(
          rootActivityId,
          Action.REVISION,
          revisedRoot?.name ?? rootActivityId,
        );
      })
      .catch((reason) => {
        blockerStore.unblock(rootActivityId);
        promptService.failure(rootActivityId, Action.REVISION, reason);
      });
  }

  const rootActivities = computed(() => {
    const result: ActivityEto[] = [];
    for (const activity of activityStore.getAll()) {
      if (
        !activity.custom &&
        activityInputStore.getFiltered("activityId", activity.id).length === 0
      ) {
        result.push(activity);
      }
    }
    return result.sort((a, b) => {
      return a.name?.localeCompare(b.name ?? "") ?? 0;
    });
  });

  const unreleasedRootActivities = computed(() =>
    rootActivities.value.filter(
      (rootActivity) => !activityVersionStore.isReleased(rootActivity.id),
    ),
  );

  const lastReleasedRootActivities = computed(() =>
    rootActivities.value.filter(
      (rootActivity) =>
        activityVersionStore.getLastReleasedVersion(rootActivity.id)?.id ===
        rootActivity.id,
    ),
  );

  const activityTrees = computed(
    (): Record<string, Record<string, ActivityTreeNode>> => {
      const result: Record<string, Record<string, ActivityTreeNode>> = {};
      rootActivities.value.forEach((rootActivity) => {
        const nodeLookup = {};
        const rootNode: ActivityTreeNode = {
          activity: rootActivity,
          parentPaths: [],
          children: [],
        };
        recursivelyAddChildNodes(rootNode, nodeLookup);
        result[rootActivity.id] = nodeLookup;
      });
      return result;
    },
  );

  function recursivelyAddChildNodes(
    parentNode: ActivityTreeNode,
    nodeLookup: Record<string, ActivityTreeNode>,
  ) {
    const childActivities = getChildActivities(parentNode.activity.id);
    childActivities.forEach((childActivity) => {
      let node = nodeLookup[childActivity.id];
      const nodeExisted = node !== undefined;
      if (!nodeExisted) {
        node = {
          activity: childActivity,
          parentPaths: [],
          children: [],
        };
        nodeLookup[childActivity.id] = node;
      }
      node.parentPaths.push(
        ...(parentNode.parentPaths.length
          ? parentNode.parentPaths.map((parentPath) => [
              ...parentPath,
              parentNode.activity.id,
            ])
          : [[parentNode.activity.id]]),
      );
      parentNode.children.push(node);
      if (!nodeExisted) {
        recursivelyAddChildNodes(node, nodeLookup);
      }
    });
  }

  function calculateNodeDepths(rootActivity: ActivityEto) {
    const nodesByDepth: Record<number, ActivityTreeNode[]> = {};
    let maxDepth = 0;
    Object.values(activityTrees.value[rootActivity.id]).forEach((node) => {
      const parentPathLengths = node.parentPaths.map((path) => path.length);
      const nodeDepth = Math.max(...parentPathLengths);
      nodesByDepth[nodeDepth] = [...(nodesByDepth[nodeDepth] ?? []), node];
      maxDepth = nodeDepth > maxDepth ? nodeDepth : maxDepth;
    });
    return { maxDepth, nodesByDepth };
  }

  function getChildActivitiesSortedByDepthAndName(rootActivity: ActivityEto) {
    const { maxDepth, nodesByDepth } = calculateNodeDepths(rootActivity);
    const activityList: ActivityEto[] = [];
    for (let depth = 0; depth <= maxDepth; depth++) {
      const nodes = (nodesByDepth[depth] ?? [])
        .map((node) => node.activity)
        .sort(compareByName);
      activityList.push(...nodes);
    }
    return activityList;
  }

  const linearizedActivityGraphs = computed(() => {
    const result: ActivityEto[][] = [];
    rootActivities.value.forEach((rootActivity) => {
      const activityList = getChildActivitiesSortedByDepthAndName(rootActivity);
      result.push([rootActivity, ...activityList]);
    });
    return result;
  });

  function getDescendantActivities(
    activityId: string,
    inclRoot = false,
  ): ActivityEto[] {
    const childActivities = getChildActivities(activityId);
    const results: ActivityEto[] = [];
    const rootActivity = activityStore.getById(activityId);
    if (inclRoot && rootActivity) {
      results.push(rootActivity);
    }
    const visitedIds = new Set(activityId);
    childActivities.forEach((childActivity) =>
      recursivelyFindActivities(results, childActivity.id, visitedIds),
    );
    return results;
  }

  function getChildActivities(activityId: string) {
    const resultIds = new Set<string>();
    const outputs = activityOutputStore.getFiltered("activityId", activityId);
    outputs.forEach((output) => {
      const inputs = activityInputStore.getFiltered("outputId", output.id);
      inputs.forEach((input) => {
        resultIds.add(input.activityId);
      });
    });
    return [...resultIds].flatMap(
      (childActivityId) => activityStore.getById(childActivityId) ?? [],
    );
  }

  function recursivelyFindActivities(
    activityList: ActivityDto[],
    currentId: string,
    visitedIds: Set<string>,
  ) {
    if (visitedIds.has(currentId)) {
      return;
    }
    const currentActivity = activityStore.getById(currentId);
    if (!currentActivity) {
      return;
    }
    activityList.push(currentActivity);
    visitedIds.add(currentId);
    getChildActivities(currentId).forEach((childActivity) =>
      recursivelyFindActivities(activityList, childActivity.id, visitedIds),
    );
  }

  function getMaxSortOrder(activityId: string): number {
    const existingTasksSortOrders = activityTaskStore
      .getFiltered("activityId", activityId)
      .map((task) => task.sortOrder ?? 0);
    const existingOutputSorOrders = activityOutputStore
      .getFiltered("activityId", activityId)
      .map((output) => output.sortOrder ?? 0);
    return Math.max(0, ...existingTasksSortOrders, ...existingOutputSorOrders);
  }

  function getOutputIds(activityId: string, inclArchived = false) {
    return getOutputs(activityId, inclArchived).map((output) => output.id);
  }

  function getOutputActivity(outputId: string): ActivityEto | undefined {
    const output = activityOutputStore.getById(outputId);
    return output ? activityStore.getById(output.activityId) : undefined;
  }

  function isRoot(activityId: string): boolean {
    return rootActivities.value.some((root) => root.id === activityId);
  }

  function getRootActivity(activityId: string) {
    const activityGraph = linearizedActivityGraphs.value.find((graph) =>
      graph.some((activity) => activity.id === activityId),
    );
    return activityGraph?.at(0);
  }

  function getAllActivities() {
    return activityStore.getAll();
  }

  function getAllActivitiesWithoutArchived() {
    return activityStore.getAll().filter((activity) => !activity.archived);
  }

  function getOutputs(activityId: string, inclArchived = true) {
    const activityOutputs = activityOutputStore.getFiltered(
      "activityId",
      activityId,
    );
    return inclArchived
      ? activityOutputs
      : activityOutputs.filter((output) => !output.archived);
  }

  function getInputs(activityId: string, inclArchived = true) {
    const activityInputs = activityInputStore.getFiltered(
      "activityId",
      activityId,
    );
    return inclArchived
      ? activityInputs
      : activityInputs.filter((input) => !input.archived);
  }

  function getInboundOutputs(activityId: string, inclArchived = true) {
    return getInputs(activityId, inclArchived).flatMap(
      (input) => activityOutputStore.getById(input.outputId) ?? [],
    );
  }

  function getTasks(activityId: string, inclArchived = true) {
    const activityTasks = activityTaskStore.getFiltered(
      "activityId",
      activityId,
    );
    return inclArchived
      ? activityTasks
      : activityTasks.filter((task) => !task.archived);
  }

  async function refetchRootActivities() {
    const activities = await apolloClient.query<Record<string, ActivityEto[]>>({
      query: GetActivitiesDocument,
      fetchPolicy: "no-cache",
    });

    const activityRoots = await apolloClient.query<
      Record<string, ActivityEto[]>
    >({
      query: GetActivityRootsDocument,
      fetchPolicy: "no-cache",
    });

    activityStore.registerAllLoaded([
      ...(activities.data.activity ?? []),
      ...(activityRoots.data.activityRoots ?? []),
    ]);
  }

  return {
    createOrUpdateActivity,
    extendActivity,
    createNewProcessTemplate,
    releaseProcessTemplate,
    reviseProcessTemplate,
    getRevisions: (mid: string) => activityVersionStore.getRevisions(mid),
    getActivity,
    isRoot,
    getAllActivities,
    getAllActivitiesWithoutArchived,
    getRootActivity,
    getMid: (id: string) => activityVersionStore.getMid(id),
    isReleased: (id: string) => activityVersionStore.isReleased(id),
    isArchived: (id: string) => activityVersionStore.isArchived(id),
    isCustom: (id: string) => activityStore.getById(id)?.custom ?? false,
    getCurrentVersion: (mid: string) => activityVersionStore.getMostRecent(mid),
    getLastReleased: (mid: string) => activityVersionStore.getLastReleased(mid),
    getLastReleasedVersion: (id: string) =>
      activityVersionStore.getLastReleasedVersion(id),
    getInputMid: (id: string) => activityInputVersionStore.getMid(id),
    getOutputMid: (id: string) => activityOutputVersionStore.getMid(id),
    getTaskMid: (id: string) => activityTaskVersionStore.getMid(id),
    getDescendantActivities,
    deleteActivity,
    createOrUpdateInput,
    removeInput: (inputId: string) => activityInputStore.deleteById(inputId),
    getInputs,
    getInputsByOutput: (outputId: string) =>
      activityInputStore.getFiltered("outputId", outputId),
    getInboundOutputs,
    createOrUpdateOutput,
    removeOutput: (outputId: string) =>
      activityOutputStore.deleteById(outputId),
    getOutputIds,
    getOutput: (id: string): ActivityOutputEto | undefined =>
      activityOutputStore.getById(id),
    getOutputs,
    getOutputActivity,
    createOrUpdateTask,
    removeTask: (taskId: string) => activityTaskStore.deleteById(taskId),
    getTask: (id: string) => activityTaskStore.getById(id),
    getTasks,
    deleteFieldWithInstances: (fieldKeyId: string) =>
      fieldService.deleteFieldWithInstances(fieldKeyId),
    unreleasedRootActivities,
    lastReleasedRootActivities,
    linearizedActivityGraphs,
    getGraph,
    isLoading: (id?: string) => activityStore.isLoading(id),
    refetchRootActivities,
  };
});
