import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import { processToDto } from "@/app/process/service/mapper/ProcessMapper";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import { useFieldValueStore } from "@/app/process/service/persistence/fields/FieldValueStore";
import { useProcessActivityStore } from "@/app/process/service/persistence/process/ProcessActivityEntityStore";
import {
  DeleteProcessDocument,
  GetProcessDocument,
  GetProcessesListDocument,
  ProcessDocument,
  type ProcessDto,
  type ProcessEto,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual<ProcessEto, ProcessDto>(processToDto),
  {
    queryName: "process",
    listQuery: GetProcessesListDocument,
    singleQuery: GetProcessDocument,
    mutation: ProcessDocument,
    deleteMutation: DeleteProcessDocument,
  },
  {
    onAfterRegister(etos) {
      useProcessActivityStore().registerAllLoaded(
        etos.flatMap((eto) => eto.activities ?? []),
      );
      useFieldValueStore().registerAllLoaded(
        etos.flatMap((eto) => eto.fields ?? []),
      );
    },
    onBeforeUnregister(etos) {
      useProcessActivityStore().unregisterAllLoaded(
        etos
          .flatMap((eto) => eto.activities ?? [])
          .map((processActivity) => processActivity.id),
      );
      useFieldValueStore().unregisterAllLoaded(
        etos.flatMap((eto) => eto.fields ?? []).map((field) => field.id),
      );
    },
  },
);

export const useProcessStore = defineEntityStore("process", entityProvider);
