<script lang="ts" setup>
import { computed } from "vue";

import { translateEnum } from "@/app/base/utils/i18n";
import {
  BG_CLASS_FAIL,
  BG_CLASS_INFO,
  BG_CLASS_SUCCESS,
  BG_CLASS_WARNING,
  Level,
  type Prompt,
  usePromptService,
  ValidationCode,
  type ValidationError,
} from "@/app/notification/PromptService";

const props = defineProps<{ prompt: Prompt }>();

const promptService = usePromptService();
const bgClass = computed(() => {
  switch (props.prompt.level) {
    case Level.Error:
      return BG_CLASS_FAIL;
    case Level.Warning:
      return BG_CLASS_WARNING;
    default:
      return BG_CLASS_INFO;
  }
});

function successPrompt(title: string, description: string): Prompt {
  return {
    ...props.prompt,
    icon: "mdi-check",
    title,
    description,
    customClass: BG_CLASS_SUCCESS,
  };
}

function failPrompt(title: string, description: string): Prompt {
  return {
    ...props.prompt,
    title,
    description,
    customClass: BG_CLASS_FAIL,
  };
}

const enrichedPrompt = computed((): Prompt => {
  if (props.prompt.action && props.prompt.key.endsWith("success")) {
    return successPrompt(
      translateEnum("action.notification.success.title", props.prompt.action),
      translateEnum(
        "action.notification.success.description",
        props.prompt.action,
        {
          name: props.prompt.title,
        },
      ),
    );
  }
  if (props.prompt.action && props.prompt.key.endsWith("fail")) {
    const reason =
      props.prompt.description ??
      validationErrorsToDescription(props.prompt.validationErrors);
    return failPrompt(
      translateEnum("action.notification.fail.title", props.prompt.action),
      translateEnum(
        "action.notification.fail.description",
        props.prompt.action,
        {
          reason,
        },
      ),
    );
  }
  return props.prompt;
});

function isValidationCode(message: string): boolean {
  return Object.values(ValidationCode).includes(message as ValidationCode);
}

function validationErrorsToDescription(
  validationErrors: ValidationError[] = [],
): string {
  return validationErrors
    .flatMap((error) => {
      // Pre-translated error messages that are not validation codes should not be displayed.
      if (!isValidationCode(error.message)) {
        return [];
      }
      const validationCode =
        ValidationCode[error.message as keyof typeof ValidationCode];
      return translateEnum("error.validation", validationCode, {
        path: error.path?.length ? error.path[0] : "",
      });
    })
    .join("\n\n");
}
</script>

<template>
  <VCard
    :class="enrichedPrompt.customClass ?? bgClass"
    class="pa-4 pr-2 border-card text-body-2"
    data-testid="prompt"
  >
    <div class="d-flex justify-space-between align-center">
      <VIcon
        v-if="enrichedPrompt.icon"
        :icon="enrichedPrompt.icon"
        class="pl-2 pr-4"
      />
      <div class="d-flex flex-column px-2 w-100">
        <div class="d-flex justify-space-between">
          <p
            v-if="enrichedPrompt.title"
            class="text-subtitle-2 font-weight-bold align-start"
            data-testid="prompt-title"
          >
            {{ enrichedPrompt.title }}
          </p>
          <VBtn
            variant="text"
            icon="mdi-close"
            size="tiny"
            data-testid="prompt-close"
            @click="promptService.deletePrompt(props.prompt.key)"
          />
        </div>
        <div class="prompt-description" data-testid="prompt-description">
          {{ enrichedPrompt.description ?? "" }}
        </div>
      </div>
    </div>
  </VCard>
</template>

<style scoped>
.prompt-description {
  width: 24rem;
}
</style>
