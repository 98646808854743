<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import DeleteDialog from "@/app/base/dialog/DeleteDialog.vue";
import TextDisplay from "@/app/base/form/TextDisplay.vue";
import TextEditor from "@/app/base/form/TextEditor.vue";
import TextField from "@/app/base/form/value/TextField.vue";
import ActivityOutputTypeSelect from "@/app/process/output/ActivityOutputTypeSelect.vue";
import { useActivityService } from "@/app/process/service/ActivityService";

const props = defineProps<{
  activityId: string;
  outputId: string;
  compact?: boolean;
  readonly?: boolean;
  customCaption?: string;
}>();

const { t } = useI18n();

const activityService = useActivityService();

const output = computed(() => activityService.getOutput(props.outputId));

const zIndex = computed(() => 1000 - (output.value?.sortOrder ?? 0));

const removeDialog = ref<boolean>(false);

const isOutputImportant = computed(() => {
  return output.value?.important ?? false;
});

function toggleImportant() {
  if (!output.value || props.readonly) {
    return;
  }
  activityService
    .createOrUpdateOutput(
      {
        id: output.value?.id,
        important: !isOutputImportant.value,
      },
      props.activityId,
    )
    .catch((reason) => {
      console.error(reason);
    });
}
</script>

<template>
  <VCard
    variant="elevated"
    class="border-card overflow-visible d-flex flex-row"
    :style="{ 'z-index': zIndex }"
  >
    <DeleteDialog
      v-model="removeDialog"
      :target="
        t('processes.output') +
        (output?.name ? ' ' + t('quoted', { expression: output?.name }) : '')
      "
      @confirm="
        () => {
          activityService.removeOutput(outputId);
          removeDialog = false;
        }
      "
      @cancel="() => (removeDialog = false)"
    />
    <div class="d-flex flex-column flex-grow-1">
      <div
        class="d-flex ga-3 pa-4"
        :class="props.compact ? undefined : 'flex-column'"
      >
        <span
          v-if="!props.compact"
          class="text-right ma-n2 mt-n3 text-caption text-blue-grey-lighten-3"
        >
          {{ props.customCaption ?? t("processes.output") }}
          <VTooltip location="start" :text="t('processes.outputFavorite')">
            <template #activator="{ props: activator }">
              <VIcon
                v-bind="activator"
                :icon="isOutputImportant ? 'mdi-star' : 'mdi-star-outline'"
                class="text-caeli5"
                @click="toggleImportant"
              />
            </template>
          </VTooltip>
        </span>
        <div v-if="props.compact" style="width: 20rem">
          <ActivityOutputTypeSelect
            :readonly="readonly"
            :outputId="props.outputId"
          />
        </div>
        <div
          v-if="!props.compact"
          class="d-flex ga-3 align-center justify-space-between"
          :style="props.compact ? { width: '32rem' } : undefined"
        >
          <ActivityOutputTypeSelect
            :class="{ 'mt-n4': readonly }"
            :readonly="readonly"
            :outputId="props.outputId"
          />
          <div v-if="!readonly" class="d-flex flex-column align-center">
            <VBtn
              icon="mdi-delete-outline"
              variant="plain"
              size="32"
              color="red-darken-2"
              @click="() => (removeDialog = true)"
            />
          </div>
        </div>
        <TextField
          v-if="!readonly"
          :label="t('processes.nameLabel')"
          :initialValue="output?.name"
          required
          variant="outlined"
          density="compact"
          hideDetails="auto"
          @update="
            (value: string) =>
              activityService.createOrUpdateOutput(
                { id: props.outputId, name: value },
                props.activityId,
              )
          "
        />
        <TextDisplay
          v-else
          :value="output?.name ?? ''"
          :label="t('processes.nameLabel')"
        />
        <VBtn
          v-if="props.compact && !readonly"
          icon="mdi-delete-outline"
          variant="plain"
          size="32"
          color="error"
          @click="() => (removeDialog = true)"
        />
        <TextEditor
          v-if="!readonly"
          :containerId="props.outputId"
          :previousContent="output?.description ?? ''"
          :label="t('processes.singleView.descriptionLabel')"
          @saveContent="
            (content: string) =>
              activityService.createOrUpdateOutput(
                {
                  id: props.outputId,
                  description: content,
                },
                props.activityId,
              )
          "
        />
        <TextDisplay
          v-else-if="output?.description"
          :label="t('processes.singleView.descriptionLabel')"
          :value="output?.description ?? ''"
          markdown
        />
        <TextField
          v-if="!readonly && !output?.key"
          :label="t('processes.techNameLabel')"
          variant="outlined"
          density="compact"
          hideDetails="auto"
          technicalKey
          @update="
            (value: string) =>
              activityService.createOrUpdateOutput(
                { id: props.outputId, key: value },
                props.activityId,
              )
          "
        />
        <TextDisplay
          v-else
          :value="output?.key ?? ''"
          :label="t('processes.techNameLabel')"
        />
      </div>
    </div>
    <VIcon
      v-if="!props.compact && !props.readonly"
      class="drag-handle-icon"
      icon="mdi-drag"
    />
  </VCard>
</template>

<style scoped>
.drag-handle-icon {
  align-self: center;
  margin-right: 8px;
  cursor: move;
}
</style>
