<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useBlockedActionStore } from "@/app/common/store/BlockedActionStore";
import StartProcessDialogSpecificButton from "@/app/process/action/start/StartProcessDialogSpecificButton.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useDialogService } from "@/app/process/service/DialogService";

const props = defineProps<{
  rootActivityMid: string;
}>();

const { t } = useI18n();

const activityService = useActivityService();
const blockerStore = useBlockedActionStore();
const dialogService = useDialogService();

const current = computed(() =>
  activityService.getCurrentVersion(props.rootActivityMid),
);

const lastReleased = computed(() =>
  activityService.getLastReleased(props.rootActivityMid),
);

const allActivities = computed(() =>
  current.value
    ? [
        current.value,
        ...activityService.getDescendantActivities(
          current.value.id ?? "undefined",
        ),
      ]
    : [],
);

const reviseBlocked = computed(() =>
  blockerStore.isBlocked(lastReleased.value?.id ?? ""),
);
</script>

<template>
  <div class="d-flex align-center ga-2 py-1">
    <VTooltip
      v-if="current && lastReleased && current.id === lastReleased.id"
      location="start"
      :text="t('action.revise')"
    >
      <template #activator="{ props: activator }">
        <VProgressCircular
          v-if="reviseBlocked"
          v-bind="activator"
          class="px-3"
          size="14"
          indeterminate
        />
        <VBtn
          v-else
          v-bind="activator"
          color="caeli5"
          variant="plain"
          size="tiny"
          icon="mdi-pencil"
          @click.stop.prevent="
            () =>
              activityService.reviseProcessTemplate(current?.id ?? 'undefined')
          "
        />
      </template>
    </VTooltip>
    <StartProcessDialogSpecificButton
      v-if="lastReleased"
      :rootActivityId="lastReleased.id"
    />
    <VTooltip location="start" :text="t('action.delete')">
      <template #activator="{ props: activator }">
        <VBtn
          v-bind="activator"
          variant="plain"
          color="error"
          size="tiny"
          icon="mdi-delete"
          @click.stop.prevent="
            () => dialogService.openActivityDeleteDialog(allActivities)
          "
        />
      </template>
    </VTooltip>
  </div>
</template>
