import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import {
  ActivityTaskDocument,
  type ActivityTaskDto,
  type ActivityTaskEto,
  DeleteActivityTaskDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: ActivityTaskEto) => ({ id: eto.id }) as ActivityTaskDto,
  ),
  {
    queryName: "activityTask",
    mutation: ActivityTaskDocument,
    deleteMutation: DeleteActivityTaskDocument,
  },
  {
    indexFields: ["mid", "activityId"],
  },
);

export const useActivityTaskStore = defineEntityStore(
  "activityTask",
  entityProvider,
);
