import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import { processTaskToDto } from "@/app/process/service/mapper/ProcessMapper";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import { type ProcessTaskDto, type ProcessTaskEto } from "@/gql/types";
import { DeleteProcessTaskDocument, ProcessTaskDocument } from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual<ProcessTaskEto, ProcessTaskDto>(
    processTaskToDto,
  ),
  {
    queryName: "processTask",
    mutation: ProcessTaskDocument,
    deleteMutation: DeleteProcessTaskDocument,
  },
  {
    indexFields: ["processActivityId", "activityTaskId"],
  },
);

export const useProcessTaskStore = defineEntityStore(
  "processTask",
  entityProvider,
);
