<script setup lang="ts">
import { computed } from "vue";

const MAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_REGEX = /^(?:\+49|0)[1-9]\d{1,4}[\s./-]?[0-9\s./-]{3,}$/;
const MIN_PHONE_NUMBER_LENGTH = 5;

enum ValueType {
  Email = "Email",
  Phone = "Phone",
  String = "String",
}

interface ValueData {
  type: ValueType;
  value: string;
}

const props = defineProps<{
  label?: string;
  value?: string;
}>();

function createValueData(value: string): ValueData | undefined {
  if (MAIL_REGEX.test(value)) {
    return { type: ValueType.Email, value };
  } else if (
    PHONE_REGEX.test(value) &&
    value.length >= MIN_PHONE_NUMBER_LENGTH
  ) {
    return { type: ValueType.Phone, value };
  } else {
    return { type: ValueType.String, value };
  }
}

const valueData = computed(() =>
  props.value ? createValueData(props.value) : undefined,
);
</script>

<template>
  <div>
    <p
      v-if="props.label"
      class="text-caption text-blue-grey-lighten-2"
      data-testid="field-label"
    >
      {{ props.label }}
    </p>
    <slot>
      <div class="value" data-testid="field-value">
        <a
          v-if="valueData?.type === ValueType.Email"
          :href="'mailto:' + valueData.value"
        >
          {{ valueData.value }}
        </a>

        <a
          v-else-if="valueData?.type === ValueType.Phone"
          :href="'tel:' + valueData.value"
        >
          {{ valueData.value }}
        </a>

        <p v-else>
          {{ valueData?.value }}
        </p>
      </div>
    </slot>
  </div>
</template>

<style scoped>
.value {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
}

.value > a {
  color: rgb(var(--v-theme-caeli-link));
  text-decoration: underline;
}
</style>
