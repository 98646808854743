<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import { usePersonService } from "@/app/process/service/PersonService";
import { EntityType } from "@/gql/types";

const { t } = useI18n();
const router = useRouter();
const personService = usePersonService();
const fieldService = useFieldService();

const isLoading = computed(() => personService.isLoading());
const listRowItems = computed(() => personService.getListRowItems());
const availableTags = computed(() =>
  fieldService.getTagFieldKeys(EntityType.Person),
);
async function createPerson() {
  const personId = personService.createOrUpdate({ id: uuidv4(), name: "Neu" });
  await router.push({ name: "personEdit", params: { personId } });
}

const mandatoryColumns = [t("person.name")];
</script>

<template>
  <VCard
    class="bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-auto"
    variant="flat"
    :loading="isLoading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        :active="isActive"
        color="caeli5"
        height="4"
        :indeterminate="true"
      />
    </template>
    <FilteredDataTable
      :searchByTextColumns="personService.displayColumns"
      :exposedColumns="personService.displayColumns"
      :mandatoryColumns="mandatoryColumns"
      contextKey="personListView"
      :rowItems="listRowItems"
      :availableTags="availableTags"
    />
  </VCard>

  <VTooltip location="start" :text="t('person.create')">
    <template #activator="{ props }">
      <FloatingActionButton
        v-bind="props"
        icon="mdi-plus"
        primary
        data-testid="create-person"
        @click="createPerson"
      />
    </template>
  </VTooltip>
</template>
