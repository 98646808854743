import dagre from "@dagrejs/dagre";
import { type Edge, type Node, Position } from "@vue-flow/core";
import { useVueFlow } from "@vue-flow/core";

const NODE_PADDING = 150.0;
const NODE_SEPARATION = 20.0;
const RANK_SEPARATION = 150.0;
const RANK_DIRECTION = "LR";
const DEFAULT_DIMENSIONS = { width: 400, height: 200 };

interface InputParams {
  nodes: Node[];
  edges: Edge[];
}

export function useGraphLayout() {
  const { findNode } = useVueFlow();

  function layout({ nodes, edges }: InputParams) {
    const dagreGraph = new dagre.graphlib.Graph();

    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({
      rankdir: RANK_DIRECTION,
      nodesep: NODE_SEPARATION,
      ranksep: RANK_SEPARATION,
    });

    nodes.forEach((node) => {
      const graphNode = findNode(node.id);
      const padding = NODE_PADDING;
      const width =
        (graphNode?.dimensions.width ?? DEFAULT_DIMENSIONS.width) + padding;
      const height =
        (graphNode?.dimensions.height ?? DEFAULT_DIMENSIONS.height) + padding;
      dagreGraph.setNode(node.id, { width, height });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    return nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        position: { x: nodeWithPosition.x, y: nodeWithPosition.y },
      };
    });
  }

  return { layout };
}
