<script setup lang="ts">
import { type NodeProps } from "@vue-flow/core";
import { Position } from "@vue-flow/core";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";

import GraphHandle from "@/app/process/graphviewer/GraphHandle.vue";
import GraphNodeToolbar from "@/app/process/graphviewer/GraphNodeToolbar.vue";
import { type GraphHandleData } from "@/app/process/graphviewer/GraphViewerService";

interface NodeData {
  label: string;
  inputs: GraphHandleData[];
  outputs: GraphHandleData[];
  isSimpleMode: boolean;
}

const props = defineProps<NodeProps<NodeData>>();

const nodeRef = ref(null);
const isToolbarVisible = ref(false);

const sortedInputs = [...props.data.inputs].sort((a, b) =>
  a.name.localeCompare(b.name),
);

const sortedOutputs = [...props.data.outputs].sort((a, b) =>
  a.name.localeCompare(b.name),
);

onClickOutside(nodeRef, () => (isToolbarVisible.value = false));
</script>

<template>
  <div ref="nodeRef" @click="isToolbarVisible = true">
    <div class="node">
      <GraphNodeToolbar :activityId="props.id" :isVisible="isToolbarVisible" />

      <div class="label">{{ props.data.label }}</div>

      <div class="handle-container">
        <div class="leftHandles">
          <GraphHandle
            v-for="input in sortedInputs"
            :id="input.id"
            :key="`input-${input.id}`"
            :label="input.name"
            :position="Position.Left"
          />
        </div>

        <div class="rightHandles">
          <GraphHandle
            v-for="output in sortedOutputs"
            :id="output.id"
            :key="`outputs-${output.id}`"
            :label="output.name"
            :position="Position.Right"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.node {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  border: 0.1rem solid rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  font-size: 1rem;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);

  &:hover {
    border: 0.1rem solid rgb(var(--v-theme-caeli6));
  }
}

.handle-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 80px;
  cursor: move;
}

.label {
  width: 100%;
  max-width: 500px;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin: 0;
  color: #fff;
  border-start-start-radius: 0.9rem;
  border-start-end-radius: 0.9rem;
  background-color: rgb(var(--v-theme-caeli6));
}

.leftHandles {
  padding: 10px 0 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  max-width: 200px;
}

.rightHandles {
  padding: 10px 0 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  max-width: 200px;
  text-align: right;
  word-wrap: anywhere;
}
</style>
