import { type AuthenticationResult, EventType } from "@azure/msal-browser";
import { type Router } from "vue-router";

import { registerGuard } from "@/services/auth/msal/routerGuard";

import { msalInstance } from "./msalConfig";

export const configureAppForMSAL = (router: Router) => {
  registerGuard(router);

  // Always use first account. Selecting from multiple is not supported.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
};
