<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  outputPresentCount: number;
  outputAllCount: number;
}>();

const classes = computed(() => {
  const results: string[] = [];
  if (props.outputPresentCount === props.outputAllCount) {
    results.push("font-weight-bold");
    results.push("text-green");
  }
  if (
    props.outputPresentCount !== props.outputAllCount &&
    props.outputPresentCount === 0
  ) {
    results.push("text-red");
  }
  return results.join(" ");
});
</script>

<template>
  <span v-if="props.outputAllCount != 0">
    <span class="pointer text-no-wrap" :class="classes">
      {{ `${props.outputPresentCount} / ${props.outputAllCount}` }}
    </span>
  </span>
</template>
