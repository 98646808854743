import { type Component, type StyleValue } from "vue";

export interface RowItem {
  class?: string | Record<string, unknown>;
  style?: StyleValue;
  events?: Record<string, unknown>;
  key: string;
  cells: Record<string, CellContent>;
  tags: string[];
}

export enum ContentType {
  DATE = "DATE",
}

export interface CellContent {
  content?: string | string[];
  component?: Component;
  props?: Record<string, unknown>;
  events?: Record<string, unknown>;
  sortValue?: string | number;
  type?: ContentType;
}
