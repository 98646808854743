import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import {
  ActivityInputDocument,
  type ActivityInputDto,
  type ActivityInputEto,
  DeleteActivityInputDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: ActivityInputEto) => ({ id: eto.id }) as ActivityInputDto,
  ),
  {
    queryName: "activityInput",
    mutation: ActivityInputDocument,
    deleteMutation: DeleteActivityInputDocument,
  },
  {
    indexFields: ["mid", "activityId", "outputId"],
  },
);
export const useActivityInputStore = defineEntityStore(
  "activityInput",
  entityProvider,
);
