import {
  type ActivityDto,
  type ActivityInputDto,
  type ActivityOutputDto,
  type ActivityTaskDto,
} from "@/gql/types";

export interface ChangeLookup<T> {
  previous?: T;
  current?: T;
  changedKeys: string[];
}

export interface ActivityChanges {
  mid: string;
  previous: ActivityDto;
  current: ActivityDto;
  activityChanges: ChangeLookup<ActivityDto>;
  taskChanges: ChangeLookup<ActivityTaskDto>[];
  outputChanges: ChangeLookup<ActivityOutputDto>[];
  inputChanges: ChangeLookup<ActivityInputDto>[];
}

function isIdField(key: string) {
  return key === "id" || key.endsWith("Id");
}

export function findFieldChanges<T extends object>(
  previous: T,
  current: T,
  excludeKeys?: string[],
): ChangeLookup<T> {
  return Object.keys(current).reduce(
    (results: ChangeLookup<T>, key) => {
      const previousValue = (previous as Record<string, unknown>)[key] ?? "";
      const currentValue = (current as Record<string, unknown>)[key] ?? "";
      if (
        !excludeKeys?.includes(key) &&
        !isIdField(key) &&
        previousValue !== currentValue
      ) {
        results.changedKeys.push(key);
      }
      return results;
    },
    { previous, current, changedKeys: [] as string[] },
  );
}

export function findAddedEntityChanges<
  TYPE extends {
    id: string;
  },
>(
  newEntities: TYPE[],
  oldEntities: TYPE[],
  getMid: (id: string) => string | undefined,
) {
  return newEntities.flatMap((newEntity) =>
    oldEntities.some(
      (oldEntity) => getMid(oldEntity.id) === getMid(newEntity.id),
    )
      ? []
      : {
          current: newEntity,
          changedKeys: [],
        },
  );
}

export function getSubTypeChanges<
  TYPE extends {
    id: string;
  },
>(
  oldEntities: TYPE[],
  newEntities: TYPE[],
  getMid: (id: string) => string | undefined,
): ChangeLookup<TYPE>[] {
  const results: ChangeLookup<TYPE>[] = findAddedEntityChanges(
    newEntities,
    oldEntities,
    getMid,
  );
  results.push(
    ...oldEntities.flatMap((oldEntity) => {
      const newEntity = newEntities.find(
        (candidate) => getMid(oldEntity.id) === getMid(candidate.id),
      );
      if (!newEntity) {
        return {
          previous: oldEntity,
          changedKeys: [],
        };
      }
      const fieldChanges = findFieldChanges(oldEntity, newEntity);
      return fieldChanges.changedKeys.length ? fieldChanges : [];
    }),
  );

  return results;
}
